import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import { WorkflowStepColor } from '../../../data/constants/WorkflowStepColor.ts';
import { IVariantStatusVariant } from '../../models/VariantModels.type.ts';
import { Assignment, AssignmentWorkflowStatus } from '../../uiComponents/Assignment/Assignment.tsx';
import {
  isArchivedWorkflowStepSelected,
  isPublishedWorkflowStepSelected,
  isScheduleToPublishWorkflowStepSelected,
} from '../../utils/contentItemUtils.ts';
import { isVariantPublished } from '../../utils/contentItemVariantUtils.ts';
import {
  DataUiWorkflowAction,
  ItemDetailSection,
  getDataUiWorkflowActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { renderDatetimeString } from '../../utils/dateTime/timeUtils.ts';
import { isVariantScheduledActionInProgress } from '../../utils/workflow/isVariantScheduledActionInProgress.ts';
import {
  ScheduledActionInProgress,
  ScheduledActionType,
} from '../Workflow/ScheduledActionInProgress.tsx';

export interface IDefaultVariantAssignmentStateProps {
  readonly variant: IVariantStatusVariant;
  readonly showDueDate?: boolean;
}

export interface IDefaultVariantAssignmentDispatchProps {
  readonly onDiscardNewVersion?: () => void;
}

type Props = IDefaultVariantAssignmentStateProps & IDefaultVariantAssignmentDispatchProps;

const scheduledVariantAssignment: AssignmentWorkflowStatus = {
  color: WorkflowStepColor.DarkBlue,
  name: 'Scheduled',
};

const getDueDateString = (due: DateTimeStamp | null): string =>
  due ? `Due by ${renderDatetimeString(due)}` : 'No due date set';

export const DefaultVariantAssignment: React.FC<Props> = ({
  variant,
  showDueDate,
  onDiscardNewVersion,
}) => {
  if (isPublishedWorkflowStepSelected(variant.assignment)) {
    return null;
  }

  if (isArchivedWorkflowStepSelected(variant.assignment)) {
    return null;
  }

  if (!isScheduleToPublishWorkflowStepSelected(variant.assignment)) {
    return (
      <Assignment
        dataUiObjectName={ItemDetailSection.CurrentStatus}
        workflowStatusColor={variant.assignment.workflowStatus.color}
      >
        <Assignment.Details
          prefix={isVariantPublished(variant) ? 'Current version' : undefined}
          suffix={showDueDate ? getDueDateString(variant.assignment.due) : undefined}
        >
          {variant.assignment.workflowStatus.name}
        </Assignment.Details>
        {onDiscardNewVersion && (
          <Assignment.Action>
            <Button
              buttonStyle="tertiary"
              onClick={onDiscardNewVersion}
              size="small"
              {...getDataUiWorkflowActionAttribute(DataUiWorkflowAction.Discard)}
            >
              Discard version
            </Button>
          </Assignment.Action>
        )}
      </Assignment>
    );
  }

  const { scheduledToPublishAt } = variant.assignment;
  const additionalNoteText = isVariantScheduledActionInProgress(scheduledToPublishAt)
    ? 'Scheduled for'
    : 'To publish on';

  return (
    <Assignment
      dataUiObjectName={ItemDetailSection.CurrentStatus}
      workflowStatusColor={scheduledVariantAssignment.color}
    >
      <Assignment.Details
        prefix={isVariantPublished(variant) ? 'Current version' : undefined}
        suffix={
          scheduledToPublishAt &&
          `${additionalNoteText} ${renderDatetimeString(scheduledToPublishAt)}`
        }
      >
        {isVariantScheduledActionInProgress(scheduledToPublishAt) ? (
          <ScheduledActionInProgress actionType={ScheduledActionType.Publish} showTooltip>
            Publishing now
          </ScheduledActionInProgress>
        ) : (
          scheduledVariantAssignment.name
        )}
      </Assignment.Details>
    </Assignment>
  );
};

DefaultVariantAssignment.displayName = 'DefaultVariantAssignment';
