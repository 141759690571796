import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Input, InputState, InputType } from '@kontent-ai/component-library/Input';
import { delay, noOperation } from '@kontent-ai/utils';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useImperativeHandle, useRef, useCallback } from 'react';
import {
  DataUiAction,
  DataUiElement,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
  getDataUiInputAttribute,
} from '../utils/dataAttributes/DataUiAttributes.ts';
import { IForwardedRefProps, forwardRef, forwardedRefProps } from '../utils/forwardedRefProps.tsx';

const getInputState = (disabled?: boolean, errorMessage?: string): InputState => {
  if (disabled) {
    return InputState.Disabled;
  }

  if (errorMessage) {
    return InputState.Alert;
  }

  return InputState.Default;
};

const clearActionToolTip = 'Clear the search phrase.';
const disabledFilterTooltip =
  'Can’t change the filter value while creating or editing a collection.';

export interface ITextFilterHandle {
  readonly focus: () => void;
}

interface ITextFilterProps {
  readonly autofocus?: boolean;
  readonly ariaLabel: string;
  readonly className?: string;
  readonly disabled?: boolean;
  readonly errorMessage?: string;
  readonly extraIconsAfterReset?: readonly React.ReactNode[];
  readonly extraIconsBeforeReset?: readonly React.ReactNode[];
  readonly hideClearButton?: boolean;
  readonly maxLength?: number;
  readonly onChange: (text: string) => void;
  readonly onClick?: () => void;
  readonly placeholder?: string;
  readonly text: string;
  readonly type?: InputType;
}

type TextFilterProps = ITextFilterProps & IForwardedRefProps<ITextFilterHandle>;

const propTypes: PropTypeMap<TextFilterProps> = {
  ...forwardedRefProps,
  autofocus: PropTypes.bool,
  ariaLabel: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  extraIconsAfterReset: PropTypes.array,
  extraIconsBeforeReset: PropTypes.array,
  hideClearButton: PropTypes.bool,
  maxLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(InputType)),
};

const TextFilter: React.FC<TextFilterProps> = ({
  autofocus,
  className,
  disabled,
  errorMessage,
  extraIconsAfterReset = [],
  extraIconsBeforeReset = [],
  forwardedRef,
  hideClearButton,
  maxLength,
  onChange,
  onClick = noOperation,
  placeholder,
  text,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const focus = useCallback(() => inputRef.current?.focus(), []);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
    onChange(event.target.value);

  const handleClear = (): void => {
    onChange('');
    focus();
  };

  useEffect(() => {
    if (autofocus) {
      delay(0).then(() => {
        focus();
      });
    }
  }, [autofocus, focus]);

  useImperativeHandle(forwardedRef, () => ({
    focus,
  }));

  const icons = [
    ...extraIconsBeforeReset,
    text && !hideClearButton && (
      <QuinaryButton
        disabled={disabled}
        tooltipPlacement="bottom"
        tooltipText={disabled ? '' : clearActionToolTip}
        onClick={handleClear}
        {...getDataUiActionAttribute(DataUiAction.ClearInput)}
      >
        <QuinaryButton.Icon icon={Icons.TimesCircle} screenReaderText={clearActionToolTip} />
      </QuinaryButton>
    ),
    ...extraIconsAfterReset,
  ].filter(Boolean);

  return (
    <div
      role="search"
      className={className}
      {...getDataUiElementAttribute(DataUiElement.TextFilter)}
    >
      <Input
        autoComplete="off"
        autoFocus={autofocus}
        caption={errorMessage}
        inputState={getInputState(disabled, errorMessage)}
        maxLength={maxLength}
        onClick={onClick}
        onChange={handleTextChange}
        placeholder={placeholder || 'Filter'}
        ref={inputRef}
        suffixes={icons}
        tooltipText={disabled ? disabledFilterTooltip : undefined}
        value={text}
        {...props}
        {...getDataUiInputAttribute(DataUiInput.Search)}
      />
    </div>
  );
};

TextFilter.displayName = 'TextFilter';
TextFilter.propTypes = propTypes;

const TextFilterForwardRef = forwardRef(TextFilter);
const TextFilterMemo = memo(TextFilterForwardRef);

TextFilterMemo.displayName = `memo(${TextFilterForwardRef.displayName})`;

export { TextFilterMemo as TextFilter };
