import { Button } from '@kontent-ai/component-library/Button';
import {
  FocusVisibilityInfoProvider,
  RootPortalContainerContextProvider,
} from '@kontent-ai/component-library/context';
import { RootPortalContainer } from '@kontent-ai/component-library/globals';
import { GlobalFonts } from '@kontent-ai/component-library/styles';
import { usePrevious } from '@kontent-ai/hooks';
import { OverlayProvider } from '@react-aria/overlays';
import { ReactNode, useEffect, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { useLocation } from 'react-router';
import { IsMissionControlEapEnabledProvider } from '../../applications/missionControl/contexts/IsContentStatusEnabled.tsx';
import { ActiveMenuContextProvider } from '../../applications/richText/plugins/inlineAi/instructions/ActiveMenuContext.tsx';
import { IsUnifiedMissionControlEnabledContextProvider } from '../../applications/unifiedMissionControl/contexts/IsUnifiedMissionControlEnabledContext.tsx';
import { getHighestPlan } from '../../data/reducers/plans/selectors/planSelectors.ts';
import {
  getCurrentProjectPlan,
  getCurrentProjectSubscription,
} from '../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { trackUserEventWithData } from '../actions/thunks/trackUserEvent.ts';
import { ReturnAutoScrollHandler } from '../components/AutoScroll/ReturnAutoScrollHandler.tsx';
import { DisableIframeInteractionOnMouseDown } from '../components/DisableIframeInteractionOnMouseDown.tsx';
import { GoogleTagManager } from '../components/GoogleTagManager.tsx';
import { Header } from '../components/Header.tsx';
import { MainLayoutGrid } from '../components/MainLayoutGrid.tsx';
import { SkipToMainContent } from '../components/SkipToMainContent.tsx';
import { TrackedEvent } from '../constants/trackedEvent.ts';
import { AiMessageBufferProvider } from '../contexts/AiMessageBufferProvider.tsx';
import { AiTaskManagerProvider } from '../contexts/AiTaskManagerProvider.tsx';
import { LayoutSynchronizationContextProvider } from '../contexts/LayoutSynchronizationContext.tsx';
import { TranslationTaskManagerProvider } from '../contexts/TranslationTaskManagerProvider.tsx';
import { PropTypeErrorNotifier } from '../debug/PropTypeErrorNotifier.tsx';
import { useDispatch } from '../hooks/useDispatch.ts';
import { useOneTimeAction } from '../hooks/useOneTimeAction.ts';
import { useSelector } from '../hooks/useSelector.ts';
import { getCurrentUser } from '../selectors/getCurrentUser.ts';
import { CustomDndBackend } from '../services/CustomDndBackend.ts';
import { goToLogin } from '../utils/authorization/authorization.ts';
import { CardinalLocalTimeZoneId, LocalTimeZoneId } from '../utils/dateTime/timeZoneUtils.ts';
import { getEmailDomain } from '../utils/emailUtils.ts';
import { IntercomUtils } from '../utils/intercomUtils.ts';
import { formatUserName } from '../utils/usersUtils.ts';
import { Amplitude } from './Amplitude.tsx';
import { ChurnZero } from './ChurnZero.tsx';
import { MainMenu } from './MainMenu/MainMenu.tsx';
import { ModalDialogSelector } from './ModalDialog/ModalDialogSelector.tsx';
import { OnboardingNotificationsSelector } from './OnboardingNotifications/OnboardingNotificationsSelector.tsx';
import { UiBlockingMessage } from './UiBlockingMessage.tsx';

type Props = {
  readonly children: ReactNode;
};

export const AppWrapper = (props: Props) => {
  useFireTrackEvents();
  useIntercom();

  const portalContainerRef = useRef<HTMLDivElement>(null);
  const isLoggedIn = useSelector((s) => s.sharedApp.isLoggedIn);
  if (!isLoggedIn) {
    return <YouHaveBeenSignedOut />;
  }

  return (
    <DndProvider backend={CustomDndBackend}>
      <ActiveMenuContextProvider>
        <FocusVisibilityInfoProvider>
          <OverlayProvider>
            <RootPortalContainerContextProvider
              portalContainerRef={portalContainerRef}
              overlayPortalContainerRef={portalContainerRef}
            >
              <LayoutSynchronizationContextProvider>
                <AiMessageBufferProvider>
                  <AiTaskManagerProvider>
                    <TranslationTaskManagerProvider>
                      <IsUnifiedMissionControlEnabledContextProvider>
                        <IsMissionControlEapEnabledProvider>
                          <MainLayoutGrid>
                            <SkipToMainContent />
                            {/* This condition needs to be on this level to avoid unnecessary overhead. */}
                            {process.env.NODE_ENV !== 'production' && <PropTypeErrorNotifier />}
                            <GlobalFonts />
                            <MainMenu />
                            <Header />
                            <MainLayoutGrid.AppView>
                              {props.children}
                              <OnboardingNotificationsSelector />
                              <UiBlockingMessage />
                              <ModalDialogSelector />
                              <ReturnAutoScrollHandler />
                              <DisableIframeInteractionOnMouseDown />
                            </MainLayoutGrid.AppView>
                            <Amplitude />
                            <GoogleTagManager />
                            <ChurnZero />
                          </MainLayoutGrid>
                        </IsMissionControlEapEnabledProvider>
                      </IsUnifiedMissionControlEnabledContextProvider>
                    </TranslationTaskManagerProvider>
                  </AiTaskManagerProvider>
                </AiMessageBufferProvider>
              </LayoutSynchronizationContextProvider>
            </RootPortalContainerContextProvider>
          </OverlayProvider>
          <RootPortalContainer ref={portalContainerRef} />
        </FocusVisibilityInfoProvider>
      </ActiveMenuContextProvider>
    </DndProvider>
  );
};

const YouHaveBeenSignedOut = () => {
  return (
    <div className="utility-message utility-message--centered sign-out">
      <div className="sign-out__logo-wrapper">
        <img
          src="/images/logotypes/kontent-ai-black.svg"
          alt="Kontent.ai"
          className="header__logo"
        />
      </div>
      <div className="sign-out__title">You have been signed out.</div>
      <Button buttonStyle="primary" onClick={goToLogin}>
        Sign in
      </Button>
    </div>
  );
};

const useIntercom = () => {
  const { pathname } = useLocation();
  const currentProjectPlan = useSelector(getCurrentProjectPlan);
  const highestPlan = useSelector((state) =>
    getHighestPlan(state.data.plans.byId, state.data.subscriptions.byId),
  );
  const currentProjectSubscription = useSelector(getCurrentProjectSubscription);
  const businessRole = useSelector((state) => state.sharedApp.userProperties.businessRole);
  const businessType = useSelector((state) => state.sharedApp.userProperties.businessType);
  const companyName = useSelector((state) => state.sharedApp.userProperties.companyName);
  const filledBusinessRole = useSelector(
    (state) => state.sharedApp.userProperties.filledBusinessRole,
  );
  const phoneNumber = useSelector((state) => state.sharedApp.userProperties.phoneNumber);
  const userInfo = useSelector(getCurrentUser);

  useOneTimeAction(() =>
    IntercomUtils.boot({
      app_id: self._envConfig.intercomAppId,
      user_id: userInfo.userId,
      user_hash: userInfo.userHash,
    }),
  );

  const previousPathname = usePrevious(pathname);
  useEffect(() => {
    if (previousPathname !== pathname) {
      IntercomUtils.pathUpdate();
    }
  }, [pathname, previousPathname]);

  useEffect(() => {
    IntercomUtils.update({
      app_id: self._envConfig.intercomAppId,
      user_id: userInfo.userId,
      user_hash: userInfo.userHash,
      name: formatUserName(userInfo),
      email: userInfo.email,
      created_at: Math.floor(Date.parse(userInfo.createdAt) / 1000),
      sign_up_date: userInfo.createdAt,
      amplitude_link: `${self._envConfig.amplitudeSearchUrl}/${userInfo.userId}`,
      business_type: businessType,
      business_role: businessRole,
      provided_company_name: companyName,
      business_role_other: filledBusinessRole,
      'subscription-plan': currentProjectPlan.name,
      'subscription-plan__trial--started-at': currentProjectSubscription.currentPlan.startAt,
      phone: phoneNumber,
      highest_plan: highestPlan,
    });
  }, [
    businessRole,
    businessType,
    companyName,
    currentProjectPlan.name,
    currentProjectSubscription.currentPlan.startAt,
    filledBusinessRole,
    highestPlan,
    phoneNumber,
    userInfo,
  ]);
};

const useFireTrackEvents = () => {
  const dispatch = useDispatch();

  const highestPlan = useSelector(
    (s) => getHighestPlan(s.data.plans.byId, s.data.subscriptions.byId) || undefined,
  );
  const emailDomain = useSelector((s) => getEmailDomain(s.data.user.info.email));
  const signUpDate = useSelector((s) => s.data.user.info.createdAt);
  const businessRole = useSelector((s) => s.sharedApp.userProperties.businessRole);
  const filledBusinessRole = useSelector((s) => s.sharedApp.userProperties.filledBusinessRole);
  const businessType = useSelector((s) => s.sharedApp.userProperties.businessType);
  const companyName = useSelector((s) => s.sharedApp.userProperties.companyName);

  useEffect(() => {
    dispatch(
      trackUserEventWithData(TrackedEvent.AppLoaded, {
        plan: highestPlan,
        'business-role': businessRole,
        'filled-business-role': filledBusinessRole,
        'business-type': businessType,
        'email-domain': emailDomain,
        'provided-company-name': companyName,
        'sign-up-date': signUpDate,
      }),
    );
  }, [
    highestPlan,
    businessRole,
    filledBusinessRole,
    businessType,
    emailDomain,
    companyName,
    signUpDate,
  ]);

  useEffect(() => {
    if (CardinalLocalTimeZoneId === null) {
      dispatch(
        trackUserEventWithData(TrackedEvent.ClientTimeZoneUnsupported, {
          localTimeZoneId: LocalTimeZoneId,
        }),
      );
    }
  }, []);
};
