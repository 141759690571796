import Immutable from 'immutable';
import ImmutablePropTypes from 'immutable-prop-types';
import React from 'react';
import { DataTable } from '../../../../../_shared/components/DataTable/DataTable.tsx';
import { DataTableHeadRow } from '../../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { DataTableRow } from '../../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { LastUpdatedAt } from '../../../../../_shared/components/LastUpdatedAt.tsx';
import {
  ItemColumnCode,
  translateColumnCodeToTitle,
} from '../../../../../_shared/constants/itemColumnCode.ts';
import { WorkflowStatusTagForVariant } from '../../../../../_shared/containers/Workflow/WorkflowStatusTagForVariant.tsx';
import { OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { TableCellDueDate } from '../../../../contentInventory/content/features/ContentItemInventory/components/TableCellDueDate.tsx';
import { ViewItemsProps } from './ListingView.tsx';

const headColumns = Immutable.List([
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.Name),
    orderBy: OrderByDirection.None,
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.ContentType),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--3',
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.WorkflowStep),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--4',
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.DueDate),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--4',
  },
  {
    columnName: 'Last updated',
    orderBy: OrderByDirection.Descending,
    className: 'data-table__column--3-and-half',
  },
]);

export const FullView: React.FC<ViewItemsProps> = ({ contentItems }) => (
  <DataTable
    dataUiCollectionName={DataUiCollection.ContentItems}
    header={<DataTableHeadRow columns={headColumns} />}
  >
    {contentItems.toArray().map((contentItem) => {
      const {
        contentType,
        due,
        id,
        lastUpdatedAt,
        linkTo,
        name,
        publishingState,
        scheduledToPublishAt,
        scheduledToUnpublishAt,
        workflowStatus,
      } = contentItem;
      return (
        <DataTableRow dataUiObjectName={name} id={id} key={id}>
          <LinkDataTableCell linkPath={linkTo} title={name}>
            {name}
          </LinkDataTableCell>
          <LinkDataTableCell linkPath={linkTo} title={contentType}>
            {contentType}
          </LinkDataTableCell>
          <LinkDataTableCell linkPath={linkTo}>
            <WorkflowStatusTagForVariant
              workflowStatus={workflowStatus}
              publishingState={publishingState}
              scheduledToPublishAt={scheduledToPublishAt}
              scheduledToUnpublishAt={scheduledToUnpublishAt}
            />
          </LinkDataTableCell>
          <LinkDataTableCell linkPath={linkTo}>
            <TableCellDueDate dueDate={due} />
          </LinkDataTableCell>
          <LinkDataTableCell linkPath={linkTo}>
            <LastUpdatedAt time={lastUpdatedAt} />
          </LinkDataTableCell>
        </DataTableRow>
      );
    })}
  </DataTable>
);

FullView.displayName = 'FullView';
const propTypes: PropTypesShape<ViewItemsProps> = {
  contentItems: ImmutablePropTypes.list.isRequired,
};
FullView.propTypes = propTypes;
