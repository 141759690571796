import { useCallback, useMemo } from 'react';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { Apply, EditorPlugin, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { DecorableFunction, Decorator, decorable } from '../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';

export type AllowPlaceholder = () => boolean;

type PlaceholderPluginState = {
  readonly allowPlaceholder: DecorableFunction<AllowPlaceholder>;
};

type PlaceholderPluginProps = {
  readonly placeholder?: string;
};

export type PlaceholderPlugin = EditorPlugin<PlaceholderPluginState, PlaceholderPluginProps>;

const allowPlaceholder: AllowPlaceholder = () => true;

export const usePlaceholder: PluginCreator<PlaceholderPlugin, [string?]> = (
  baseEditor,
  defaultPlaceholder = undefined,
) =>
  useMemo(
    () =>
      withDisplayName('PlaceholderPlugin', {
        ComposedEditor: (props) => {
          const { placeholder } = props;

          const render: Decorator<Render<PlaceholderPlugin>> = useCallback(
            (baseRender) => (state) =>
              baseRender({
                ...state,
                editorProps: {
                  ...state.editorProps,
                  placeholder: state.allowPlaceholder()
                    ? (placeholder ?? defaultPlaceholder)
                    : undefined,
                },
              }),
            [defaultPlaceholder, placeholder],
          );

          const apply: Apply<PlaceholderPlugin> = useCallback(
            (state) => {
              state.render.decorate(render);
              return {
                allowPlaceholder: decorable(allowPlaceholder),
              };
            },
            [render],
          );

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor, defaultPlaceholder],
  );
