import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import React, { useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { loadListingItems } from '../../applications/itemEditor/features/LoadedItems/actions/thunkLoadedItemsActions.ts';
import {
  getCurrentProjectContainerId,
  getCurrentProjectId,
} from '../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { loadBreadcrumbs } from '../actions/thunkSharedActions.ts';
import {
  Breadcrumbs as BreadcrumbsComponent,
  IBreadcrumbsProps,
  NavigationBreadcrumb,
} from '../components/Breadcrumbs.tsx';
import { ContentItemRouteParams } from '../constants/routePaths.ts';
import { useDispatch } from '../hooks/useDispatch.ts';
import { useSelector } from '../hooks/useSelector.ts';
import { Breadcrumb } from '../models/Breadcrumb.type.ts';
import { isMultipleWorkflowsConfigurationVisible } from '../selectors/Workflows/isMultipleWorkflowsConfigurationVisible.ts';
import { getBreadcrumbs } from '../selectors/breadcrumbs/getBreadcrumbs.ts';
import { getBreadcrumbsOrigin } from '../selectors/breadcrumbs/getBreadcrumbsOrigin.ts';
import { getSelectedLanguageIdOrRouteMacro } from '../selectors/getSelectedLanguageId.ts';
import { replaceLanguageMacroInBreadcrumbs } from '../utils/breadcrumbs/breadcrumbResolvers.ts';
import { parseContentItemIds } from '../utils/routing/routeTransitionUtils.ts';
import { isUuid } from '../utils/validation/typeValidators.ts';

const decorateBreadcrumbs = memoize.maxOne(
  (
    currentProjectId: Uuid,
    breadcrumbs: Immutable.List<Breadcrumb>,
    selectedLanguageId: Uuid,
  ): Immutable.List<NavigationBreadcrumb> => {
    return replaceLanguageMacroInBreadcrumbs(breadcrumbs, selectedLanguageId)
      .map((breadcrumb: Breadcrumb) => ({
        label: breadcrumb.title,
        to: `${
          breadcrumb.useProjectIdPrefix && isUuid(currentProjectId) ? `/${currentProjectId}` : ''
        }${breadcrumb.path}`,
      }))
      .toList();
  },
);

export const Breadcrumbs: React.FC<Pick<IBreadcrumbsProps, 'renderSavingStatusComponent'>> = (
  props,
) => {
  const location = useLocation();
  const match = useRouteMatch<Partial<ContentItemRouteParams<Uuid>>>();
  const dispatch = useDispatch();

  const breadcrumbs = useSelector(getBreadcrumbs);
  const breadcrumbsOrigin = useSelector(getBreadcrumbsOrigin);
  const isMultipleWorkflowsConfigurationAllowed = useSelector(
    isMultipleWorkflowsConfigurationVisible,
  );
  const listingContentItems = useSelector((state) => state.data.listingContentItems);
  const projectId = useSelector(getCurrentProjectId);
  const projectContainerId = useSelector(getCurrentProjectContainerId);
  const selectedLanguageId = useSelector(getSelectedLanguageIdOrRouteMacro);
  const canLoadProjectBreadcrumbs: boolean = !!projectId || !!projectContainerId;

  useEffect(() => {
    if (canLoadProjectBreadcrumbs) {
      dispatch(
        loadBreadcrumbs({
          location,
          breadcrumbsOrigin,
          isMultipleWorkflowsConfigurationAllowed,
          listingContentItems,
        }),
      );
    }
  }, [
    canLoadProjectBreadcrumbs,
    location,
    isMultipleWorkflowsConfigurationAllowed,
    listingContentItems,
    breadcrumbsOrigin,
  ]);

  const contentItemIds = match.params.contentItemIds
    ? parseContentItemIds(match.params.contentItemIds)
    : null;
  useEffect(() => {
    if (contentItemIds && !!projectId) {
      dispatch(loadListingItems(contentItemIds));
    }
  }, [projectId, contentItemIds]);

  if (!breadcrumbs.size) {
    return null;
  }

  return (
    <BreadcrumbsComponent
      {...props}
      breadcrumbs={decorateBreadcrumbs(projectId, breadcrumbs, selectedLanguageId)}
    />
  );
};

Breadcrumbs.displayName = 'BreadcrumbsContainer';
