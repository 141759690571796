import { Button } from '@kontent-ai/component-library/Button';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../utils/dataAttributes/DataUiAttributes.ts';
import { MainLayoutGrid } from './MainLayoutGrid.tsx';
import { StatusBarContainer } from './StatusBar/StatusBarContainer.tsx';

export interface IEditableAppBarOwnProps {
  readonly renderLeftContainer: () => React.ReactNode;
  readonly renderCentralContainer: (ref: React.RefObject<HTMLDivElement>) => React.ReactNode;
  readonly renderRightContainer: () => React.ReactNode;
}

export interface IEditableAppBarStateProps {
  readonly createButtonText?: string;
  readonly canCreateNewObject?: boolean;
  readonly createNewItemTooltip?: string;
  readonly isDisabled?: boolean;
}

export interface IEditableAppBarDispatchProps {
  readonly onCreate?: () => void;
}

type EditableAppBarProps = IEditableAppBarOwnProps &
  IEditableAppBarDispatchProps &
  IEditableAppBarStateProps;

const propTypes: PropTypesShape<EditableAppBarProps> = {
  createButtonText: PropTypes.string,
  canCreateNewObject: PropTypes.bool,
  createNewItemTooltip: PropTypes.string,
  isDisabled: PropTypes.bool,
  onCreate: PropTypes.func,
  renderLeftContainer: PropTypes.func.isRequired,
  renderCentralContainer: PropTypes.func.isRequired,
  renderRightContainer: PropTypes.func.isRequired,
};

export const EditableAppBar: React.FC<EditableAppBarProps> = ({
  canCreateNewObject,
  createButtonText,
  createNewItemTooltip,
  isDisabled,
  onCreate,
  renderCentralContainer,
  renderLeftContainer,
  renderRightContainer,
}) => {
  const createButton = onCreate && canCreateNewObject && (
    <Tooltip tooltipText={createNewItemTooltip} placement="left">
      <Button
        buttonStyle="secondary"
        onClick={onCreate}
        disabled={isDisabled}
        {...getDataUiActionAttribute(DataUiAction.CreateNew)}
      >
        {createButtonText || 'Create'}
      </Button>
    </Tooltip>
  );

  return (
    <MainLayoutGrid.StatusBar>
      <StatusBarContainer
        renderLeftContainer={renderLeftContainer}
        renderCentralContainer={renderCentralContainer}
        renderRightContainer={() => (
          <>
            {renderRightContainer()}
            {createButton}
          </>
        )}
      />
    </MainLayoutGrid.StatusBar>
  );
};

EditableAppBar.displayName = 'EditableAppBar';
EditableAppBar.propTypes = propTypes;
