import React, { ComponentProps } from 'react';
import { DataTableRow } from '../../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { TableCellDueDate } from '../../../../contentInventory/content/features/ContentItemInventory/components/TableCellDueDate.tsx';
import { ScrollTableItemContributors } from '../../../contentStatus/components/ScrollTableItemContributors.tsx';
import { YourTaskWithPathToItem } from '../types/YourTaskWithPathToItem.type.ts';

type TasksAssignedToYouWidgetRowProps = {
  readonly task: YourTaskWithPathToItem;
  readonly onTaskClick: () => void;
  readonly rowFocusLinkAriaLabel: string;
};

export const TasksAssignedToYouWidgetRow: React.FC<TasksAssignedToYouWidgetRowProps> = ({
  task,
  onTaskClick,
  rowFocusLinkAriaLabel,
}) => {
  const linkProps = {
    linkPath: task.contentItemPath,
    onClick: onTaskClick,
  } satisfies ComponentProps<typeof LinkDataTableCell>;

  return (
    <DataTableRow dataUiObjectName={DataUiCollection.ContentTasks} id={task.id}>
      <LinkDataTableCell focusableRowLinkAriaLabel={rowFocusLinkAriaLabel} {...linkProps}>
        {task.description}
      </LinkDataTableCell>
      <LinkDataTableCell {...linkProps}>
        <ScrollTableItemContributors contributors={[task.taskAuthor]} />
      </LinkDataTableCell>
      <LinkDataTableCell {...linkProps}>
        <TableCellDueDate dueDate={task.taskDueDate} />
      </LinkDataTableCell>
    </DataTableRow>
  );
};
