import { isElement } from '@kontent-ai/DOM';
import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { useObserveElementPresence } from '@kontent-ai/hooks';
import React from 'react';
import { createPortal } from 'react-dom';
import { AiOperationState } from '../../../../../../_shared/features/AI/types/AiOperationState.ts';
import {
  AiError,
  getAiErrorMessage,
} from '../../../../../../_shared/features/AI/types/aiErrors.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ElementReference } from '../../../../../../applications/itemEditor/features/ContentItemEditing/containers/hooks/useItemElementReference.ts';
import {
  AiActionStatus,
  getAiOperationStatus,
} from '../../../../../../applications/richText/plugins/ai/components/status/AiActionStatus.tsx';
import { getAiElementStatusPlaceholderId } from '../../../../../../applications/richText/plugins/ai/components/status/AiElementStatusPlaceholder.tsx';
import { reviewByGuidelinesErrorMessageByErrorCode } from '../reviewByGuidelinesAiErrors.ts';
import { getAiReviewButtonPlaceholderId } from './AiReviewButtonPlaceholder.tsx';

type Props = {
  readonly aiOperationState: AiOperationState;
  readonly disabled: boolean;
  readonly element: ElementReference;
  readonly error: AiError | null;
  readonly perform: () => void;
  readonly tooltipText: string;
};

export const AiReviewAction: React.FC<Props> = ({
  aiOperationState,
  disabled,
  element,
  error,
  perform,
  tooltipText,
}) => {
  const { current: buttonPlaceholder } = useObserveElementPresence(
    getAiReviewButtonPlaceholderId(element.elementId, element.contentComponentId),
  );

  const { current: statusPlaceholder } = useObserveElementPresence(
    getAiElementStatusPlaceholderId(element.elementId, element.contentComponentId),
  );

  return (
    <>
      {isElement(statusPlaceholder) &&
        createPortal(
          <AiActionStatus
            label={getStatusLabel(aiOperationState, error)}
            status={getAiOperationStatus(aiOperationState, error)}
          />,
          statusPlaceholder,
        )}
      {isElement(buttonPlaceholder) &&
        createPortal(
          <QuinaryButton
            tooltipText={tooltipText}
            tooltipPlacement="bottom"
            disabled={disabled}
            onClick={perform}
            {...getDataUiElementAttribute(DataUiElement.AiReviewButton)}
          >
            <QuinaryButton.Icon icon={Icons.UserCheckbox} />
          </QuinaryButton>,
          buttonPlaceholder,
        )}
    </>
  );
};

const getStatusLabel = (aiOperationState: AiOperationState, error: AiError | null): string => {
  if (error) {
    return getAiErrorMessage(error, reviewByGuidelinesErrorMessageByErrorCode);
  }

  return aiOperationState === AiOperationState.Pending ? 'Reviewing' : 'Review finished';
};
