import { Avatar, AvatarSize } from '@kontent-ai/component-library/Avatar';
import React, { ComponentProps } from 'react';
import { LinkDataTableCell } from '../../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { Task } from '../../../../../_shared/models/Task.ts';
import { MissionControlTrackedWidgetName } from '../../../../../_shared/models/TrackUserEventData.ts';
import { createUnknownUserInfo } from '../../../../../_shared/models/UserInfo.ts';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import {
  formatUserName,
  getExistingUserNewColorGradient,
  getUserInitials,
} from '../../../../../_shared/utils/usersUtils.ts';
import { TableCellDueDate } from '../../../../contentInventory/content/features/ContentItemInventory/components/TableCellDueDate.tsx';
import { useIsDemoModeEnabled } from '../../../contexts/MissionControlDemoContext.tsx';
import {
  StyledDataTableCell,
  StyledDataTableRow,
} from '../../shared/components/StyledDataTable.tsx';
import { useTrackWidgetOverviewItemClick } from '../../shared/hooks/tracking.ts';

interface IMostOverdueTasksWidgetRowProps {
  readonly task: Task;
  readonly rowFocusLinkAriaLabel: string;
}

export const MostOverdueTasksWidgetRow: React.FC<IMostOverdueTasksWidgetRowProps> = ({
  rowFocusLinkAriaLabel,
  task,
}) => {
  const isDemoModeEnabled = useIsDemoModeEnabled();

  const trackWidgetOverviewItemClick = useTrackWidgetOverviewItemClick(
    MissionControlTrackedWidgetName.OverdueTasks,
  );
  const commonCellProps = {
    linkPath: isDemoModeEnabled
      ? null // Disable redirect in demo mode
      : getContentItemPath(
          window.location.pathname,
          task.contentItemId.itemId,
          task.contentItemId.variantId,
        ),
    onClick: isDemoModeEnabled ? undefined : trackWidgetOverviewItemClick,
  } satisfies ComponentProps<typeof LinkDataTableCell>;

  const usersById = useSelector((s) => s.data.users.usersById);
  const owner = usersById.get(task.owner) ?? createUnknownUserInfo(task.owner);

  return (
    <StyledDataTableRow dataUiObjectName={DataUiCollection.ContentTasks} id={task.id}>
      <StyledDataTableCell focusableRowLinkAriaLabel={rowFocusLinkAriaLabel} {...commonCellProps}>
        {task.description}
      </StyledDataTableCell>
      <StyledDataTableCell {...commonCellProps}>
        <Avatar
          backgroundGradient={getExistingUserNewColorGradient(owner)}
          initials={getUserInitials(owner)}
          label={formatUserName(owner)}
          size={AvatarSize.S}
        />
      </StyledDataTableCell>
      <StyledDataTableCell {...commonCellProps}>
        <TableCellDueDate dueDate={task.dueDate} />
      </StyledDataTableCell>
    </StyledDataTableRow>
  );
};

MostOverdueTasksWidgetRow.displayName = 'MostOverdueTasksWidgetRow';
