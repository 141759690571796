import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../@types/Dispatcher.type.ts';
import { getDefaultSpaceDomain } from '../../../applications/environmentSettings/spaces/utils/spacesUtils.ts';
import { getContentItemPreviewUrlInfo } from '../../../applications/itemEditor/utils/previewUtils.ts';
import { onboardingNotificationClosed } from '../../actions/sharedActions.ts';
import { trackUserEventWithData } from '../../actions/thunks/trackUserEvent.ts';
import {
  IOnboardingNotificationBarProps,
  OnboardingNotificationBar,
} from '../../components/OnboardingNotifications/OnboardingNotificationBar.tsx';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { IStore } from '../../stores/IStore.type.ts';

const Message: React.FC = () => (
  <>🚀 Awesome! Your content is live. 🚀 Want to preview how it could look on a basic website?</>
);

interface StateProps {
  readonly message: React.ReactNode;
  readonly buttonMessage: string;
  readonly previewUrl?: string;
}

function mapStateToProps(state: IStore): StateProps {
  const {
    contentApp: {
      editedContentItemVariantElements,
      editedContentItemVariant,
      previewConfiguration,
      editedContentItem,
    },
    data: { languages, collections },
  } = state;

  const editedContentItemId = editedContentItem?.id;
  const editedContentItemCodename = editedContentItem?.codename;
  const editedContentItemTypeId = editedContentItem?.editedContentItemTypeId;
  const editedContentItemCollectionId = editedContentItem?.collectionId;
  const variantId = editedContentItemVariant?.id.variantId;
  const spaceId = previewConfiguration
    ? (getDefaultSpaceDomain(previewConfiguration)?.spaceId ?? null)
    : null;

  const previewUrlInfo = getContentItemPreviewUrlInfo(
    editedContentItemId,
    editedContentItemCodename,
    editedContentItemTypeId,
    editedContentItemCollectionId,
    variantId,
    editedContentItemVariantElements,
    previewConfiguration,
    languages.defaultLanguage,
    languages.byId,
    collections.byId,
    spaceId,
  );

  return {
    buttonMessage: 'See your content',
    message: <Message />,
    previewUrl: previewUrlInfo.url,
  };
}

interface DispatchProps {
  readonly onButtonClick: (previewUrl?: string) => () => void;
  readonly onClose: () => void;
  readonly onInit: () => void;
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    onButtonClick: (previewUrl?: string) => () => {
      dispatch(
        trackUserEventWithData(TrackedEvent.FeatureUsed, {
          feature: 'onboarding-notification--cta',
          info: 'content-publishing--business',
        }),
      );
      dispatch(onboardingNotificationClosed());
      if (previewUrl) {
        window.open(previewUrl, '_blank');
      }
    },
    onClose: () => {
      dispatch(onboardingNotificationClosed());
    },
    onInit: () => {
      dispatch(
        trackUserEventWithData(TrackedEvent.FeatureUsed, {
          feature: 'onboarding-notification--displayed',
          info: 'content-publishing--business',
        }),
      );
    },
  };
}

function mergeProps(
  stateProps: StateProps,
  dispatchProps: DispatchProps,
): IOnboardingNotificationBarProps {
  return {
    message: stateProps.message,
    buttonMessage: stateProps.buttonMessage,
    onButtonClick: dispatchProps.onButtonClick(stateProps.previewUrl),
    onClose: dispatchProps.onClose,
    onInit: dispatchProps.onInit,
  };
}

export const ContentPublishingOnboardingBusinessUserNotification = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(OnboardingNotificationBar);
