import {
  CompiledTypeElementType,
  ElementType,
  TypeElementType,
} from '../../../applications/contentInventory/content/models/ContentItemElementType.ts';
import { ActiveCapabilityType } from '../../models/activeCapability.type.ts';
import { isString } from '../stringUtils.ts';
import { DataAttributeName, getDataAttribute } from './DataAttributes.ts';

export enum DataUiAttributes {
  Action = 'data-ui-action',
  ActiveCapabilities = 'data-ui-active-capabilities',
  AppName = 'data-ui-app',
  Collection = 'data-ui-collection',
  ColumnName = 'data-ui-column-name',
  ComponentId = 'data-ui-component-id',
  Element = 'data-ui-element',
  ElementInComponent = 'data-ui-element-in-component',
  Input = 'data-ui-input',
  NavigationItem = 'data-ui-nav',
  ObjectName = 'data-ui-object-name',
}

export type ObjectWithDataAttribute = {
  readonly [key in DataAttributeName]?: string | number | undefined;
};

const itemElementToDataAttributeMap: Record<
  CompiledTypeElementType | ElementType.Guidelines,
  string
> = {
  [ElementType.Asset]: 'asset-element',
  [ElementType.Subpages]: 'subpages-element',
  [ElementType.Custom]: 'custom-element',
  [ElementType.DateTime]: 'date-time-element',
  [ElementType.Guidelines]: 'guidelines-element',
  [ElementType.LinkedItems]: 'modular-content-element',
  [ElementType.MultipleChoice]: 'multiple-choice-element',
  [ElementType.Number]: 'number-element',
  [ElementType.RichText]: 'rich-text-element',
  [ElementType.Taxonomy]: 'taxonomy-element',
  [ElementType.Text]: 'text-element',
  [ElementType.UrlSlug]: 'url-slug-element',
};

function camelCaseToDashes(str: string): string {
  return str.replace(/([A-Z])/g, (capital) => `-${(capital[0] ?? '').toLowerCase()}`);
}

export function getDataAttributeProps<T extends AnyObject>(props: T): AnyObject {
  return Object.keys(props).reduce((result, newKey) => {
    if (isString(newKey) && newKey.startsWith('data') && isString(props[newKey])) {
      return {
        ...result,
        [camelCaseToDashes(newKey)]: props[newKey],
      };
    }

    return result;
  }, {});
}

export const getDataUiActionAttribute = (
  value:
    | DataUiAction
    | DataUiRteAction
    | DataUiStatusBarActions
    | DataUiWorkflowAction
    | DataUiSwitchAction
    | DataUiAssetRenditionAction
    | undefined,
): ObjectWithDataAttribute => getDataAttribute(DataUiAttributes.Action, value);
export const getDataUiAppNameAttribute = (value: DataUiAppName): ObjectWithDataAttribute =>
  getDataAttribute(DataUiAttributes.AppName, value);
export const getDataUiCollectionAttribute = (value: DataUiCollection): ObjectWithDataAttribute =>
  getDataAttribute(DataUiAttributes.Collection, value);
export const getDataUiCommentActionAttribute = (
  value: DataUiCommentsAction,
): ObjectWithDataAttribute => getDataAttribute(DataUiAttributes.Action, value);
export const getDataUiInputAttribute = (value: DataUiInput): ObjectWithDataAttribute =>
  getDataAttribute(DataUiAttributes.Input, value);
export const getDataUiNavAttribute = (
  value: DataUiAppName | DataUiExternalLinkName,
): ObjectWithDataAttribute => getDataAttribute(DataUiAttributes.NavigationItem, value);
export const getDataUiRevisionActionAttribute = (
  value: DataUiRevisionAction,
): ObjectWithDataAttribute => getDataAttribute(DataUiAttributes.Action, value);
export const getDataUiSwitchActionAttribute = (
  value: DataUiSwitchAction,
): ObjectWithDataAttribute => getDataAttribute(DataUiAttributes.Action, value);
export const getDataUiWorkflowActionAttribute = (
  value: DataUiWorkflowAction,
): ObjectWithDataAttribute => getDataAttribute(DataUiAttributes.Action, value);
/** If possible, put on an element with onClick handler */
export const getDataUiContentElementAttribute = (
  type: CompiledTypeElementType,
): ObjectWithDataAttribute =>
  getDataAttribute(DataUiAttributes.Element, itemElementToDataAttributeMap[type]);
export const getDataUiElementAttribute = (
  value: DataUiElement | TypeElementType | ItemDetailSection,
): ObjectWithDataAttribute => getDataAttribute(DataUiAttributes.Element, value);
export const getDataUiComponentIdAttribute = (componentId: string): ObjectWithDataAttribute =>
  getDataAttribute(DataUiAttributes.ComponentId, componentId);
export const getDataUiElementInComponentAttribute = (objectId: string): ObjectWithDataAttribute =>
  getDataAttribute(DataUiAttributes.ElementInComponent, objectId);
export const getDataUiObjectNameAttribute = (objectName: string): ObjectWithDataAttribute =>
  getDataAttribute(DataUiAttributes.ObjectName, objectName);
export const getDataUiColumnNameAttribute = (columnName: string): ObjectWithDataAttribute =>
  getDataAttribute(DataUiAttributes.ColumnName, columnName);
export const getDataUiActiveCapabilitiesDataAttributes = (
  activeCapabilities: ReadonlyArray<ActiveCapabilityType>,
): ObjectWithDataAttribute =>
  getDataAttribute(DataUiAttributes.ActiveCapabilities, activeCapabilities.join(','));

export enum DataUiAppName {
  AccessDenied = 'access-denied',

  // ============== Home =================
  Custom = 'custom',
  Home = 'home',
  Calendar = 'calendar',
  ContentStatus = 'content-status',
  ProjectOverview = 'project-overview',
  YourContent = 'your-content',
  YourContentAssignedToYou = 'your-content--assigned-to-you',
  YourContentRecentlyEditedByYou = 'your-content--edited-by-you',
  YourContentYourTasks = 'your-content--your-tasks',

  // ============== Mission control =================
  MissionControl = 'mission-control',

  // ============== Content inventory =================
  ContentInventory = 'content-inventory',

  AssetListing = 'asset-listing',
  AssetEditing = 'asset-editing',
  ContentItemListing = 'content-item-listing',
  ContentItemEditing = 'content-item-editing',
  Relations = 'relations',

  // =============== Models =================
  ContentModels = 'content-models',

  AssetType = 'asset-type',
  ContentTypeEditing = 'content-type-editing',
  ContentTypeListing = 'content-type-listing',
  SitemapListing = 'sitemap-listing',
  SnippetEditing = 'snippet-editing',
  SnippetListing = 'snippet-listing',
  TaxonomyGroupEditing = 'taxonomy-group-editing',
  TaxonomyGroupListing = 'taxonomy-group-listing',

  // ============== Settings ==============
  ActiveProjects = 'active-projects',
  ApiKeys = 'api-keys',
  ArchivedProjects = 'archived-projects',
  AuditLog = 'audit-log',
  MultipleWorkflows = 'multiple-workflows',
  ContentCollections = 'content-collections',
  EnvironmentSettings = 'environment-settings',
  Environments = 'environments',
  General = 'general-settings',
  InnovationLab = 'early-access-program',
  LegacyWebhooks = 'legacy-webhooks',
  Localization = 'localization',
  PreviewUrls = 'preview-urls',
  ProjectSettings = 'project-settings',
  Projects = 'projects',
  Quickstart = 'quickstart',
  RoleEditing = 'role-editing',
  Roles = 'roles',
  Spaces = 'spaces',
  Users = 'users',
  WebhookMessages = 'webhook-messages',
  Webhooks = 'webhooks',
  WebSpotlightSettings = 'web-spotlight-settings',
  WorkflowStepsListing = 'workflow-steps-listing',

  // =========== User profile ==============
  SubscriptionAdmins = 'subscription-admins',
  SubscriptionApiKeys = 'subscription-api-keys',
  SubscriptionBillingHistory = 'subscription-billing-history',
  SubscriptionBillingInfo = 'subscription-billing-info',
  SubscriptionEditing = 'subscription-editing',
  SubscriptionGeneral = 'subscription-general',
  SubscriptionListing = 'subscription-listing',
  SubscriptionPlans = 'subscription-plans',
  SubscriptionProjects = 'subscription-projects',
  SubscriptionTransactions = 'subscription-transactions',
  SubscriptionUsage = 'subscription-usage',
  SubscriptionUsers = 'subscription-users',
  UserProfile = 'user-profile',

  // ========== Web spotlight ============
  WebSpotlight = 'web-spotlight',
  WebSpotlightEditor = 'web-spotlight-editor',
  WebSpotlightPreview = 'web-spotlight-preview',
}

export enum DataUiExternalLinkName {
  Documentation = 'documentation',
  KeyboardShortcuts = 'keyboard-shortcuts',
  ProductUpdates = 'product-updates',
  Roadmap = 'roadmap',
  UxFeedback = 'ux-feedback',
}

export enum DataUiAction {
  AcceptTerms = 'acceptTerms',
  Activate = 'activate',
  ActivateUser = 'activate-user',
  Add = 'add',
  AddException = 'add-exception',
  AddRootItem = 'add-root-item',
  AddScope = 'add-scope',
  AddTask = 'add-task',
  AiAction = 'ai-action',
  Archive = 'archive',
  AssignLanguageRole = 'assign-language-role',
  Cancel = 'cancel',
  AssignTerms = 'assign-terms',
  Clone = 'clone',
  ChangePlan = 'change-plan',
  ClearFilter = 'clear-filter',
  ClearInput = 'clear-input',
  CloseDialog = 'close-dialog',
  CloseSidebar = 'close-sidebar',
  CloseSubscriptionAdminInfo = 'close-subscription-admin-info',
  Collapse = 'collapse',
  CollapseAllComponents = 'collapse-all-components',
  CompareVersions = 'compare-versions',
  Configure = 'configure',
  ConfigureColumnSettings = 'configure-column-settings',
  Confirm = 'confirm',
  Continue = 'continue',
  ConvertComponentToItemVariant = 'convert-component-to-item-variant',
  CopyAssetUrl = 'copy-asset-url',
  CopyFromLanguage = 'copy-from-language',
  CopyToClipboard = 'copy-to-clipboard',
  CreateFirst = 'create-first',
  CreateFolder = 'create-folder',
  CreateNew = 'create-object',
  CreateNewOption = 'create-new-option',
  CreateNewRootItem = 'create-new-root-item',
  CreateNewVersion = 'create-new-version',
  CreateSubscription = 'create-subscription',
  Deactivate = 'deactivate',
  Delete = 'delete',
  DeleteAssetFolder = 'delete-asset-folder',
  DeleteScope = 'delete-scope',
  DescribeWithAi = 'describe-with-ai',
  DeselectAll = 'deselect-all',
  Disable = 'disable',
  Discard = 'discard',
  DiscardAndNavigate = 'discard-and-navigate',
  DismissUserFeedback = 'dismiss-user-feedback',
  DownloadFile = 'download-file',
  Drag = 'drag',
  Duplicate = 'duplicate',
  Edit = 'edit',
  EditAsset = 'edit-asset',
  EditSubscription = 'edit-subscription',
  Enable = 'enable',
  Expand = 'expand',
  ExpandAllComponents = 'expand-all-components',
  ExpandAllTypes = 'expand-all-types',
  ExportToPdf = 'export-to-pdf',
  GetCodename = 'get-codename',
  GoToEnvironment = 'go-to-environment',
  GoToNextMonth = 'go-to-next-month',
  GoToPreviousMonth = 'go-to-prev-month',
  GoToSettings = 'go-to-settings',
  GoToSpaces = 'go-to-spaces',
  HideComments = 'hide-comments',
  Insert = 'insert',
  InviteExistingUsers = 'invite-existing-users',
  LinkLike = 'link-like',
  Logout = 'logout',
  ManageCreditCard = 'manage-credit-card',
  Move = 'Move',
  MoveAssetsToCollection = 'move-assets-to-collection',
  MoveAssetsToFolder = 'move-assets-to-folder',
  MoveAssetFolder = 'move-asset-folder',
  MoreActions = 'more-actions',
  MoveItemsToCollection = 'move-items-to-collection',
  OpenAssetFoldersSelector = 'open-asset-folders-selector',
  OpenCalendarArrangementSelector = 'open-calendar-arrangement-selector',
  OpenContentItem = 'open-content-item',
  OpenCollectionSelection = 'open-collection-selection',
  OpenContentItemSidebar = 'open-content-item-sidebar',
  OpenFilter = 'open-filter',
  OpenDocsMenu = 'open-docs-menu',
  OpenDuplicateMenu = 'open-duplicate-menu',
  OpenEnvironmentMenu = 'open-environment-menu',
  OpenFallbacksPopover = 'open-fallbacks-popover',
  OpenFeedbackMenu = 'open-feedback-menu',
  OpenInNewTab = 'open-in-new-tab',
  OpenIntercom = 'open-intercom',
  OpenLanguageSelector = 'open-language-selector',
  OpenPreview = 'open-preview',
  OpenProject = 'open-project',
  OpenProjectMenu = 'open-project-menu',
  OpenRoleSelector = 'open-role-selector',
  OpenSpacesMenu = 'open-spaces-menu',
  OpenTraining = 'training',
  OpenUserMenu = 'open-user-menu',
  OpenWebhook = 'open-webhook',
  PickAsset = 'pick-asset',
  PickDateTime = 'pick-date-time',
  PromoteExistingUsers = 'promote-existing-users',
  PromoteUserToAdmin = 'promote-user-to-admin',
  Refresh = 'refresh',
  Regenerate = 'regenerate',
  Remove = 'remove',
  RemoveRoleBuilderRow = 'remove-role-builder-row',
  Rename = 'rename',
  Replace = 'replace',
  Resend = 'resend',
  Reset = 'reset',
  Revoke = 'revoke',
  Save = 'save',
  SaveAndNavigate = 'save-and-navigate',
  SaveDateTime = 'save-date-time',
  Select = 'select',
  SelectAll = 'select-all',
  SelectCreditCard = 'select-credit-card',
  SelectFiles = 'select-files',
  SelectNextMonth = 'select-next-month',
  SelectPreviousMonth = 'select-previous-month',
  SendAdminInvitation = 'send-admin-invitation',
  SendUserFeedback = 'send-user-feedback',
  SendInvitation = 'send-invitation',
  SetUpProjectAsWebsite = 'set-up-project-as-website',
  Share = 'share',
  Show = 'show',
  ShowItemsWithoutDueDate = 'show-items-without-due-date',
  ShowResolvedComments = 'show-resolved-comments',
  ShowDiscussion = 'show-discussion',
  ShowWebhookMessageContent = 'show-webhook-message-content',
  ShowWebhookMessageResponse = 'show-webhook-message-response',
  ShowContentComponentConversionConfirmationDialog = 'show-content-component-conversion-confirmation-dialog',
  ShowConfirmation = 'show-confirmation',
  StartSubscription = 'start-subscription',
  StartTrial = 'start-trial',
  Submit = 'submit',
  SuggestWithAi = 'suggest-with-ai',
  SwapEnvironments = 'swap-environments',
  ThumbsDown = 'thumbs-down',
  ThumbsUp = 'thumbs-up',
  ToggleAutorefresh = 'toggle-autorefresh',
  ToggleEditableElements = 'toggle-editable-elements',
  ToggleWebSpotlightFeature = 'toggle-web-spotlight-feature',
  Translate = 'translate',
  TranslateVariantMenu = 'translateVariantMenu',
  Undo = 'undo',
  Unlink = 'unlink',
  WebSpotlightPreviewActions = 'web-spotlight-preview-actions',
  WorkflowStepOverviewItem = 'workflow-step-overview-item',
  WorkflowStepOverviewBarItem = 'workflow-step-overview-bar-item',
}

export enum DataUiRteAction {
  AddAssetLink = 'add-asset-link',
  AddBlock = 'add-block',
  AddComment = 'add-comment',
  AddEmailLink = 'add-email-link',
  AddImage = 'add-image',
  AddItem = 'add-item',
  AddItemLink = 'add-item-link',
  AddPhoneLink = 'add-phone-link',
  AddSuggestion = 'add-suggestion',
  AddTable = 'add-table',
  AddWebLink = 'add-web-link',
  AiAction = 'ai-action',
  AskAi = 'ask-ai',
  Bold = 'style-bold',
  ChangeBlockType = 'change-block-type',
  ChangeTone = 'ChangeTone',
  ClearFormatting = 'clear-formatting',
  Code = 'code',
  CreateTitle = 'CreateTitle',
  HeaderOne = 'block-type-header-one',
  HeaderTwo = 'block-type-header-two',
  HeaderThree = 'block-type-header-three',
  HeaderFour = 'block-type-header-four',
  HeaderFive = 'block-type-header-five',
  HeaderSix = 'block-type-header-six',
  ImproveContent = 'ImproveContent',
  InsertNewContentComponent = 'insert-new-content-component',
  Italic = 'style-italic',
  Link = 'link',
  MakeShorter = 'MakeShorter',
  OrderedList = 'block-type-ordered-list',
  Subscript = 'subscript',
  Summarize = 'Summarize',
  Superscript = 'superscript',
  Undo = 'undo',
  Unlink = 'unlink',
  UnorderedList = 'block-type-unordered-list',
  Unstyled = 'block-type-unstyled',
}

export enum DataUiCommentsAction {
  Approve = 'approve',
  Cancel = 'cancel',
  Edit = 'edit',
  GetLink = 'get-link',
  MarkAsResolved = 'mark-as-resolved',
  MarkAsUnresolved = 'mark-as-unresolved',
  ResolveUndo = 'resolve-undo',
  ResolveUndoCancel = 'resolve-undo-cancel',
  OpenCommentMenu = 'open-comment-menu',
  Reply = 'reply',
  SaveComment = 'save-comment',
  Suggest = 'suggest',
}

export enum DataUiSwitchAction {
  SwitchDifferences = 'switch-differences',
  SwitchProjectStatus = 'switch-project-status',
  SwitchUserStatus = 'switch-user-status',
}

export enum DataUiWorkflowAction {
  Archive = 'archive',
  AddNoteForContributors = 'add-note-for-contributors',
  CancelSchedule = 'cancel-schedule',
  CancelScheduledUnpublish = 'cancel-scheduled-unpublish',
  ChangeWorkflow = 'change-workflow',
  ChangeWorkflowStep = 'change-workflow-step',
  CreateNewVersion = 'create-new-version',
  Discard = 'discard',
  EditDueDate = 'edit-due-date',
  EditNote = 'edit-note',
  OpenContributorsSelection = 'open-contributors-selection',
  OpenWorkflowStepModal = 'open-workflow-step-modal',
  Publish = 'publish',
  RestoreFromArchivedStep = 'restore-from-archived-step',
  ScheduleToPublish = 'schedule-to-publish',
  ScheduleToUnpublish = 'schedule-to-unpublish',
  ScrollToIncompleteElements = 'scroll-to-incomplete-items',
  SelectNow = 'select-now',
  SelectSchedule = 'select-schedule',
  Unpublish = 'unpublish',
  UpdateWorkflowStep = 'update-workflow-step',
}

export enum DataUiEntityWebhookAction {
  WebhookPublishedSlotTrigger = 'select-published',
  WebhookPreviewSlotTrigger = 'select-preview',
  WebhookAllEventsTrigger = 'selected-all',
  WebhookSpecificEventsTrigger = 'selected-specific',
}

export enum DataUiRevisionAction {
  RestoreRevision = 'restore-revision',
  SelectRevision = 'select-revision',
}

export enum DataUiAssetRenditionAction {
  ChangeAssetRendition = 'change-asset-rendition',
  CreateAssetRendition = 'create-asset-rendition',
  DeleteCustomizedImage = 'delete-customized-image',
}

export enum DataUiInput {
  ApiKey = 'api-key',
  AssetDescription = 'asset-description',
  CapabilityOption = 'capability-option',
  Checkbox = 'checkbox',
  Codename = 'codename',
  Comment = 'comment',
  Confirm = 'confirm',
  Content = 'content',
  ContentElementRequired = 'content-element-required',
  DateTime = 'date-time',
  DefaultValue = 'default-value',
  DeliveryPreviewPrimaryApiKey = 'delivery-preview-primary-api-key',
  DeliveryPreviewSecondaryApiKey = 'delivery-preview-secondary-api-key',
  DeliveryPrimaryApiKey = 'delivery-primary-api-key',
  DeliverySecondaryApiKey = 'delivery-secondary-api-key',
  Email = 'email',
  EntityName = 'entity-name',
  EntityWebhookCollectionFilter = 'entity-webhook-collection-filter',
  EntityWebhookContentTypeFilter = 'entity-webhook-content-type-filter',
  EntityWebhookLanguageFilter = 'entity-webhook-language-filter',
  EntityWebhookTaxonomyFilter = 'entity-webhook-taxonomy-filter',
  EntityWebhookWorkflowStep = 'entity-webhook-workflow-step',
  EnvironmentName = 'environment-name',
  Environments = 'environments',
  Filter = 'filter',
  FilterName = 'filter-name',
  FirstName = 'first-name',
  HeaderKey = 'header-key',
  HeaderValue = 'header-value',
  Id = 'id',
  ImageHeight = 'image-height',
  ImageWidth = 'image-width',
  IncludeContentAndAssets = 'include-content-and-assets',
  ItemName = 'item-name',
  ItemTextElement = 'item-text-element',
  LanguageName = 'language-name',
  LastName = 'last-name',
  Mention = 'mention',
  ModalDialogAssetName = 'modal-dialog-asset-name',
  MultipleChoiceMode = 'multiple-choice-mode',
  MultipleChoiceOption = 'multiple-choice-option',
  NonLocalizable = 'non-localizable',
  Number = 'number',
  NumberLimit = 'number-limit',
  OpenInNewWindow = 'open-in-new-window',
  PhoneNumber = 'phone-number',
  ProjectName = 'project-name',
  Secret = 'secret',
  Search = 'search',
  SharedWithUsersProjectManagersSelectorItems = 'shared-with-users-project-managers-selector-items',
  SharedWithUsersSelectorItems = 'shared-with-users-selector-items',
  SitemapNodeName = 'sitemap-node-name',
  SpaceDomain = 'space-domain',
  SubscriptionName = 'subscription-name',
  Suggestion = 'suggestion',
  Task = 'task',
  TaskAssignees = 'task-assignees',
  TaxonomyTermName = 'taxonomy-term-name',
  Text = 'text',
  Time = 'time',
  Title = 'title',
  TypeElementGuidelines = 'type-element-guidelines',
  TypeElementName = 'type-element-name',
  TypeElementSelect = 'type-element-select',
  UploadFiles = 'upload-files',
  Url = 'url',
  UrlPattern = 'url-pattern',
  WebhookDeliveryItemTriggers = 'webhook-delivery-item-triggers',
  WebhookManagementItemTriggers = 'webhook-management-item-trigger',
  WebhookName = 'webhook-name',
  WebhookPreviewDeliveryItemTriggers = 'webhook-preview-delivery-item-triggers',
  WebhookPreviewTaxonomyTriggers = 'webhook-preview-taxonomy-triggers',
  WebhookTaxonomyTriggers = 'webhook-taxonomy-triggers',
  WebhookWorkflowStepTriggers = 'webhook-workflow-step-triggers',
  WebSpotlightPreviewHeight = 'web-spotlight-preview-height',
  WebSpotlightPreviewScale = 'web-spotlight-preview-scale',
  WebSpotlightPreviewWidth = 'web-spotlight-preview-width',
  WorkflowPublishStepRolesForCreateNew = 'workflow-step-roles-create-new',
  WorkflowPublishStepRolesForUnpublish = 'workflow-step-roles-unpublish',
  WorkflowStepName = 'workflow-step-name',
  WorkflowStepRoles = 'workflow-step-roles',
  WorkflowStepTransitionsFrom = 'workflow-step-transitions-from',
  WorkflowStepTransitionsTo = 'workflow-step-transitions-to',
}

export enum DataUiCollection {
  AiGuidelines = 'ai-guidelines',
  AiActions = 'ai-actions',
  ApiKeys = 'api-keys',
  AssetDescriptions = 'asset-descriptions',
  AssetFolders = 'asset-folders',
  Assets = 'assets',
  AuditLogActions = 'audit-log-actions',
  AuditLogObjectTypes = 'audit-log-object-types',
  BillingHistory = 'billing-history',
  BlockOptions = 'block-options',
  Breadcrumbs = 'breadcrumbs',
  CalendarArrangementSelector = 'calendar-arrangement-selector',
  ColumnHeaderCollection = 'column-header-collection',
  ColumnSettings = 'column-settings',
  CommentMenuOptions = 'comment-menu-options',
  ContentCollections = 'content-collections',
  ContentElementList = 'content-element-list',
  ContentElements = 'content-elements',
  ContentGroups = 'content-groups',
  ContentItemFilter = 'content-item-filter',
  ContentItems = 'content-items',
  ContentItemUsages = 'content-item-usages',
  ContentModuleListing = 'content-module-listing',
  ContentPaceDetailListing = 'content-pace-detail-listing',
  ContentTasks = 'content-tasks',
  ContentTypeElements = 'content-type-elements',
  ContentTypeFilter = 'content-type-filter',
  ContentTypes = 'content-types',
  Contributors = 'contributors',
  CopyFromLanguageVariant = 'copy-from-language-variant',
  DependentContentElements = 'dependent-content-elements',
  DocsMenuOptions = 'doc-menu-options',
  EntityWebhooks = 'entity-webhooks',
  Environments = 'environments',
  FeedbackMenuOptions = 'feedback-menu-options',
  InvitationOptions = 'invitation-options',
  ItemDetailSections = 'item-detail-sections',
  ItemLinkContentTypes = 'item-link-content-types',
  LanguageFallbackOptions = 'language-fallback-options',
  LanguageFilter = 'language-filter',
  LanguageList = 'language-list',
  LanguageSelectorItems = 'language-selector-items',
  LimitationFormatting = 'limitation-formatting',
  MissionControlNavigationMenu = 'mission-control-navigation-menu',
  MoreActionsDropdown = 'more-actions-dropdown',
  MultipleChoiceOptions = 'multiple-choice-options',
  PreviewUrls = 'preview-urls',
  PreviewUrlsSection = 'preview-urls-section',
  ProjectLocations = 'project-locations',
  ProjectOrderBy = 'project-order-by',
  Projects = 'projects',
  ProjectsList = 'projects-list',
  ProjectTemplates = 'project-templates',
  PublishingStates = 'publishing-states',
  QuickActionDropdown = 'quick-action-dropdown',
  QuickActions = 'quick-actions',
  Revisions = 'revisions',
  RoleList = 'role-list',
  RteBlockTypeMenuOptions = 'rte-block-type-menu-options',
  RteInsertBlockMenuOptions = 'rte-insert-block-menu-options',
  RteLinkMenuOptions = 'rte-link-menu-options',
  RteTableMenuOptions = 'rte-table-menu-options',
  SearchScope = 'search-scope',
  SendToContentGroupOptions = 'send-to-content-group-options',
  Sitemap = 'sitemap',
  Snippets = 'snippets',
  SortOptions = 'sort-options',
  Spaces = 'spaces',
  Statuses = 'statuses',
  SubscriptionAdmins = 'subscription-admins',
  Subscriptions = 'subscriptions',
  SubscriptionUsers = 'subscription-users',
  SupportingElementList = 'supporting-element-list',
  Taxonomies = 'taxonomies',
  TaxonomyFilter = 'taxonomy-filter',
  TaxonomyTerms = 'taxonomy-terms',
  Transactions = 'transactions',
  UserMenu = 'user-menu',
  UserRoles = 'user-roles',
  UsersList = 'users-list',
  WebhookMessages = 'webhook-messages',
  WebhookMessagesFilterOptions = 'webhook-messages-filter-options',
  WebhookTriggers = 'webhook-triggers',
  WorkflowList = 'workflow-list',
  Workflows = 'workflows',
  WorkflowStepColors = 'workflow-step-colors',
  WorkflowSteps = 'workflow-steps',

  // conditions
  ConditionAction = 'condition-action',
  ConditionOperator = 'condition-operator',
  ConditionTriggerElement = 'condition-trigger-element',
  ConditionTriggerOptions = 'condition-trigger-options',

  // comments
  CommentInlineThreads = 'inline-comment-threads',
  CommentMenuItems = 'comment-menu-items',
  CommentThreads = 'comment-threads',
  CommentThreadItems = 'comment-thread-items',

  // tabs
  TabItems = 'tab-items',
  TabItemsExpanded = 'tab-items-expanded',
  TabItemsCollapsed = 'tab-items-collapsed',
  SubscriptionTabs = 'subscription-tabs',

  // workflow
  ScheduleMethodSelector = 'schedule-method-selector',
  WorkflowStepDropdownItems = 'workflow-step-dropdown-items',

  // role capabilities
  CapabilityRules = 'capability-rules',
  CapabilityRuleExceptions = 'capability-rule-exceptions',
  RuleCapabilities = 'rule-capabilities',
  RuleCapabilityScopes = 'rule-capability-scopes',

  // users
  UserStatuses = 'user-statuses',

  WarningMessageActions = 'warning-message-actions',
}

export enum ItemDetailSection {
  Assignment = 'assignment',
  AssignmentSection = 'assignment-section',
  AssignmentNote = 'assignment-note',
  ContentCollectionsSection = 'content-collections-section',
  ContentType = 'content-type',
  ContributorsSection = 'contributors-section',
  CurrentStatus = 'current-status',
  DueDateSection = 'due-date-section',
  IncompleteItemElementsNotification = 'incomplete-item-elements-notification',
  LastUpdated = 'last-updated',
  NoteAuthor = 'note-author',
  NoteSection = 'note-section',
  NoteText = 'note-text',
  PublishedStatus = 'published-status',
  PublishingStatus = 'publishing-status',
  Sitemap = 'sitemap',
  SpacesSection = 'spaces-section',
  StatusName = 'status-name',
  TasksSection = 'tasks-section',
  UnpublishDateSection = 'unpublish-date-section',
  WorkflowSection = 'workflow-section',
  Usage = 'usage',
}

export enum Popovers {
  ContentComponentConversionConfirmationDialog = 'content-component-conversion-confirmation-dialog',
  ContentComponentDeleteConfirmationDialog = 'content-component-delete-confirmation-dialog',
  FallbacksPopover = 'fallbacks-popover',
  InvitationConfirmationPopover = 'invitation-confirmation-popover',
  QuickActionPopover = 'quick-action-popover',
  RteInlineToolbar = 'rte-inline-toolbar',
  UserDetailActivationPopover = 'user-detail-activation-popover',
  UserStateChangePopover = 'user-state-change-popover',
}

export enum ItemEditingForms {
  Collection = 'collection',
  Contributors = 'contributors',
  DueDate = 'due-date',
  Note = 'note',
  Spaces = 'spaces',
  Tasks = 'tasks',
  Workflow = 'workflow',
}

export enum DataUiElement {
  AIActionInProgress = 'ai-action-in-progress',
  AgendaItem = 'agenda-item',
  AgendaItemName = 'agenda-item-name',
  AiResult = 'ai-result',
  AiToolbar = 'ai-toolbar',
  AiTranslateButton = 'ai-translate-button',
  AiReviewButton = 'ai-review-button',
  Alert = 'alert',
  ApiKeyCard = 'api-key-card',
  ApiKeyCardTitle = 'api-key-card-title',
  ApiKeyInputGroup = 'api-key-input-group',
  ApiKeyModalInfo = 'api-key-modal-info',
  AssetCollection = 'asset-collection',
  AssetDescription = 'asset-description',
  AssetFolder = 'asset-folder',
  AssetFolderBreadcrumbItem = 'asset-folder-breadcrumb-item',
  AssetFolderBreadcrumbsPopover = 'asset-folder-breadcrumbs-popover',
  AssetListingEmptySearch = 'asset-listing-empty-search',
  AssetRenditionDialog = 'asset-rendition-dialog',
  AssetRenditionRemoveConfirmationDialog = 'asset-rendition-remove-confirmation-dialog',
  AssetThumbnailImage = 'asset-thumbnail-image',
  AssetThumbnailImageMissing = 'asset-thumbnail-image-missing',
  AssetThumbnailImageUnknown = 'asset-thumbnail-image-unknown',
  AssetUploader = 'asset-uploader',
  AssignedSpacesMessage = 'assigned-spaces-message',
  Assignment = 'assignment',
  AuditLogEvent = 'audit-log-event',
  AuditLogEventDescription = 'audit-log-event-description',
  AuditLogEventDetails = 'audit-log-event-details',
  AuditLogFilter = 'audit-log-filter',
  Badge = 'badge',
  BarItem = 'bar-item',
  BarItemForm = 'bar-item-form',
  BarItemLanguageFields = 'bar-item-language-fields',
  BarItemList = 'bar-item-list',
  BarItemNode = 'bar-item-node',
  Breadcrumbs = 'breadcrumbs',
  BreadcrumbsEllipsis = 'breadcrumbs-ellipsis',
  CalendarDateCell = 'calendar-data-cell',
  CalendarDateCellOutOfRange = 'calendar-data-cell--out-of-range',
  CalendarDateCellTitle = 'calendar-data-cell-title',
  CalendarDateCellTitleToday = 'calendar-data-cell-title--today',
  CalendarLegend = 'calendar-legend',
  CalendarMonthName = 'calendar-month-name',
  CalendarMonthRow = 'calendar-month-row',
  CalendarMonthView = 'calendar-month-view',
  CalendarPopup = 'calendar-popup',
  CalendarView = 'calendar-view',
  CalendarWeekdayHeader = 'calendar-weekday-header',
  CalloutQuickTip = 'callout-quick-tip',
  CalloutWarning = 'callout-warning',
  CapabilityExceptionRule = 'capability-exception-rule',
  CapabilityRule = 'capability-rule',
  CascadePublishChildItem = 'cascade-publish-child-item',
  CascadePublishDialog = 'cascade-publish-dialog',
  CascadePublishFailedItemsDialog = 'cascade-publish-failed-items-dialog',
  CellItemLink = 'cell-item-link',
  ChangeWorkflowStepSection = 'change-workflow-step-section',
  CodenameForm = 'codename-form',
  CollectionsGroup = 'collections-group',
  ColumnHeaderCollection = 'column-header-collection',
  ColumnHeaderComments = 'column-header-comments',
  ColumnHeaderContentType = 'column-header-content-type',
  ColumnHeaderContributors = 'column-header-contributors',
  ColumnHeaderDueDate = 'column-header-due-date',
  ColumnHeaderLanguage = 'column-header-language',
  ColumnHeaderLastModified = 'column-header-last-modified',
  ColumnHeaderName = 'column-header-name',
  ColumnHeaderPublished = 'column-header-published',
  ColumnHeaderSpace = 'column-header-space',
  ColumnHeaderTasks = 'column-header-tasks',
  ColumnHeaderWorkflowStep = 'column-header-workflow-step',
  ColumnOptionCollection = 'column-option-collection',
  ColumnOptionContentType = 'column-option-content-type',
  ColumnOptionDueDate = 'column-option-due-date',
  ColumnOptionLastModified = 'column-option-last-modified',
  ColumnOptionPublished = 'column-option-published',
  ColumnOptionSpace = 'column-option-space',
  ColumnOptionWorkflowStep = 'column-option-workflow-step',
  CommentButton = 'comment-button',
  CommentReference = 'comment-reference',
  CommentStatusIsResolved = 'comment-status-is-resolved',
  CommentThread = 'comment-thread',
  CommentThreadItem = 'comment-thread-item',
  ComparisonKey = 'comparison-key',
  ConfigSection = 'config-section',
  ConfirmationDialog = 'confirmation-dialog',
  ContentChangesWarningDialog = 'content-changes-warning-dialog',
  ContentComponentContent = 'content-component-content',
  ContentComponentConvertedToItemInfo = 'content-component-converted-to-item-info',
  ContentComponentElements = 'content-component-elements',
  ContentComponentItem = 'content-component-item',
  ContentComponentTitle = 'content-component-title',
  ContentComponentType = 'content-component-type',
  ContentElement = 'content-element',
  ContentElementCollection = 'content-element-collection',
  ContentElementCollectionSharedSpaceMessage = 'content-element-collection-shared-space-message',
  ContentElementConfigPane = 'content-element-config-pane',
  ContentElementGuidelines = 'content-element-guidelines',
  ContentElementRequired = 'content-element-required',
  ContentGroup = 'content-group',
  ContentGroupName = 'content-group-name',
  ContentGroupsInfoMessage = 'content-groups-info-message',
  ContentItemDetailsSidebar = 'content-item-details-sidebar',
  ContentItemEditor = 'content-item-editor',
  ContentModule = 'content-module',
  ContentTypeElementConditionConfiguration = 'content-type-element-condition-configuration',
  ContentTypeElementValidationWarning = 'content-type-element-validation-warning',
  ContributorSelector = 'contributor-selector',
  CopyFromLanguageNotification = 'copy-from-language-notification',
  CreateEnvironmentDialog = 'create-environment-dialog',
  CurrentEnvironmentLabel = 'current-environment-label',
  CurrentLanguageTag = 'current-language-tag',
  CustomElementAllowedElements = 'custom-element-allowed-elements',
  CustomElementParameters = 'custom-element-parameters',
  CustomElementUrl = 'custom-element-url',
  DapiAccessCard = 'dapi-access-card',
  DapiNavigationTab = 'dapi-navigation-tab',
  DapiPreviewAccessCheckbox = 'dapi-preview-access-checkbox',
  DapiSecureAccessCheckbox = 'dapi-secure-access-checkbox',
  DateTimePickerCurrentMonth = 'datetime-picker-current-month',
  DateTimePickerInput = 'datetime-picker-input',
  DefaultValueStatusMessage = 'default-value-status-message',
  DeleteEnvironmentDialog = 'delete-environment-dialog',
  Dialog = 'dialog',
  DiscussionSidebar = 'discussion-sidebar',
  Dropzone = 'drop-zone',
  ElementErrorText = 'element-error-text',
  ElementWarningText = 'element-warning-text',
  ElementHint = 'element-hint',
  ElementLimitations = 'element-limitations',
  ElementStatusPane = 'element-status-pane',
  EmptyState = 'empty-state',
  EmptyStateTitle = 'empty-state-title',
  EnabledApisCard = 'enabled-apis-card',
  EnabledApisCardTitle = 'enabled-apis-card-title',
  EntityWebhookEventTriggerDetails = 'entity-webhook-event-trigger-details',
  EnvironmentCard = 'environment-card',
  EnvironmentCardTitle = 'environment-card-title',
  EnvironmentIdCard = 'environment-id-card',
  EnvironmentIdCardTitle = 'environment-id-card-title',
  EnvironmentIdInput = 'environment-id-input',
  EnvironmentNameCard = 'environment-name-card',
  EnvironmentNameCardTitle = 'environment-name-card-title',
  EnvironmentNameInput = 'environment-name-input',
  EnvironmentPlaceholder = 'environment-placeholder',
  EnvironmentsSelector = 'environments-selector',
  FairUsePolicySummaryTable = 'fair-use-policy-summary-table',
  FallbackLanguageTag = 'fallback-language-tag',
  FeedbackItem = 'feedback-item',
  FileSizeLimitation = 'file-size-limitation',
  FileTypeLimitation = 'file-type-limitation',
  Filter = 'filter',
  FilterForm = 'filter-form',
  FormGroup = 'form-group',
  FormSection = 'form-section',
  GeneratingSecretMessage = 'generating-secret-message',
  HeightLimitation = 'height-limitation',
  InlineImage = 'inline-image',
  InvalidElements = 'invalid-elements',
  InvitationModalDialog = 'invitation-modal-dialog',
  ItemElementMetadata = 'item-metadata',
  ItemsCountLimitation = 'items-count-limitation',
  ItemsTableColumnCollection = 'items-table-column-collection',
  ItemsTableColumnComments = 'items-table-column-comments',
  ItemsTableColumnContributors = 'items-table-column-contributors',
  ItemsTableColumnDueDate = 'items-table-column-due-date',
  ItemsTableColumnLanguage = 'items-table-column-language',
  ItemsTableColumnLastModified = 'items-table-column-last-modified',
  ItemsTableColumnName = 'items-table-column-name',
  ItemsTableColumnPublished = 'items-table-column-published',
  ItemsTableColumnSpace = 'items-table-column-space',
  ItemsTableColumnTasks = 'items-table-column-tasks',
  ItemsTableColumnType = 'items-table-column-type',
  ItemsTableColumnWorkflowStep = 'items-table-column-workflow-step',
  LimitationCategoryContent = 'limitation-category-content',
  LimitationCategoryHeader = 'limitation-category-header',
  LinkedContentFallbackSummary = 'linked-content-fallback-summary',
  ListingMessage = 'listing-message',
  ListingMessageTitle = 'listing-message-title',
  Loader = 'loader',
  ManagementApiActivator = 'management-api-activator',
  ManagementApiManageAssetsPermissionCheckbox = 'management-api-manage-assets-permission-checkbox',
  ManagementApiManageContentModelPermissionCheckbox = 'management-api-manage-content-model-permission-checkbox',
  ManagementApiManageContentPermissionCheckbox = 'management-api-manage-content-permission-checkbox',
  ManagementApiManageEnvironmentSettingsPermissionCheckbox = 'management-api-manage-environment-settings-permission-checkbox',
  ManagementApiManageEnvironmentsPermissionCheckbox = 'management-api-manage-environments-permission-checkbox',
  ManagementApiPermissionsCard = 'management-api-permissions-card',
  ManagementApiReadAssetsPermissionCheckbox = 'management-api-read-assets-permission-checkbox',
  ManagementApiReadContentPermissionCheckbox = 'management-api-read-content-permission-checkbox',
  MapiNavigationTab = 'mapi-navigation-tab',
  MarkElementAsLocalizableDialog = 'mark-element-as-localizable-dialog',
  MarketplaceModal = 'marketplace-modal',
  MaskModal = 'mask-modal',
  ModalDialog = 'modal-dialog',
  ModalDialogFooter = 'modal-dialog-footer',
  ModalDialogHeadline = 'modal-dialog-headline',
  MoveItemsFailedDialog = 'move-items-failed-dialog',
  MultiSelectDropdown = 'multi-select-dropdown',
  MultiSelectOption = 'multi-select-option',
  MultiSelectOptionArea = 'multi-select-option-area',
  MultiSelectOptionName = 'multi-select-option-name',
  MultiSelectPermanentOption = 'multi-select-permanent-option',
  MultiSelectSearchText = 'multi-select-search-text',
  NavigationBar = 'navigation-bar',
  NavigationTab = 'navigation-tab',
  NewContentItemForm = 'new-content-item-form',
  NotTranslatedTag = 'not-translated-tag',
  NotificationAlert = 'notification-alert',
  NotificationBarInfo = 'notification-bar-info',
  NotificationBarItemLiveUsers = 'notification-bar-item-live-users',
  NotificationBarItemOverwriteInfo = 'notification-bar-item-overwrite-info',
  NotificationMessageError = 'notification-message-error',
  NotificationMessageWarning = 'notification-message-warning',
  NotificationText = 'notification-text',
  PackageSummaryTable = 'package-summary-table',
  PageTitle = 'page-title',
  PlanningStatus = 'planning-status',
  PlanningStatusTile = 'planning-status-tile',
  PlanningStatusTileCaption = 'planning-status-tile-caption',
  Popover = 'popover',
  PreviewItem = 'preview-item',
  PreviewUrl = 'preview-url',
  ProductionEnvironmentCard = 'production-environment-card',
  ProductivityTip = 'productivity-tip',
  ProjectCopyStatus = 'project-copy-status',
  ProjectDeleteStatus = 'project-delete-status',
  ProjectNameCard = 'project-name-card',
  ProjectNameCardTitle = 'project-name-card-title',
  ProjectNameInput = 'project-name-input',
  ResolvedCommentsSidebar = 'resolved-comments-sidebar',
  RevisionItemDetailWorkflowName = 'revision-item-detail-workflow-name',
  RevisionNotification = 'revision-notification',
  RevisionTitle = 'revision-title',
  RichTextEditor = 'rich-text-editor',
  RichTextElement = 'rich-text-element',
  RichTextViewer = 'rich-text-viewer',
  RoleBuilder = 'role-builder',
  RoleBuilderRow = 'role-builder-row',
  RteBlockToolbar = 'rte-block-toolbar',
  RteComponentsAndItemsLimitationCategory = 'rte-components-and-items-limitation-category',
  RteContent = 'rte-content',
  RteImagesLimitationCategory = 'rte-images-limitation-category',
  RteLinkDetail = 'rte-link-detail',
  RteLinkDialog = 'rte-link-dialog',
  RteTablesLimitationCategory = 'rte-tables-limitation-category',
  RteTextLimitationCategory = 'rte-text-limitation-category',
  SavedFiltersSection = 'saved-filters-section',
  SavingInProgressButtonIcon = 'saving-in-progress-button-icon',
  ScrollTable = 'scroll-table',
  SectionHeadline = 'section-headline',
  SecureDeliveryApiActivator = 'secure-delivery-api-activator',
  SelectedCollection = 'collections-selector',
  SettingGroup = 'settings-group',
  SettingGroupItem = 'settings-group-item',
  SharedWithUsersProjectManagersSelector = 'shared-with-users-project-managers-selector',
  SharedWithUsersSelector = 'shared-with-users-selector',
  SidebarContent = 'sidebar-content',
  SidebarDivider = 'sidebar-divider',
  SidebarSection = 'sidebar-section',
  SidebarSubmit = 'sidebar-submit',
  SimpleStatus = 'simple-status',
  SingleEaseQuestion = 'single-ease-question',
  SingleSelectDropdown = 'single-select-dropdown',
  SingleSelectDropdownInput = 'single-select-dropdown-input',
  SingleSelectDropdownOption = 'single-select-dropdown-option',
  SingleSelectDropdownSelectedOptionPane = 'single-select-dropdown-selected-option-pane',
  SingleSelectDropdownSelectedOptionText = 'single-select-dropdown-selected-option-text',
  Skeleton = 'skeleton',
  SpaceBarCollections = 'space-bar-collections',
  SpaceBarName = 'space-bar-name',
  SpaceDomain = 'space-domain',
  SpaceNameInput = 'space-name-input',
  Status = 'status',
  StatusBar = 'status-bar',
  StatusMessage = 'status-message',
  StatusMessageIcon = 'status-message-icon',
  StatusPane = 'status-pane',
  StatusPaneError = 'status-pane-error',
  StatusPaneInProgress = 'status-pane-in-progress',
  StatusPaneSuccess = 'status-pane-success',
  SubscriptionAdminsDialog = 'subscription-admins-dialog',
  SubscriptionIdCard = 'subscription-id-card',
  SubscriptionPlanIncludedUsersLimitReachedWarning = 'subscription-plan-included-users-limit-reached-warning',
  SubscriptionPlanMaxUsersLimitReachedWarning = 'subscription-plan-max-users-limit-reached-warning',
  SubscriptionUsersRemainingSeats = 'subscription-users-remaining-seats',
  SwapEnvironmentDialog = 'swap-environment-dialog',
  SwitchWrapper = 'switch-wrapper',
  TaskForm = 'task-form',
  TextFilter = 'text-filter',
  TextLengthLimitation = 'text-length-limitation',
  TextValidationRegexLimitationCategory = 'text-validation-regex-limitation-category',
  TooltipText = 'tooltip-text',
  TransactionSummary = 'transaction-summary',
  UiBlockingMessage = 'ui-blocking-message',
  UmuxFeedbackItem = 'umux-feedback-item',
  UmuxFeedbackModalDialog = 'umux-feedback-modal-dialog',
  UncategorizedLabel = 'uncategorized-label',
  UnsavedChangesDialog = 'unsaved-changes-dialog',
  UnpublishScheduleSection = 'unpublish-schedule-section',
  UnpublishScheduleInformation = 'unpublish-schedule-information',
  UploadDropzone = 'upload-dropzone',
  UsageSummaryTable = 'usage-summary-table',
  UsedInItem = 'used-in-item',
  UserConfiguration = 'user-configuration',
  UserDetailModalDialog = 'user-detail-modal-dialog',
  UserFeedbackSmiley = 'user-feedback-smiley',
  UserProjectSettings = 'user-project-settings',
  VariantLanguageLabel = 'variant-language-label',
  VariantLanguageRow = 'variant-language-row',
  WebSpotlightPreviewAutoRefreshStatus = 'web-spotlight-preview-autorefresh-status',
  WebSpotlightResolutionTypeSelect = 'web-spotlight-resolution-type-select',
  WebSpotlightTreeItem = 'web-spotlight-tree-item',
  WebhookMessageDialog = 'webhook-message-dialog',
  WidthLimitation = 'width-limitation',
  WorkflowOverview = 'workflow-overview',
  WorkflowOverviewItemName = 'workflow-overview-item-name',
  WorkflowStatus = 'workflow-status',
  WorkflowStepContainer = 'workflow-step-container',
  WorkflowStepForm = 'workflow-step-form',
  WorkflowStepFormNew = 'workflow-step-form--new',
  WorkflowStepOverviewItemCount = 'workflow-step-overview-item-count',
}

export enum DataUiStatusBarActions {
  NavigateBack = 'navigate-back',
  CloseRevisions = 'close-revisions',
}

export enum DataUiSimpleStatusObjectName {
  Default = 'default',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}
