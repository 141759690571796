import { assert } from '@kontent-ai/utils';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { modalWithPropertiesOpened } from '../../../_shared/actions/sharedActions.ts';
import { ModalDialogType } from '../../../_shared/constants/modalDialogType.ts';
import { ContentItemRoute } from '../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageIdOrThrow } from '../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import { getCurrentUserRoleForCollectionForLanguageOrThrow } from '../../../_shared/utils/permissions/getContributorRole.ts';
import { getLinkedContentItemPath } from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IContentType } from '../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { ILinkedItemsTypeElementData } from '../../contentModels/shared/models/elements/LinkedItemsTypeElementData.ts';
import { isSubpagesTypeElement } from '../../contentModels/shared/types/typeElementTypeGuards.ts';
import {
  getAllowedTypes,
  getUsableContentTypes,
} from '../../itemEditor/features/ContentItemEditing/utils/modularContentUtils.ts';
import { prepareNewContentItemDialogForWebSpotlight } from '../../itemEditor/features/NewContentItem/actions/thunkNewContentItemActions.ts';
import { getAvailableCollectionsForContentItem } from '../../itemEditor/features/NewContentItem/utils/getAvailableCollectionsForContentItem.ts';
import { getWorkflowsCurrentUserCanCreateVariantIn } from '../../itemEditor/selectors/workflows/getWorkflowsCurrentUserCanCreateVariantIn.ts';
import {
  createAndLinkContentItem,
  linkPageItemsAndReload,
  loadSubpagesActionPermissionsForVariant,
} from '../actions/thunkWebSpotlightActions.ts';
import {
  webSpotlightNodeBlurred,
  webSpotlightNodeFocused,
} from '../actions/webSpotlightActions.ts';
import {
  ITreeItemMoreActionsOwnProps,
  TreeItemMoreActions as TreeItemMoreActionsComponent,
} from '../components/tree/TreeItemMoreActions.tsx';
import { NodeId } from '../types/nodeId.type.ts';

type IContainerProps = ITreeItemMoreActionsOwnProps & {
  readonly itemPath: string;
  readonly nodeId: NodeId;
  readonly variantId: Uuid;
};

const getSubpagesElementData = (contentType: IContentType): ILinkedItemsTypeElementData | null => {
  const typeElements = contentType.typeElements;
  const subpagesElementData = typeElements.find(isSubpagesTypeElement);
  return subpagesElementData ?? null;
};

const getTypesForCollection = (
  allowedContentTypes: ReadonlyArray<IContentType>,
  collectionId: Uuid | null,
  state: IStore,
): Immutable.Set<IContentType> | null => {
  if (collectionId) {
    const { settings } = getCurrentUserRoleForCollectionForLanguageOrThrow(
      state,
      collectionId,
      getSelectedLanguageIdOrThrow(state),
    );
    return getUsableContentTypes(allowedContentTypes, state.data.contentTypes.byId, settings);
  }

  return null;
};

export const TreeItemMoreActions: React.FC<IContainerProps> = (props) => {
  const dispatch = useDispatch();

  const singleUsableCollectionId = useSelector((s) => {
    const selectedLanguageId = getSelectedLanguageIdOrThrow(s);
    const collections = selectedLanguageId
      ? getAvailableCollectionsForContentItem(selectedLanguageId, s)
      : [];

    return collections.length === 1 ? (collections[0]?.id ?? null) : null;
  });

  const contentType = useSelector((s) => s.data.contentTypes.byId.get(props.typeId));

  const subpagesElementData = contentType ? getSubpagesElementData(contentType) : null;
  const subpagesById = useSelector((s) => s.webSpotlightApp.subpagesById);
  const moreActionsCheckResult = useSelector((s) => s.webSpotlightApp.moreActionsCheckResult);

  const allowedContentTypes = useSelector((s) =>
    getAllowedTypes(
      subpagesElementData?.allowedTypes || [],
      s.data.contentTypes.byId,
      true,
    ).toArray(),
  );
  const typesForCollection = useSelector((s) =>
    getTypesForCollection(allowedContentTypes, singleUsableCollectionId, s),
  );
  const allContentTypes = useSelector((s) => s.data.contentTypes.byId);

  const singleUsableContentTypeId = useMemo(() => {
    const contentTypes = typesForCollection || allContentTypes.toSet();
    return contentTypes.size === 1 ? (contentTypes.first()?.id ?? null) : null;
  }, [typesForCollection, allContentTypes]);

  const userHasToSelectWorkflow = useSelector(
    (s) =>
      getWorkflowsCurrentUserCanCreateVariantIn(
        s,
        getSelectedLanguageIdOrThrow(s),
        singleUsableCollectionId,
        singleUsableContentTypeId,
      ).length > 1,
  );
  const history = useHistory();

  const linkItems = (subpagesElementId: Uuid, contentItemIds: UuidArray) =>
    dispatch(
      linkPageItemsAndReload(
        props.itemId,
        props.variantId,
        subpagesElementId,
        props.nodeId,
        contentItemIds,
      ),
    );

  const canCreateItemWithoutSelection =
    !!singleUsableCollectionId && !!singleUsableContentTypeId && !userHasToSelectWorkflow;

  const onCreateNewItem = async (subpagesElementId: Uuid) => {
    const createdItemId = await dispatch(
      createAndLinkContentItem({
        contentCollectionId: canCreateItemWithoutSelection ? singleUsableCollectionId : undefined,
        contentTypeId: canCreateItemWithoutSelection ? singleUsableContentTypeId : undefined,
        itemId: props.itemId,
        parentPath: props.itemPath,
        subpagesElementId,
        variantId: props.variantId,
      }),
    );
    const itemPath = getLinkedContentItemPath(props.itemPath, createdItemId, ContentItemRoute);
    history.push(itemPath);
  };

  const onNodeBlurred = useCallback(
    () => dispatch(webSpotlightNodeBlurred(props.nodeId)),
    [props.nodeId],
  );
  const onNodeFocused = () => dispatch(webSpotlightNodeFocused(props.nodeId));
  const loadActionPermissions = () =>
    dispatch(loadSubpagesActionPermissionsForVariant(props.variantId, props.itemId));

  const onOpenCreateNewItemDialog = useCallback(() => {
    assert(
      subpagesElementData?.allowedTypes || subpagesElementData?.elementId,
      () => `${__filename} component: Subpages element for type ${props.typeId} was not found.`,
    );

    dispatch(
      prepareNewContentItemDialogForWebSpotlight({
        allowedContentTypeIds: Immutable.Set(subpagesElementData.allowedTypes),
      }),
    );

    dispatch(
      modalWithPropertiesOpened({
        dialogType: ModalDialogType.NewContentItemDialogForWebSpotlight,
        itemId: props.itemId,
        itemPath: props.itemPath,
        subpagesElementId: subpagesElementData.elementId,
        variantId: props.variantId,
      }),
    );
  }, [
    subpagesElementData?.allowedTypes,
    subpagesElementData?.elementId,
    props.typeId,
    props.itemId,
    props.itemPath,
    props.variantId,
  ]);

  if (!contentType) {
    return null;
  }

  return (
    <TreeItemMoreActionsComponent
      {...props}
      canCreateItemWithoutSelection={canCreateItemWithoutSelection}
      contentType={contentType}
      linkItems={linkItems}
      loadActionPermissions={loadActionPermissions}
      moreActionsCheckResult={moreActionsCheckResult}
      onCreateNewItem={onCreateNewItem}
      onNodeBlurred={onNodeBlurred}
      onNodeFocused={onNodeFocused}
      onOpenCreateNewItemDialog={onOpenCreateNewItemDialog}
      subpagesById={subpagesById}
      subpagesElementData={subpagesElementData}
    />
  );
};
