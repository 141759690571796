import React, { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { TextFilter } from '../../../../../_shared/components/TextFilter.tsx';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { queryChanged } from '../../actions/assetLibraryActions.ts';
import { MaxAssetFullTextSearchPhraseLength } from '../../constants/uiConstants.ts';
import { useAssetSearchPlaceholderTypewriterAnimation } from '../../hooks/useAssetSearchPlaceholderTypewriterAnimation.ts';
import { areSearchPhrasesEqual } from '../../models/IAssetLibraryQuery.ts';

const placeholder = {
  prefix: 'Search for assets by ',
  suffixes: ['their name or description', 'what’s in the image, such as, person or animal'],
};

export const AssetSearchPhrase: React.FC = () => {
  const reduxValue = useSelector((s) => s.assetLibraryApp.query.searchPhrase);
  const lastDispatchedValueRef = useRef(reduxValue);
  const [value, setValue] = useState<string>(reduxValue);

  useEffect(() => {
    setValue(reduxValue);
    lastDispatchedValueRef.current = reduxValue;
  }, [reduxValue]);

  const dispatch = useDispatch();
  const setReduxValueDebounced = useDebouncedCallback((newValue: string) => {
    if (!areSearchPhrasesEqual({ searchPhrase: newValue }, { searchPhrase: reduxValue })) {
      dispatch(queryChanged({ searchPhrase: newValue }));
    }
  }, 300);

  const { text: placeholderText, stopAnimation } = useAssetSearchPlaceholderTypewriterAnimation(
    placeholder.prefix,
    placeholder.suffixes,
  );

  const onChange = (newValue: string) => {
    setValue(newValue);
    setReduxValueDebounced(newValue);
    stopAnimation();
  };

  return (
    <TextFilter
      autofocus
      className="asset-library__search-phrase"
      onClick={stopAnimation}
      onChange={onChange}
      placeholder={placeholderText}
      text={value}
      maxLength={MaxAssetFullTextSearchPhraseLength}
      ariaLabel="Search for assets"
    />
  );
};

AssetSearchPhrase.displayName = 'AssetSearchPhrase';
