import { Avatar, AvatarSize } from '@kontent-ai/component-library/Avatar';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { FontWeight } from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled from 'styled-components';
import { NotificationBarInfo } from '../../../../../../../component-library/components/NotificationBar/NotificationBar.tsx';
import { tagHeight } from '../../../../../../../component-library/components/Tag/tokens.ts';
import { IUserInfo } from '../../../../../../_shared/models/UserInfo.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { pluralizeWithCount } from '../../../../../../_shared/utils/stringUtils.ts';
import {
  formatUserName,
  getExistingUserNewColorGradient,
  getUserInitials,
} from '../../../../../../_shared/utils/usersUtils.ts';
import { IProjectContributor } from '../../../../../../data/models/users/ProjectContributor.ts';

export interface IItemLiveUsersNotificationBarOwnProps {
  readonly showUserNamesInMessage?: boolean;
}

export interface IItemLiveUsersNotificationBarStateProps {
  readonly currentUser: IProjectContributor | null;
  readonly fellowLiveUsers: ReadonlyArray<IUserInfo>;
}

export type ItemLiveUsersNotificationBarProps = IItemLiveUsersNotificationBarOwnProps &
  IItemLiveUsersNotificationBarStateProps;

const MaxFellowUserCount = 3;

const CountWithTooltip = styled.span`
  text-decoration: underline;
  cursor: default;
`;

const SlightEmphasis = styled.span`
  overflow: hidden;
  line-height: ${tagHeight}
  font-weight: ${FontWeight.Medium}
`;

const MessageOverlap = ({
  overlapCount,
  overlappedUserNames,
}: { readonly overlappedUserNames: string; readonly overlapCount: number }) => (
  <>
    {' + '}
    <Tooltip placement="bottom" tooltipText={overlappedUserNames}>
      <CountWithTooltip>{pluralizeWithCount('other', overlapCount)}</CountWithTooltip>
    </Tooltip>
  </>
);

export const ItemLiveUsersNotificationBar: React.FC<ItemLiveUsersNotificationBarProps> = ({
  currentUser,
  fellowLiveUsers,
  showUserNamesInMessage,
}) => {
  if (fellowLiveUsers.length === 0 || !currentUser) {
    return null;
  }

  const displayedFellowUsers = fellowLiveUsers.slice(0, MaxFellowUserCount);
  const overlapCount = fellowLiveUsers.length - MaxFellowUserCount;
  const overlappedUserNames = fellowLiveUsers
    .slice(MaxFellowUserCount)
    .map((u) => formatUserName(u))
    .join('\n');

  const messageWithUserNames = (
    <>
      {'This content item is currently opened by: '}
      <SlightEmphasis>
        {displayedFellowUsers.map((u) => formatUserName(u)).join(', ')}
        {overlapCount > 0 && (
          <MessageOverlap overlapCount={overlapCount} overlappedUserNames={overlappedUserNames} />
        )}
        {displayedFellowUsers.length > 1 ? ',' : ''}
      </SlightEmphasis>
      {' and '}
      <SlightEmphasis>you</SlightEmphasis>.
    </>
  );

  return (
    <NotificationBarInfo
      {...getDataUiElementAttribute(DataUiElement.NotificationBarItemLiveUsers)}
      actions={{
        alignment: 'left',
        renderActions: () => (
          <div className="avatar-chain">
            {overlapCount > 0 && (
              <Tooltip tooltipText={overlappedUserNames} placement="bottom">
                <div className="avatar-chain__overlap">{`+${overlapCount}`}</div>
              </Tooltip>
            )}
            <div className="avatar-chain__avatar" key={currentUser.userId}>
              <Avatar
                backgroundGradient={getExistingUserNewColorGradient(currentUser)}
                label={formatUserName(currentUser)}
                initials={getUserInitials(currentUser)}
                size={AvatarSize.M}
              />
            </div>
            {displayedFellowUsers.reverse().map(
              (user) =>
                user && (
                  <div className="avatar-chain__avatar" key={user.userId}>
                    <Avatar
                      backgroundGradient={getExistingUserNewColorGradient(user)}
                      label={formatUserName(user)}
                      initials={getUserInitials(user)}
                      size={AvatarSize.M}
                    />
                  </div>
                ),
            )}
          </div>
        ),
      }}
    >
      {showUserNamesInMessage ? messageWithUserNames : 'Others are editing this item: '}
    </NotificationBarInfo>
  );
};
