import { useCallback, useEffect } from 'react';
import { getContentStateActionResult } from '../../../../../_shared/features/AI/helpers/transformAiResult.ts';
import {
  AiTaskProgress,
  useAiTaskProgress,
} from '../../../../../_shared/features/AI/hooks/aiTasks/useAiTaskProgress.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { ActiveCapabilityType } from '../../../../../_shared/models/activeCapability.type.ts';
import { Asset, IAsset } from '../../../../../data/models/assets/Asset.ts';
import {
  assetEditorModalClosed,
  setEditedAsset,
} from '../../../../contentInventory/assets/actions/assetLibraryActions.ts';
import {
  initializeAssetEditDialog,
  trackUserActionOnAssetUpdate,
  updateAsset,
  validateAssetsInEditedItem,
} from '../../../../contentInventory/assets/actions/thunkAssetLibraryActions.ts';
import { aiIsWorkingOnDescription } from '../../../../contentInventory/assets/constants/aiConstants.ts';
import { assetAlreadyModifiedSaveTooltip } from '../../../../contentInventory/assets/constants/uiConstants.ts';
import { matchAnyTranslateDescriptionAiTaskInProgress } from '../../../../contentInventory/assets/utils/matchAnyTranslateDescriptionAiTaskInProgress.tsx';
import { matchDescribeImageAiTask } from '../../../../contentInventory/assets/utils/matchDescribeImageAiTask.tsx';
import { AssetEditorDialog } from '../components/AssetEditorDialog.tsx';

interface IAssetEditorDialogContainerProps {
  readonly assetId: Uuid;
  readonly onAssetEditingFinished: () => void;
}

export const ModalAssetEditor = ({
  assetId,
  onAssetEditingFinished,
}: IAssetEditorDialogContainerProps) => {
  const originalAsset = useSelector((state) => state.data.assets.byId.get(assetId));

  const {
    assetEditorLoadingStatus,
    editedAsset,
    hasConflict,
    isEditedAssetBeingSaved,
    isEditedAssetModified,
  } = useSelector((state) => state.assetLibraryApp.assetUiEditor);

  const taxonomyGroups = useSelector((state) => state.data.taxonomyGroups.byId);

  const isLoading = assetEditorLoadingStatus !== LoadingStatus.Loaded;
  const canUpdateAssets = originalAsset
    ? Asset.hasCapability(originalAsset, ActiveCapabilityType.UpdateAssets)
    : false;

  const describeImageAiTask = useAiTaskProgress(
    getContentStateActionResult,
    matchDescribeImageAiTask(assetId),
  );

  const translateDescriptionAiTaskInProgress = useAiTaskProgress(
    getContentStateActionResult,
    matchAnyTranslateDescriptionAiTaskInProgress(assetId),
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeAssetEditDialog(assetId));
  }, [assetId]);

  const onCancel = useCallback(() => {
    onAssetEditingFinished();
    dispatch(assetEditorModalClosed());
  }, [onAssetEditingFinished]);

  const onChange = useCallback(
    (updatedAsset: IAsset) => dispatch(setEditedAsset(updatedAsset)),
    [],
  );

  const onSaveAsset = useCallback(
    async (asset: IAsset) => {
      const generatedAiDescription =
        describeImageAiTask.progress === AiTaskProgress.Completed
          ? (describeImageAiTask.result.content?.getPlainText() ?? null)
          : null;

      dispatch(trackUserActionOnAssetUpdate(asset, generatedAiDescription));
      await dispatch(updateAsset(asset));
      dispatch(validateAssetsInEditedItem());
      onAssetEditingFinished();
      dispatch(assetEditorModalClosed());
    },
    [describeImageAiTask, onAssetEditingFinished],
  );

  const isAiWorkingOnDescription =
    describeImageAiTask.progress === AiTaskProgress.Running ||
    translateDescriptionAiTaskInProgress.progress === AiTaskProgress.Running;

  const getDisabledSaveButtonTooltipText = () => {
    if (isAiWorkingOnDescription) {
      return aiIsWorkingOnDescription;
    }

    return hasConflict ? assetAlreadyModifiedSaveTooltip : undefined;
  };

  const isSaveButtonDisabled =
    isLoading ||
    isEditedAssetBeingSaved ||
    hasConflict ||
    !isEditedAssetModified ||
    isAiWorkingOnDescription;

  return originalAsset ? (
    <AssetEditorDialog
      assetTitle={editedAsset?.title ?? null}
      assetTitlePlaceholder={originalAsset?.filename}
      canUpdateAssets={canUpdateAssets}
      disabledSaveButtonTooltipText={getDisabledSaveButtonTooltipText()}
      editedAsset={editedAsset}
      isBeingSaved={isEditedAssetBeingSaved}
      isLoading={isLoading}
      isSaveButtonDisabled={isSaveButtonDisabled}
      onCancel={onCancel}
      onChange={onChange}
      onSaveAsset={onSaveAsset}
      taxonomyGroups={taxonomyGroups}
    />
  ) : null;
};

ModalAssetEditor.displayName = 'ModalAssetEditor';
