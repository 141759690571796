import { assert } from '@kontent-ai/utils';
import classNames from 'classnames';
import { DraftBlockRenderMap } from 'draft-js';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { CSSProperties, MouseEvent, ReactNode, RefObject } from 'react';
import { waitUntilFocusAndScrollAreNotDeferred } from '../../../../../_shared/utils/autoScrollUtils.ts';
import { logError } from '../../../../../_shared/utils/logError.ts';
import {
  SelectableContainerClassName,
  getBlockFromBlockElement,
} from '../../../editorCore/utils/editorComponentUtils.ts';
import { isBlockNestedIn } from '../../../utils/blocks/blockTypeUtils.ts';
import { getCoordinatesFromDepth } from '../api/depthCoordinatesConversion.ts';
import { RowCells, Table } from './Table.tsx';
import { TableCell } from './TableCell.tsx';
import { isPointerApiEnabled } from './TableColumnResizer.tsx';
import { resizeTableColumns } from './tableColumnResizeHandlers.ts';

const ExtraSpaceForTableCarets = 3;

function getWidthStyle(width: number | undefined): CSSProperties | undefined {
  if (width === undefined) {
    return undefined;
  }
  return { width: `${Math.max(Math.round(width), 0)}px` };
}

interface ITableCellWrapperProps {
  readonly children?: ReactNode;
  readonly beforeTable?: ReactNode;
  readonly getRenderMap: () => DraftBlockRenderMap;
  readonly onContextMenu?: (blockKey: string, cellRef: React.RefObject<HTMLElement>) => void;
}

interface ITableCellWrapperState {
  readonly colSizes: Immutable.Map<number, number>;
  readonly reachedMaxWidth: boolean;
  readonly reachedMaxWidthChecked: boolean;
}

export class TableCellWrapper extends React.PureComponent<
  ITableCellWrapperProps,
  ITableCellWrapperState
> {
  static displayName = 'TableCellWrapper';

  static propTypes: PropTypesShape<ITableCellWrapperProps> = {
    beforeTable: PropTypes.node,
    children: PropTypes.node,
    getRenderMap: PropTypes.func.isRequired,
    onContextMenu: PropTypes.func,
  };

  private readonly headingCellRefs = new Map<number, RefObject<HTMLDivElement>>();
  private readonly tableWrapperRef = React.createRef<HTMLDivElement>();

  constructor(props: ITableCellWrapperProps) {
    super(props);

    this.state = {
      colSizes: Immutable.Map(),
      reachedMaxWidth: false,
      reachedMaxWidthChecked: false,
    };
  }

  componentDidMount(): void {
    // Observe mouse events and start resizing table columns when column edge is dragged
    if (isPointerApiEnabled && this.tableWrapperRef.current) {
      this.tableWrapperRef.current.addEventListener('pointerdown', this._onResizeStart);
    }
  }

  componentWillUnmount(): void {
    if (isPointerApiEnabled && this.tableWrapperRef.current) {
      this.tableWrapperRef.current.removeEventListener('pointerdown', this._onResizeStart);
    }

    this.deferredTrackReachedMaxWidth.cancel();
  }

  private readonly _trackReachedMaxWidth = () => {
    this.setState((prevState) => {
      const wrapper = this.tableWrapperRef.current;
      if (!wrapper || !wrapper.parentElement) {
        return null;
      }

      const hasMaxWidth =
        wrapper.parentElement.getBoundingClientRect().width -
          wrapper.getBoundingClientRect().width <=
        ExtraSpaceForTableCarets;
      if (!hasMaxWidth || prevState.reachedMaxWidth) {
        return prevState.reachedMaxWidthChecked
          ? null
          : {
              reachedMaxWidth: false,
              reachedMaxWidthChecked: true,
              colSizes: prevState.colSizes,
            };
      }

      // When table reaches max width and some column sizes are set, capture current column widths to prevent column sizes to jump unexpectedly
      const colSizes = prevState.colSizes.isEmpty()
        ? prevState.colSizes
        : Immutable.Map<number, number>().withMutations((result) => {
            for (let colIndex = 0; colIndex < 1000; colIndex++) {
              const colWidth = this._getColumnWidth(colIndex);
              if (colWidth !== null) {
                result.set(colIndex, colWidth);
              }
            }
          });

      return {
        reachedMaxWidth: true,
        reachedMaxWidthChecked: true,
        colSizes,
      };
    });
  };

  private readonly deferredTrackReachedMaxWidth = waitUntilFocusAndScrollAreNotDeferred(
    this._trackReachedMaxWidth,
  );

  private readonly _onContextMenu = (
    e: MouseEvent,
    blockKey: string,
    cellContentRef: React.RefObject<HTMLDivElement>,
  ) => {
    // Keep native browser behavior with CTRL key
    if (e.ctrlKey) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();

    this.props.onContextMenu?.(blockKey, cellContentRef);
  };

  private readonly _onResizeStart = (event: PointerEvent) =>
    resizeTableColumns(event, this._onResizeColumn);

  private readonly _onResizeColumn = (colIndex: number, delta: number): Promise<number> => {
    return new Promise((resolve) => {
      // Phase 1 - Try to set the desired width
      let resizedOriginalWidth: number | null = null;
      let nextOriginalWidth: number | null = null;
      let appliedDelta = 0;
      let isNextColumnResized = false;

      this.setState(
        (prevState) => {
          resizedOriginalWidth = this._getColumnWidth(colIndex);
          if (resizedOriginalWidth === null) {
            resolve(0);
            return null;
          }

          const desiredNewWidth = Math.max(resizedOriginalWidth + delta, 0);
          appliedDelta = desiredNewWidth - resizedOriginalWidth;

          const withNewWidth = prevState.colSizes.set(colIndex, desiredNewWidth);

          if (!prevState.reachedMaxWidth) {
            return { colSizes: withNewWidth };
          }

          // Compensate the next column size to keep the table consistent and not influence other cells
          // but only in case the table is already at the full available size
          nextOriginalWidth = this._getColumnWidth(colIndex + 1);

          isNextColumnResized = prevState.reachedMaxWidth && nextOriginalWidth !== null;
          const withCompensatedNext =
            isNextColumnResized && nextOriginalWidth !== null
              ? withNewWidth.set(colIndex + 1, Math.max(nextOriginalWidth - appliedDelta, 0))
              : withNewWidth;

          return { colSizes: withCompensatedNext };
        },
        () => {
          // Phase 2 - Verify what was applied, if necessary ensure proper value is remembered and rollback unwanted influence on next column
          const resizedActualWidth = this._getColumnWidth(colIndex);
          if (resizedOriginalWidth === null || resizedActualWidth === null) {
            resolve(0);
            return;
          }

          const resizedActuallyAppliedDelta = resizedActualWidth - resizedOriginalWidth;
          if (resizedActuallyAppliedDelta === appliedDelta) {
            resolve(appliedDelta);
            return;
          }

          const nextActualWidth = this._getColumnWidth(colIndex + 1);
          const nextActuallyAppliedDelta =
            isNextColumnResized && nextOriginalWidth !== null && nextActualWidth !== null
              ? nextActualWidth - nextOriginalWidth
              : resizedActuallyAppliedDelta;

          // The actually applied delta is the smaller of the changes in resize and next column
          // because table layout may weight desired sizes in case all columns have some size specified
          // We need to prevent scenario where the cell resizes just because its desired size grows
          // but there is no more space for the growth because the next cell cannot shrink any more
          const actuallyAppliedDelta =
            isNextColumnResized &&
            Math.abs(resizedActuallyAppliedDelta) > Math.abs(nextActuallyAppliedDelta)
              ? nextActuallyAppliedDelta
              : resizedActuallyAppliedDelta;

          // Adjust the column widths back based on actually applied widths
          this.setState(
            (prevState) => {
              const withActualWidth = prevState.colSizes.set(colIndex, resizedActualWidth);
              const newNextColumnWidth = prevState.colSizes.get(colIndex + 1);
              const withRestoredNext =
                isNextColumnResized && newNextColumnWidth !== undefined
                  ? withActualWidth.set(
                      colIndex + 1,
                      Math.max(newNextColumnWidth + appliedDelta - actuallyAppliedDelta),
                    )
                  : withActualWidth;

              return { colSizes: withRestoredNext };
            },
            () => {
              // Phase 3 - When the actual values needed adjustment, double-check the actual change because neither of the previous deltas may be 100% correct
              const finalWidth = this._getColumnWidth(colIndex);
              if (resizedOriginalWidth === null || finalWidth === null) {
                resolve(0);
                return;
              }

              // Report back finally applied delta to keep it consistent in the part which actually tracks the mouse offset
              const finallyAppliedDelta = finalWidth - resizedOriginalWidth;
              resolve(finallyAppliedDelta);
            },
          );
        },
      );
    });
  };

  private readonly _getRefForHeadingCell = (colIndex: number): React.RefObject<HTMLDivElement> => {
    const existingRef = this.headingCellRefs.get(colIndex);
    if (existingRef) {
      return existingRef;
    }

    const newRef = React.createRef<HTMLDivElement>();
    this.headingCellRefs.set(colIndex, newRef);
    return newRef;
  };

  private readonly _getColumnWidth = (colIndex: number): number | null => {
    const cell = this.headingCellRefs.get(colIndex)?.current;
    if (!cell) {
      return null;
    }
    return cell.getBoundingClientRect().width;
  };

  private readonly _renderRow = (cells: RowCells, rowIndex: number): JSX.Element => {
    const { getRenderMap } = this.props;
    const { colSizes, reachedMaxWidth } = this.state;

    const totalColumns = cells.size;
    const defaultWidthStyle = reachedMaxWidth
      ? { width: `${Math.floor(100 / totalColumns)}%` }
      : undefined;

    const isFirstRow = rowIndex === 0;

    return (
      <div className="rte__table-row" key={rowIndex}>
        {cells
          .map((cellChildren: ReadonlyArray<React.ReactElement<any>>, depth: number) => {
            const colIndex = getCoordinatesFromDepth(depth).x;
            const isLastColumn = colIndex === totalColumns - 1;

            const firstCellChild = cellChildren[0];
            assert(firstCellChild, () => `${__filename}: Child at index 0 is falsy.`);
            const cellBlock = getBlockFromBlockElement(firstCellChild);
            const cellContentBlocks = cellChildren.filter(
              (child: React.ReactElement<any>): boolean => {
                const childBlock = getBlockFromBlockElement(child);
                return !!childBlock && isBlockNestedIn(childBlock, cellBlock);
              },
            );

            const style = isFirstRow
              ? (getWidthStyle(colSizes.get(colIndex)) ?? defaultWidthStyle)
              : undefined;

            if (!cellContentBlocks.length) {
              logError('Table cell is missing content blocks');
            }

            return (
              <TableCell
                key={colIndex}
                ref={this._getRefForHeadingCell(colIndex)}
                blockKey={cellBlock.getKey()}
                columnIndex={colIndex}
                hasResizer={!isLastColumn || !reachedMaxWidth}
                blockRenderMap={getRenderMap()}
                onContextMenu={this._onContextMenu}
                style={style}
              >
                {cellContentBlocks}
              </TableCell>
            );
          })
          .toArray()}
      </div>
    );
  };

  render(): JSX.Element {
    return (
      <div
        ref={this.tableWrapperRef}
        className={classNames(SelectableContainerClassName, 'rte__table', {
          'rte__table--reached-max-width': this.state.reachedMaxWidth,
        })}
      >
        {this.props.beforeTable}
        <Table onResize={this.deferredTrackReachedMaxWidth} renderRow={this._renderRow}>
          {this.props.children}
        </Table>
      </div>
    );
  }
}
