import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DefaultLanguageId } from '../../../../../_shared/constants/variantIdValues.ts';
import { getStringActionResult } from '../../../../../_shared/features/AI/helpers/transformAiResult.ts';
import { useAiTask } from '../../../../../_shared/features/AI/hooks/aiTasks/useAiTask.ts';
import { useOnFinishedAiActionTask } from '../../../../../_shared/features/AI/hooks/aiTasks/useOnFinishedAiActionTask.ts';
import { useAiActionTrackingWithSession } from '../../../../../_shared/features/AI/hooks/useAiActionTrackingWithSession.ts';
import { usePendingAiActionWithSession } from '../../../../../_shared/features/AI/hooks/usePendingAiActionWithSession.ts';
import { AiActionTrackingProps } from '../../../../../_shared/features/AI/types/AiActionTrackingProps.type.ts';
import { AiOperationState } from '../../../../../_shared/features/AI/types/AiOperationState.ts';
import { AiError } from '../../../../../_shared/features/AI/types/aiErrors.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { AiActionSource } from '../../../../../_shared/models/events/AiActionEventData.type.ts';
import { getSelectedLanguageId } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { isEmptyOrWhitespace } from '../../../../../_shared/utils/stringUtils.ts';
import { useEditorStateCallbacks } from '../../../../../applications/richText/editorCore/hooks/useEditorStateCallbacks.ts';
import { useEditorWithPlugin } from '../../../../../applications/richText/editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../../../../applications/richText/editorCore/types/Editor.composition.type.ts';
import { None } from '../../../../../applications/richText/editorCore/types/Editor.contract.type.ts';
import {
  Apply,
  EditorPlugin,
  Render,
} from '../../../../../applications/richText/editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../../../applications/richText/editorCore/utils/decorable.ts';
import { withDisplayName } from '../../../../../applications/richText/editorCore/utils/withDisplayName.ts';
import { CommentsPlugin } from '../../../../../applications/richText/plugins/comments/CommentsPlugin.tsx';
import { StylesPlugin } from '../../../../../applications/richText/plugins/visuals/StylesPlugin.tsx';
import { isContentEmpty } from '../../../../../applications/richText/utils/general/editorContentUtils.ts';
import { AiActionName } from '../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { createReviewByGuidelinesRequest } from '../../../repositories/serverModels/AiServerModels.reviewByGuidelines.ts';
import { AiReviewAction } from './components/AiReviewAction.tsx';
import { useCreateReviewComments } from './useCreateReviewComments.tsx';
import { ReviewCommentServerModel, parseReviewComments } from './utils/reviewCommentServerModel.ts';
import { getNewComments, getReviewComments } from './utils/reviewCommentUtils.ts';

type ReviewByGuidelinesPluginProps = AiActionTrackingProps & {
  readonly aiGuidelinesIds: ReadonlyArray<Uuid>;
};

export type ReviewByGuidelinesPlugin = EditorPlugin<
  None,
  ReviewByGuidelinesPluginProps,
  None,
  [StylesPlugin, CommentsPlugin]
>;

const getGuidelines = (state: IStore, aiGuidelinesIds: ReadonlyArray<Uuid>): string => {
  const selectedGuidelineId = aiGuidelinesIds[0];
  return (
    (selectedGuidelineId && state.data.aiGuidelines.byId.get(selectedGuidelineId)?.guidelines) ?? ''
  );
};

export const useReviewByGuidelines: PluginCreator<ReviewByGuidelinesPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('ReviewByGuidelinesPlugin', {
        ComposedEditor: (props) => {
          const { element, aiGuidelinesIds } = props;

          const isDefaultLanguage = useSelector(
            (state) => getSelectedLanguageId(state) === DefaultLanguageId,
          );

          const guidelines = useSelector((state) => getGuidelines(state, aiGuidelinesIds));
          const hasGuidelines = !isEmptyOrWhitespace(guidelines);

          const [lastError, setLastError] = useState<AiError | null>(null);

          const {
            aiSessionId,
            elementOperationTrackingData,
            trackFinishedAction,
            trackStartingAction,
          } = useAiActionTrackingWithSession(props.element);

          const { decorateWithEditorStateCallbacks, getEditorState } =
            useEditorStateCallbacks<ReviewByGuidelinesPlugin>();

          const { aiOperationState, startPendingAiAction, finishPendingAiAction } =
            usePendingAiActionWithSession(aiSessionId);

          const { run, result } = useAiTask(AiActionName.ReviewByGuidelines, getStringActionResult);

          const { createComments, decorateWithCreateCommentsCallbacks } =
            useCreateReviewComments(element);

          // We keep only server model at this level for more effective detection of new comments without having to compare content states
          const comments = parseReviewComments(result);
          const processedComments = useRef<ReadonlyArray<ReviewCommentServerModel>>([]);

          useEffect(() => {
            if (comments?.length) {
              const newParsedComments = getNewComments(comments, processedComments.current);
              if (!newParsedComments.length) {
                return;
              }

              const newComments = getReviewComments(newParsedComments);
              if (!newComments.length) {
                return;
              }

              createComments(newComments);
              processedComments.current = [...processedComments.current, ...newParsedComments];
            }
          }, [comments, createComments]);

          useOnFinishedAiActionTask(result.isFinished, () => {
            if (result.trackingParams) {
              trackFinishedAction(result.trackingParams);
            }
            setLastError(result.error);
            finishPendingAiAction();
          });

          const startReviewByGuidelinesAction = useCallback(() => {
            processedComments.current = [];
            startPendingAiAction();

            const content = getEditorState().getCurrentContent();

            run(createReviewByGuidelinesRequest(content, guidelines, elementOperationTrackingData));

            trackStartingAction({
              action: AiActionName.ReviewByGuidelines,
              source: AiActionSource.Element,
            });
          }, [
            elementOperationTrackingData,
            getEditorState,
            guidelines,
            run,
            startPendingAiAction,
            trackStartingAction,
          ]);

          const renderOverlays: Decorator<Render<ReviewByGuidelinesPlugin>> = useCallback(
            (baseRenderOverlays) => (state) => {
              const isEmpty = isContentEmpty(state.editorState.getCurrentContent());
              const disabledTooltipText = getDisabledTooltipText(
                aiOperationState,
                isDefaultLanguage,
                hasGuidelines,
                isEmpty,
              );

              return (
                <>
                  {baseRenderOverlays(state)}
                  <AiReviewAction
                    aiOperationState={aiOperationState}
                    disabled={!!disabledTooltipText}
                    element={element}
                    error={lastError}
                    perform={startReviewByGuidelinesAction}
                    tooltipText={disabledTooltipText ?? 'Review content with AI'}
                  />
                </>
              );
            },
            [
              aiOperationState,
              element,
              isDefaultLanguage,
              hasGuidelines,
              lastError,
              startReviewByGuidelinesAction,
            ],
          );

          const apply: Apply<ReviewByGuidelinesPlugin> = useCallback(
            (state) => {
              decorateWithCreateCommentsCallbacks(state);
              decorateWithEditorStateCallbacks(state);
              state.renderOverlays.decorate(renderOverlays);

              return {};
            },
            [decorateWithCreateCommentsCallbacks, decorateWithEditorStateCallbacks, renderOverlays],
          );

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );

const getDisabledTooltipText = (
  aiOperationState: AiOperationState,
  isDefaultLanguage: boolean,
  hasGuidelines: boolean,
  isEmpty: boolean,
): string | null => {
  if (aiOperationState === AiOperationState.Pending) {
    return 'The AI is reviewing your content based on the guidelines specified for this element.';
  }

  if (!isDefaultLanguage) {
    return 'You can review content with AI only in the default language';
  }

  if (!hasGuidelines) {
    return 'This element is missing content review guidelines. Ask your project manager to add them.';
  }

  if (isEmpty) {
    return 'When you provide content, you can use AI to review it based on your guidelines';
  }

  return null;
};
