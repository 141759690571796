import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { getCurrentProjectId } from '../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { loadBreadcrumbs } from '../actions/thunkSharedActions.ts';
import {
  ApplicationBreadcrumbs as ApplicationBreadcrumbsComponent,
  IApplicationBreadcrumbsProps,
} from '../components/ApplicationBreadcrumbs.tsx';
import { NavigationBreadcrumb } from '../components/Breadcrumbs.tsx';
import { ProjectsRoute } from '../constants/routePaths.ts';
import { useDispatch } from '../hooks/useDispatch.ts';
import { useSelector } from '../hooks/useSelector.ts';
import { Breadcrumb } from '../models/Breadcrumb.type.ts';
import { isMultipleWorkflowsConfigurationVisible } from '../selectors/Workflows/isMultipleWorkflowsConfigurationVisible.ts';
import { getBreadcrumbs } from '../selectors/breadcrumbs/getBreadcrumbs.ts';
import { getBreadcrumbsOrigin } from '../selectors/breadcrumbs/getBreadcrumbsOrigin.ts';
import { getSelectedLanguageIdOrRouteMacro } from '../selectors/getSelectedLanguageId.ts';
import { replaceLanguageMacroInBreadcrumbs } from '../utils/breadcrumbs/breadcrumbResolvers.ts';
import { canGoUpToMyProjects } from '../utils/breadcrumbs/breadcrumbsUtils.ts';
import { isUuid } from '../utils/validation/typeValidators.ts';

type ApplicationBreadcrumbsOwnProps = Pick<
  IApplicationBreadcrumbsProps,
  'renderSavingStatusComponent'
>;

const getNavigateBackPath = (pathname: string, breadcrumbs: Immutable.List<Breadcrumb>): string => {
  const firstBreadcrumb = breadcrumbs.first();
  if (canGoUpToMyProjects(pathname) && firstBreadcrumb) {
    return breadcrumbs.count() === 1 ? ProjectsRoute : firstBreadcrumb.path;
  }
  return '';
};

const decorateBreadcrumbs = memoize.maxOne(
  (
    currentProjectId: Uuid,
    breadcrumbs: Immutable.List<Breadcrumb>,
    selectedLanguageId: Uuid,
  ): Immutable.List<NavigationBreadcrumb> => {
    return replaceLanguageMacroInBreadcrumbs(breadcrumbs, selectedLanguageId)
      .map((breadcrumb: Breadcrumb) => ({
        label: breadcrumb.title,
        to: `${
          breadcrumb.useProjectIdPrefix && isUuid(currentProjectId) ? `/${currentProjectId}` : ''
        }${breadcrumb.path}`,
      }))
      .toList();
  },
);

export const ApplicationBreadcrumbs: React.FC<
  React.PropsWithChildren<ApplicationBreadcrumbsOwnProps>
> = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const breadcrumbs = useSelector(getBreadcrumbs);
  const breadcrumbsOrigin = useSelector(getBreadcrumbsOrigin);
  const isMultipleWorkflowsConfigurationAllowed = useSelector(
    isMultipleWorkflowsConfigurationVisible,
  );
  const listingContentItems = useSelector((state) => state.data.listingContentItems);
  const navigateBackPath = getNavigateBackPath(location.pathname, breadcrumbs);
  const projectId = useSelector(getCurrentProjectId);
  const selectedLanguageId = useSelector(getSelectedLanguageIdOrRouteMacro);

  useEffect(() => {
    dispatch(
      loadBreadcrumbs({
        location,
        breadcrumbsOrigin,
        isMultipleWorkflowsConfigurationAllowed,
        listingContentItems,
      }),
    );
  }, [location, breadcrumbsOrigin, isMultipleWorkflowsConfigurationAllowed, listingContentItems]);

  return (
    <ApplicationBreadcrumbsComponent
      {...props}
      breadcrumbs={decorateBreadcrumbs(projectId, breadcrumbs, selectedLanguageId)}
      navigateBackPath={navigateBackPath}
    />
  );
};

ApplicationBreadcrumbs.displayName = 'ApplicationBreadcrumbsContainer';
