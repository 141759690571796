import { Box } from '@kontent-ai/component-library/Box';
import { Card } from '@kontent-ai/component-library/Card';
import { PaperLevel } from '@kontent-ai/component-library/Paper';
import { Overlay } from '@kontent-ai/component-library/Scrims';
import { PortalContainerContextProvider } from '@kontent-ai/component-library/context';
import { ComponentLibraryGlobals } from '@kontent-ai/component-library/globals';
import {
  BaseColor,
  spacingElementEdgeHorizontal,
  spacingElementEdgeVertical,
} from '@kontent-ai/component-library/tokens';
import { DisabledFocusLockProp } from '@kontent-ai/component-library/types';
import { useAttachRef } from '@kontent-ai/hooks';
import { useDialog } from '@react-aria/dialog';
import { FocusScope } from '@react-aria/focus';
import { useModal, useOverlay, usePreventScroll } from '@react-aria/overlays';
import { mergeProps } from '@react-aria/utils';
import { animated, useTransition } from '@react-spring/web';
import { AriaDialogProps } from '@react-types/dialog';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../app/_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ScrollContainerContextProvider } from '../../ScrollContainer/ScrollContainerContext.tsx';
import { DialogState } from '../DialogStateEnum.ts';
import { fullScreenModalTransitionsDefinition, modalTransitionsDefinition } from '../animations.ts';
import {
  DialogFooter,
  IDialogFooterProps,
  dialogFooterPropTypes,
} from '../components/DialogFooter.tsx';

export interface IBaseModalDialogProps
  extends Omit<IDialogFooterProps, 'dialogState'>,
    AriaDialogProps,
    DisabledFocusLockProp {
  readonly autoFocusRef?: React.RefObject<HTMLElement>;
  readonly bodyPadding?: 'default' | 'none';
  readonly headline: string;
  readonly isOpen: boolean;
  readonly onReturnFocus?: () => void;
  readonly shouldCloseOnBlur?: boolean;
  readonly shouldCloseOnInteractOutside?: (element: HTMLElement) => boolean;
  readonly withDividers?: boolean;
  readonly state?: DialogState;
}

const { dialogState, ...filteredDialogFooterPropTypes } = dialogFooterPropTypes;

export const baseModalDialogPropTypes: PropTypeMap<
  Omit<IBaseModalDialogProps, keyof AriaDialogProps>
> = {
  ...filteredDialogFooterPropTypes,
  autoFocusRef: PropTypes.any,
  bodyPadding: PropTypes.oneOf(['default', 'none']),
  __disabledFocusLock: PropTypes.bool,
  headline: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onReturnFocus: PropTypes.func,
  shouldCloseOnBlur: PropTypes.bool,
  withDividers: PropTypes.bool,
  shouldCloseOnInteractOutside: PropTypes.func,
  state: PropTypes.oneOf(Object.values(DialogState)),
};

interface IBaseModalDialogComponentProps extends IBaseModalDialogProps {
  readonly isFullScreen?: boolean;
  readonly minWidth?: string;
  readonly minHeight?: string;
}

const baseModalDialogComponentPropTypes: PropTypeMap<
  Omit<IBaseModalDialogComponentProps, keyof AriaDialogProps>
> = {
  ...baseModalDialogPropTypes,
  isFullScreen: PropTypes.bool,
  minWidth: PropTypes.string,
  minHeight: PropTypes.string,
};

const AnimatedDiv = styled.div<
  Required<Pick<IBaseModalDialogComponentProps, 'isFullScreen' | 'minWidth' | 'minHeight'>>
>`
  display: flex;
  // center horizontally and vertically in grid
  margin: auto;
  
  max-height: 100%;
  ${({ isFullScreen, minWidth, minHeight }) => css`
    ${
      isFullScreen &&
      css`
        width: 100%;
        height: 100%;
    `
    };
    min-width: ${minWidth};
    min-height: ${minHeight};
  `};
`;

export const BaseModalDialogComponent = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<IBaseModalDialogComponentProps>
>(
  (
    {
      autoFocusRef,
      bodyPadding = 'default',
      cancelAction,
      children,
      __disabledFocusLock,
      extraAction,
      headline,
      isDismissable,
      isFullScreen,
      isOpen,
      minHeight,
      minWidth,
      onClose,
      onReturnFocus,
      primaryAction,
      renderNotificationBar,
      shouldCloseOnBlur,
      shouldCloseOnInteractOutside,
      withDividers,
      state = DialogState.Default,
      ...otherProps
    },
    forwardedRef,
  ) => {
    const { refObject, refToForward } = useAttachRef<HTMLDivElement>(forwardedRef);
    const bodyRef = useRef<HTMLDivElement>(null);

    // Handle interacting outside the dialog and pressing
    // the Escape key to close the modal.
    const { overlayProps } = useOverlay(
      {
        isDismissable,
        isKeyboardDismissDisabled: !isDismissable,
        isOpen,
        onClose,
        shouldCloseOnBlur: isDismissable && !!shouldCloseOnBlur,
        shouldCloseOnInteractOutside,
      },
      refObject,
    );

    // Prevent scrolling while the modal is open, and hide content
    // outside the modal from screen readers.
    usePreventScroll();
    const { modalProps } = useModal();

    // Get props for the dialog and its title
    const { dialogProps, titleProps } = useDialog(otherProps, refObject);

    const primaryActionFallbackRef = React.useRef<HTMLButtonElement>(null);
    const cancelActionFallbackRef = React.useRef<HTMLButtonElement>(null);

    const primaryActionRef = primaryAction?.ref ?? primaryActionFallbackRef;
    const cancelActionRef = cancelAction?.ref ?? cancelActionFallbackRef;
    const returnFocusCallbackRef = useRef(onReturnFocus);

    const [shouldRender, setShouldRender] = useState(false);

    const isLoading = state === DialogState.InProgress;

    useEffect(() => {
      if (isOpen) {
        setShouldRender(true);
      } else if (ComponentLibraryGlobals.skipAnimation) {
        setShouldRender(false);
      }
    }, [isOpen]);

    const onAnimationEnd = () => {
      if (!isOpen) {
        setShouldRender(false);
        returnFocusCallbackRef.current?.();
      }
    };

    useEffect(() => {
      if (shouldRender) {
        const focusRef = autoFocusRef ?? (isDismissable ? cancelActionRef : primaryActionRef);
        focusRef.current?.focus();
      }
    }, [autoFocusRef, primaryActionRef, isDismissable, cancelActionRef, shouldRender]);

    useEffect(() => {
      returnFocusCallbackRef.current = onReturnFocus;
    }, [onReturnFocus]);

    const dialogTransitions = useTransition(
      shouldRender,
      isFullScreen ? fullScreenModalTransitionsDefinition : modalTransitionsDefinition,
    );

    if (!shouldRender) {
      return null;
    }

    return (
      <Overlay isOpen={isOpen} onAnimationEnd={onAnimationEnd}>
        <Box
          paddingX={spacingElementEdgeHorizontal}
          paddingY={spacingElementEdgeVertical}
          css={`
          width: 100%;
          height: 100%;
          position: relative;
        `}
        >
          {dialogTransitions(
            (style, item) =>
              item && (
                <animated.div
                  style={style}
                  css={`
              display: grid;
              height: 100%;
            `}
                >
                  <AnimatedDiv
                    isFullScreen={isFullScreen || false}
                    minWidth={minWidth || '0'}
                    minHeight={minHeight || '0'}
                    aria-busy={isLoading}
                    {...mergeProps(overlayProps, dialogProps, modalProps)}
                  >
                    <PortalContainerContextProvider portalContainerRef={refObject}>
                      <ScrollContainerContextProvider
                        // The dialog has some non-scrollable parts (header/footer)
                        // We reset the scroll context here to give the child components
                        // in those areas proper non-scrollable context
                        scrollContainerRef={null}
                        tippyBoundaryRef={null}
                      >
                        <FocusScope
                          contain={!__disabledFocusLock}
                          css={`
                          display: flex;
                          min-width: 100%;
                          min-height: 100%;
                        `}
                          restoreFocus={!returnFocusCallbackRef.current}
                        >
                          <Card
                            ref={refToForward}
                            component="article"
                            cardLabel={headline}
                            level={PaperLevel.Popout}
                            {...otherProps}
                            css="width: 100%"
                          >
                            {isDismissable && onClose && <Card.CloseButton onClose={onClose} />}
                            <Card.Headline
                              id={titleProps.id}
                              {...getDataUiElementAttribute(DataUiElement.ModalDialogHeadline)}
                            >
                              {headline}
                            </Card.Headline>
                            <Card.Body
                              overflowMode={withDividers ? 'scrollable' : 'auto'}
                              backgroundColor={BaseColor.White}
                              ref={bodyRef}
                              css={bodyPadding === 'none' ? 'padding-block: 0' : undefined}
                            >
                              {children}
                            </Card.Body>
                            <DialogFooter
                              dialogState={state}
                              primaryAction={
                                primaryAction && {
                                  ...primaryAction,
                                  ref: primaryActionRef,
                                }
                              }
                              cancelAction={
                                isDismissable
                                  ? {
                                      ...cancelAction,
                                      ref: cancelActionRef,
                                    }
                                  : undefined
                              }
                              {...{
                                isDismissable,
                                onClose,
                                extraAction,
                                renderNotificationBar,
                              }}
                            />
                          </Card>
                        </FocusScope>
                      </ScrollContainerContextProvider>
                    </PortalContainerContextProvider>
                  </AnimatedDiv>
                </animated.div>
              ),
          )}
        </Box>
      </Overlay>
    );
  },
);

BaseModalDialogComponent.displayName = 'BaseModalDialogComponent';
BaseModalDialogComponent.propTypes = baseModalDialogComponentPropTypes;
