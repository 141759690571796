import { useIdWithPrefix } from '@kontent-ai/hooks';
import Immutable from 'immutable';
import React, { useState } from 'react';
import { FullScreenModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/FullScreenModalDialog.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import {
  ItemColumnCode,
  translateColumnCodeToTitle,
} from '../../../../../_shared/constants/itemColumnCode.ts';
import { OrderBy, OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import { stringifyContentItemId } from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IAssignedListingContentItem } from '../../../../../data/models/listingContentItems/IAssignedListingContentItem.ts';
import { StyledDataTable } from '../../shared/components/StyledDataTable.tsx';
import { Widget } from '../../shared/components/Widget.tsx';
import { WidgetListingContentShowMoreButton } from '../../shared/components/WidgetListingContentShowMoreButton.tsx';
import { WidgetListingStateContent } from '../../shared/components/WidgetListingStateContent.tsx';
import {
  entityListDetailDisplayCount,
  entityListWidgetDisplayCount,
} from '../../shared/constants/widgetConstants.ts';
import { WidgetListingState } from '../../shared/types/WidgetListingState.type.ts';
import { AssignedContentItemsOrderByColumnCode } from '../constants/assignedContentItemsOrderByColumnCode.ts';
import { ItemsAssignedToYouWidgetDetailContainer as ItemsAssignedToYouWidgetDetail } from '../containers/ItemsAssignedToYouWidgetDetailContainer.tsx';
import { createNewOrderBy, getDirection } from '../utils/orderByColumn.ts';
import { ItemsAssignedToYouWidgetRow } from './ItemsAssignedToYouWidgetRow.tsx';
import { WidgetEmptyState } from './WidgetEmptyState.tsx';

type ItemsAssignedToYouWidgetProps = {
  readonly fetchMore: (count: number) => void;
  readonly items: ReadonlyArray<IAssignedListingContentItem>;
  readonly itemsState: WidgetListingState;
  readonly onChangeOrderBy: (
    orderBy: OrderBy<AssignedContentItemsOrderByColumnCode>,
    numberOfItemsToFetch: number,
  ) => void;
  readonly onDetailItemClick?: () => void;
  readonly onItemClick?: () => void;
  readonly onShowMoreClick?: () => void;
  readonly orderBy: OrderBy<AssignedContentItemsOrderByColumnCode>;
};

export const ItemsAssignedToYouWidget: React.FC<ItemsAssignedToYouWidgetProps> = ({
  fetchMore,
  items,
  itemsState,
  onChangeOrderBy,
  onDetailItemClick,
  onItemClick,
  onShowMoreClick,
  orderBy,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const openDetail = () => {
    onShowMoreClick?.();
    fetchMore(entityListDetailDisplayCount);
    setIsDialogOpen(true);
  };

  const closeDetail = () => setIsDialogOpen(false);

  const widgetTitleId = useIdWithPrefix('assigned-to-you');

  return (
    <>
      <Widget>
        <Widget.Title text="Items assigned to you" id={widgetTitleId} />
        <WidgetListingStateContent state={itemsState} renderEmptyState={() => <WidgetEmptyState />}>
          <Widget.Body>
            <StyledDataTable
              dataUiCollectionName={DataUiCollection.ContentItems}
              header={<DataTableHeadRow columns={getTableHeadColumns(orderBy, onChangeOrderBy)} />}
              ariaLabelledBy={widgetTitleId}
            >
              {items.slice(0, entityListWidgetDisplayCount).map((item) => (
                <ItemsAssignedToYouWidgetRow
                  key={stringifyContentItemId(item.id)}
                  onItemClick={onItemClick}
                  item={item}
                  rowFocusLinkAriaLabel={`visit content item — ${item.name}`}
                />
              ))}
            </StyledDataTable>
          </Widget.Body>

          <Widget.Footer>
            <Widget.CenterContent>
              <WidgetListingContentShowMoreButton
                onClick={openDetail}
                ariaDescribedBy={widgetTitleId}
              />
            </Widget.CenterContent>
          </Widget.Footer>
        </WidgetListingStateContent>
      </Widget>

      <FullScreenModalDialog
        headline="Items assigned to you"
        isDismissable
        isOpen={isDialogOpen}
        onClose={closeDetail}
      >
        <ItemsAssignedToYouWidgetDetail
          ariaLabelledBy={widgetTitleId}
          onChangeOrderBy={onChangeOrderBy}
          onItemClick={onDetailItemClick}
          dataState={itemsState}
          orderBy={orderBy}
          itemsAssignedToYou={items}
        />
      </FullScreenModalDialog>
    </>
  );
};

const getTableHeadColumns = (
  orderedBy: OrderBy<AssignedContentItemsOrderByColumnCode>,
  onChangeOrderBy: (
    orderBy: OrderBy<AssignedContentItemsOrderByColumnCode>,
    numberOfItemsToFetch: number,
  ) => void,
) => {
  return Immutable.List.of<Column>(
    {
      columnName: translateColumnCodeToTitle(ItemColumnCode.Name),
      orderBy: OrderByDirection.None,
    },
    {
      columnName: translateColumnCodeToTitle(ItemColumnCode.WorkflowStep),
      orderBy: OrderByDirection.None,
    },
    {
      columnName: translateColumnCodeToTitle(ItemColumnCode.DueDate),
      orderBy: getDirection(orderedBy, AssignedContentItemsOrderByColumnCode.Due),
      className: 'data-table__column--3',
      onClick: () =>
        onChangeOrderBy(
          createNewOrderBy(orderedBy, AssignedContentItemsOrderByColumnCode.Due),
          entityListWidgetDisplayCount,
        ),
    },
    {
      columnName: translateColumnCodeToTitle(ItemColumnCode.LastModifiedAt),
      orderBy: getDirection(orderedBy, AssignedContentItemsOrderByColumnCode.LastModified),
      className: 'data-table__column--4',
      onClick: () =>
        onChangeOrderBy(
          createNewOrderBy(orderedBy, AssignedContentItemsOrderByColumnCode.LastModified),
          entityListWidgetDisplayCount,
        ),
    },
  );
};
