import { DefaultLanguageId } from '../../../../../../_shared/constants/variantIdValues.ts';
import { isAiReviewEnabled } from '../../../../../../_shared/selectors/aiSelectors.ts';
import { getSelectedLanguageId } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { TypeElement } from '../../../../../../applications/contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import {
  isRichTextTypeElement,
  isTextTypeElement,
} from '../../../../../../applications/contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';

export const shouldShowAiReviewButton = (
  store: IStore,
  typeElement: TypeElement,
  contentComponentId: string | undefined,
): boolean => {
  const isReviewEnabled = isAiReviewEnabled(store);
  const isElementReviewable = isRichTextTypeElement(typeElement) || isTextTypeElement(typeElement);
  const isDefaultLanguage = getSelectedLanguageId(store) === DefaultLanguageId;

  return (
    isReviewEnabled &&
    isElementReviewable &&
    !contentComponentId &&
    (!typeElement.isNonLocalizable || isDefaultLanguage)
  );
};
