import { SrOnly } from '@kontent-ai/component-library/styles';
import { usePress } from '@react-aria/interactions';
import React, { useRef } from 'react';
import {
  DataUiAction,
  DataUiWorkflowAction,
  getDataAttributeProps,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

interface ILinkLikeProps {
  readonly className?: string;
  readonly onClick?: () => void;
  readonly dataUiAction?: DataUiAction | DataUiWorkflowAction;
  readonly screenReaderText?: string;
}

export const LinkLike: React.FC<React.PropsWithChildren<ILinkLikeProps>> = (props) => {
  const ref = useRef(null);
  const { pressProps } = usePress({
    ref,
    onPress: () => {
      if (props.onClick) {
        props.onClick();
      }
    },
  });
  return (
    <a tabIndex={0} className={props.className} {...getDataAttributeProps(props)} {...pressProps}>
      {props.screenReaderText && <SrOnly>{props.screenReaderText}</SrOnly>}
      {props.children}
    </a>
  );
};

LinkLike.displayName = 'LinkLike';
