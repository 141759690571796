import React, { useCallback, useMemo } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { DataUiRteAction } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { canUserViewAnyActiveLanguage } from '../../../../../_shared/utils/permissions/canUserViewAnyActiveLanguage.ts';
import { CannotViewItemsMessage } from '../../../../contentInventory/content/constants/cannotViewMessages.ts';
import { ModalContentItemSelector } from '../../../../features/ModalContentItemSelector/containers/ModalContentItemSelector.tsx';
import { ElementReference } from '../../../../itemEditor/features/ContentItemEditing/containers/hooks/useItemElementReference.ts';
import { useEditorWithPlugin } from '../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import {
  Apply,
  EditorPlugin,
  PluginProps,
  PluginState,
  Render,
} from '../../../editorCore/types/Editor.plugins.type.ts';
import { EditorChangeReason } from '../../../editorCore/types/EditorChangeReason.ts';
import { Decorator } from '../../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../../editorCore/utils/withDisplayName.ts';
import {
  EditorFeatureLimitations,
  ItemLinkLimitations,
} from '../../apiLimitations/api/EditorFeatureLimitations.ts';
import { getLinkStatus } from '../../apiLimitations/api/editorLimitationUtils.ts';
import { EntityDecoratorProps } from '../../entityApi/api/editorEntityUtils.ts';
import { GetLinkEntityComponent, GetLinkOptions, LinksPlugin } from '../LinksPlugin.type.ts';
import { isContentLink, isNewLinkOfType } from '../api/LinkEntity.ts';
import { LinkType } from '../api/LinkType.ts';
import { NewLinkType } from '../api/NewLinkType.ts';
import { isNewLinkAllowedAtSelection } from '../api/editorLinkUtils.ts';
import { ContentLink } from './containers/ContentLink.tsx';

type ContentLinksPluginProps = {
  readonly contentItemCollectionId?: Uuid;
  readonly editedEntityName: string;
  readonly element: ElementReference;
  readonly limitations: ItemLinkLimitations & EditorFeatureLimitations;
};

export type ContentLinksPlugin = EditorPlugin<None, ContentLinksPluginProps, None, [LinksPlugin]>;

type CustomLinkProps = Pick<
  PluginState<ContentLinksPlugin>,
  'editLink' | 'cancelNewLink' | 'linkEditingCancelled' | 'unlink' | 'getApi'
> &
  Pick<PluginProps<ContentLinksPlugin>, 'disabled' | 'element' | 'limitations'>;

const NewContentLinkEntity = ({
  cancelNewLink,
  children,
  contentState,
  entityKey,
}: React.PropsWithChildren<EntityDecoratorProps<CustomLinkProps>>) => {
  const entity = contentState.getEntity(entityKey);
  if (!isNewLinkOfType(entity, NewLinkType.ContentLink)) {
    return children;
  }

  const isPlaceholder = !!entity.getData().isPlaceholder;

  // New link should be always under a dialog, but just for case if anyone sees it, click reverts it back to no link
  return (
    <a
      href="client/app/applications/richText/components/editorCore/plugins/links/ContentLinksPlugin.tsx#"
      className="rte__link rte__link--is-edited"
      onClick={() => cancelNewLink(entityKey, isPlaceholder)}
    >
      {children}
    </a>
  );
};

const ContentLinkEntity = ({
  blockKey,
  children,
  contentState,
  decoratedText,
  disabled,
  editLink,
  element: { elementId },
  entityKey,
  getApi,
  limitations,
  unlink,
}: React.PropsWithChildren<EntityDecoratorProps<CustomLinkProps>>) => {
  const entity = contentState.getEntity(entityKey);
  if (!isContentLink(entity)) {
    return children;
  }

  const { itemId } = entity.getData();
  const status = getLinkStatus({ blockKey, contentState }, getApi().getLimitations());

  return (
    <ContentLink
      disabled={disabled}
      elementId={elementId}
      entityKey={entityKey}
      itemId={itemId}
      key={entityKey}
      limitations={limitations}
      onEdit={() => editLink(entityKey)}
      onUnlink={() => unlink(entityKey)}
      status={status}
      text={decoratedText}
    >
      {children}
    </ContentLink>
  );
};

export const useContentLinks: PluginCreator<ContentLinksPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('ContentLinksPlugin', {
        ComposedEditor: (props) => {
          const { contentItemCollectionId, disabled, editedEntityName, element, limitations } =
            props;

          const canViewContent = useSelector(canUserViewAnyActiveLanguage);

          const apply: Apply<ContentLinksPlugin> = useCallback(
            (state) => {
              const getLinkEntityComponent: Decorator<GetLinkEntityComponent<CustomLinkProps>> =
                (baseGetLinkEntityComponent) => (linkType) => {
                  const customLinkProps: CustomLinkProps = {
                    cancelNewLink: state.cancelNewLink,
                    disabled,
                    editLink: state.editLink,
                    element,
                    getApi: state.getApi,
                    limitations,
                    linkEditingCancelled: state.linkEditingCancelled,
                    unlink: state.unlink,
                  };

                  switch (linkType) {
                    case LinkType.Content:
                      return {
                        component: ContentLinkEntity,
                        props: customLinkProps,
                      };

                    case NewLinkType.ContentLink:
                      return {
                        component: NewContentLinkEntity,
                        props: customLinkProps,
                      };

                    default:
                      return baseGetLinkEntityComponent(linkType);
                  }
                };

              state.getLinkEntityComponent.decorate(getLinkEntityComponent);

              const updateContentLink = (entityKey: string, contentItemId: Uuid): void => {
                if (!state.canUpdateContent(EditorChangeReason.Internal)) {
                  return;
                }

                state.executeChange((editorState) => {
                  const selection = state.getApi().getSelectionForEntity(editorState, entityKey);
                  if (selection) {
                    const newEditorState = state
                      .getApi()
                      .createContentLink(editorState, selection, contentItemId);
                    state.linkEditingFinished();
                    return newEditorState;
                  }
                  return editorState;
                }, EditorChangeReason.Internal);
              };

              const renderModalToViewer: Decorator<Render<ContentLinksPlugin>> =
                (baseRenderModalToViewer) => (baseState) => {
                  const { editedLinkEntityKey, editorState, cancelNewLink, linkEditingCancelled } =
                    baseState;

                  if (editedLinkEntityKey) {
                    const content = editorState.getCurrentContent();
                    const entity = content.getEntity(editedLinkEntityKey);

                    const isExisting = isContentLink(entity);
                    const isNew = isNewLinkOfType(entity, NewLinkType.ContentLink);
                    const isPlaceholder = isNew && !!entity.getData().isPlaceholder;

                    if (isExisting || isNew) {
                      return (
                        <ModalContentItemSelector
                          allowedContentTypeIds={limitations.allowedItemLinkTypes}
                          collectionId={contentItemCollectionId}
                          onClose={
                            isExisting
                              ? () => linkEditingCancelled(editedLinkEntityKey)
                              : () => cancelNewLink(editedLinkEntityKey, isPlaceholder)
                          }
                          onSelect={(contentItemId) =>
                            updateContentLink(editedLinkEntityKey, contentItemId)
                          }
                          titleBarText={`Select a content item to link to ${editedEntityName}`}
                        />
                      );
                    }
                  }

                  return baseRenderModalToViewer(baseState);
                };

              state.renderModalToViewer.decorate(renderModalToViewer);

              const newContentLink = (): void => {
                if (!state.canUpdateContent()) {
                  return;
                }

                state.executeChange((editorState) => {
                  const selection = editorState.getSelection();
                  const content = editorState.getCurrentContent();
                  if (isNewLinkAllowedAtSelection(content, selection)) {
                    const newEditorState = selection.isCollapsed()
                      ? state
                          .getApi()
                          .createLinkPlaceholder(editorState, selection, NewLinkType.ContentLink)
                      : state.getApi().createNewContentLink(editorState, selection);
                    if (editorState !== newEditorState) {
                      const entityKey = newEditorState
                        .getCurrentContent()
                        .getLastCreatedEntityKey();
                      state.setEditedLinkEntityKey(entityKey);
                      return newEditorState;
                    }
                  }
                  return editorState;
                }, EditorChangeReason.Internal);
              };

              const getLinkOptions: Decorator<GetLinkOptions> = (baseGetLinkOptions) => () => [
                ...baseGetLinkOptions(),
                {
                  isDisabled: !canViewContent,
                  name: 'Content item',
                  onClick: newContentLink,
                  tooltipText: canViewContent ? undefined : CannotViewItemsMessage,
                  uiAction: DataUiRteAction.AddItemLink,
                },
              ];

              state.getLinkOptions.decorate(getLinkOptions);

              return {};
            },
            [
              canViewContent,
              contentItemCollectionId,
              disabled,
              editedEntityName,
              element,
              limitations,
            ],
          );

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
