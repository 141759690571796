import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  isAiChangeToneEnabled,
  isAiCreateTitleEnabled,
  isAiImproveContentEnabled,
  isAiMakeShorterEnabled,
  isAiMatchWritingStyleEnabled,
  isAiSummarizeEnabled,
} from '../../../../_shared/selectors/aiSelectors.ts';
import { isDefined } from '../../../../_shared/utils/filter/isDefined.ts';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { useLockEditor } from '../behavior/LockEditorPlugin.tsx';
import { useAiMenu } from './AiMenuPlugin.tsx';
import { useChangeTone } from './actions/ChangeTone/ChangeTonePlugin.tsx';
import { useMatchWritingStyleOfItem } from './actions/ChangeTone/MatchWritingStyleOfItem/MatchWritingStyleOfItemPlugin.tsx';
import { useCreateTitle } from './actions/CreateTitle/CreateTitlePlugin.tsx';
import { useImproveContent } from './actions/ImproveContent/ImproveContentPlugin.tsx';
import { useMakeShorter } from './actions/MakeShorter/MakeShorterPlugin.tsx';
import { useSummarize } from './actions/Summarize/SummarizePlugin.tsx';

const baseAiPlugins = [useAiMenu, useLockEditor];
const noPlugins: ReadonlyArray<PluginCreator> = [];

export const useAskAiPlugins = (): ReadonlyArray<PluginCreator> => {
  const actionAiPlugins = [
    useSelector((state) => (isAiMakeShorterEnabled(state) ? useMakeShorter : undefined)),
    useSelector((state) => (isAiImproveContentEnabled(state) ? useImproveContent : undefined)),
    useSelector((state) => (isAiChangeToneEnabled(state) ? useChangeTone : undefined)),
    useSelector((state) =>
      isAiMatchWritingStyleEnabled(state) ? useMatchWritingStyleOfItem : undefined,
    ),
    useSelector((state) => (isAiCreateTitleEnabled(state) ? useCreateTitle : undefined)),
    useSelector((state) => (isAiSummarizeEnabled(state) ? useSummarize : undefined)),
  ].filter(isDefined);

  return actionAiPlugins.length ? [...baseAiPlugins, ...actionAiPlugins] : noPlugins;
};
