import { RouterLinkButton } from '@kontent-ai/component-library/Button';
import Immutable from 'immutable';
import React from 'react';
import { DataTable } from '../../../../_shared/components/DataTable/DataTable.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { DataTableRow } from '../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import { LastUpdatedAt } from '../../../../_shared/components/LastUpdatedAt.tsx';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import {
  ItemColumnCode,
  translateColumnCodeToTitle,
} from '../../../../_shared/constants/itemColumnCode.ts';
import { YourContentTabName } from '../../../../_shared/constants/yourContentTabName.ts';
import { WorkflowStatusTagForVariant } from '../../../../_shared/containers/Workflow/WorkflowStatusTagForVariant.tsx';
import { ContentItemId } from '../../../../_shared/models/ContentItemId.type.ts';
import { OrderByDirection } from '../../../../_shared/models/OrderBy.ts';
import { stringifyContentItemId } from '../../../../_shared/models/utils/contentItemIdUtils.ts';
import { VariantCompletionStatus } from '../../../../_shared/utils/contentItemVariantUtils.ts';
import { DataUiCollection } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  getContentItemPath,
  getInventoryPath,
} from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { PublishingState } from '../../../../data/constants/PublishingState.ts';
import { IAssignmentWorkflowStep } from '../../../../data/models/workflow/WorkflowStep.ts';
import { TableCellDueDate } from '../../../contentInventory/content/features/ContentItemInventory/components/TableCellDueDate.tsx';
import { NameWithLanguage } from '../NameWithLanguage.tsx';
import { YourContentTabSaver } from '../YourContentTabSaver.tsx';

export interface IRecentlyEditedItemViewModel {
  readonly id: ContentItemId;
  readonly contentType: string;
  readonly due: DateTimeStamp | null;
  readonly language: string;
  readonly lastUpdatedAt: DateTimeStamp | null;
  readonly name: string;
  readonly publishingState: PublishingState;
  readonly scheduledToPublishAt: DateTimeStamp | null;
  readonly scheduledToUnpublishAt: DateTimeStamp | null;
  readonly variantCompletionStatus: VariantCompletionStatus | null;
  readonly workflowStatus: IAssignmentWorkflowStep;
}

export interface IContentRecentlyEditedByYouStateProps {
  readonly contentItems: Immutable.List<IRecentlyEditedItemViewModel>;
  readonly currentPath: string;
  readonly loadingFinished: boolean;
}

export interface IContentRecentlyEditedByYouDispatchProps {
  readonly onItemClick: (itemId: Uuid, index: number) => void;
}

interface IContentRecentlyEditedByYouProps
  extends IContentRecentlyEditedByYouStateProps,
    IContentRecentlyEditedByYouDispatchProps {}

const tableHeadColumns = Immutable.List.of<Column>(
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.Name),
    orderBy: OrderByDirection.None,
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.ContentType),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--4',
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.WorkflowStep),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--5',
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.DueDate),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--4',
  },
  {
    columnName: 'Your last edit',
    orderBy: OrderByDirection.Descending,
    className: 'data-table__column--4',
  },
);

export const ContentRecentlyEditedByYou: React.FC<IContentRecentlyEditedByYouProps> = ({
  contentItems,
  currentPath,
  loadingFinished,
  onItemClick,
}) => {
  const renderEmptyState = (): JSX.Element => {
    const inventoryPath = getInventoryPath(currentPath);

    return (
      <EmptyState>
        <EmptyState.Title>Resume your work effortlessly with Your Content.</EmptyState.Title>
        <EmptyState.Content>
          It looks like you haven’t edited anything recently, let’s make the first step.
        </EmptyState.Content>
        <EmptyState.Footer>
          <RouterLinkButton buttonStyle="primary" to={inventoryPath}>
            Browse all content
          </RouterLinkButton>
        </EmptyState.Footer>
      </EmptyState>
    );
  };

  const renderItem = (item: IRecentlyEditedItemViewModel, index: number): JSX.Element => {
    const {
      contentType,
      due,
      id,
      language,
      lastUpdatedAt,
      name,
      publishingState,
      scheduledToPublishAt,
      scheduledToUnpublishAt,
      variantCompletionStatus,
      workflowStatus,
    } = item;

    const linkProps = {
      linkPath: getContentItemPath(currentPath, id.itemId, id.variantId),
      onClick: () => onItemClick(id.itemId, index),
    };

    return (
      <DataTableRow
        className="your-content__item"
        id={stringifyContentItemId(id)}
        key={stringifyContentItemId(id)}
        dataUiObjectName={name}
      >
        <LinkDataTableCell {...linkProps} focusableRowLinkAriaLabel={`visit content — ${name}`}>
          <NameWithLanguage
            name={name}
            language={language}
            variantCompletionStatus={variantCompletionStatus}
          />
        </LinkDataTableCell>
        <LinkDataTableCell {...linkProps} title={contentType}>
          {contentType}
        </LinkDataTableCell>
        <LinkDataTableCell {...linkProps}>
          <WorkflowStatusTagForVariant
            publishingState={publishingState}
            workflowStatus={workflowStatus}
            scheduledToPublishAt={scheduledToPublishAt}
            scheduledToUnpublishAt={scheduledToUnpublishAt}
          />
        </LinkDataTableCell>
        <LinkDataTableCell {...linkProps}>
          <TableCellDueDate dueDate={due} />
        </LinkDataTableCell>
        <LinkDataTableCell {...linkProps}>
          <LastUpdatedAt time={lastUpdatedAt} />
        </LinkDataTableCell>
      </DataTableRow>
    );
  };

  if (!loadingFinished) {
    return (
      <section>
        <DataTable
          noBoxShadow
          dataUiCollectionName={DataUiCollection.ContentItems}
          header={<DataTableHeadRow columns={tableHeadColumns} />}
          infoMessage={
            <EmptyState>
              <Loader />
            </EmptyState>
          }
        />
      </section>
    );
  }

  return (
    <YourContentTabSaver
      tabName={YourContentTabName.RecentlyEditedByYou}
      itemCount={contentItems.size}
    >
      <section className="your-content__items-section">
        <DataTable
          noBoxShadow
          dataUiCollectionName={DataUiCollection.ContentItems}
          header={<DataTableHeadRow columns={tableHeadColumns} />}
          infoMessage={contentItems.isEmpty() ? renderEmptyState() : null}
        >
          {!contentItems.isEmpty() && contentItems.toArray().map(renderItem)}
        </DataTable>
      </section>
    </YourContentTabSaver>
  );
};

ContentRecentlyEditedByYou.displayName = 'ContentRecentlyEditedByYou';
