import { isWholeNodeVisibleVertically, scrollToView } from '@kontent-ai/DOM';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import { Spacing, gridUnit, px } from '@kontent-ai/component-library/tokens';
import { useAttachRef } from '@kontent-ai/hooks';
import classNames from 'classnames';
import React, {
  PropsWithChildren,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { DefaultVariantId } from '../../../../../../_shared/constants/variantIdValues.ts';
import { CustomOnScrollCallback } from '../../../../../../_shared/hooks/useAutoScroll.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { IUserInfo } from '../../../../../../_shared/models/UserInfo.ts';
import { ElementStatus } from '../../../../../../_shared/uiComponents/ElementStatus/ElementStatus.tsx';
import {
  isArchivedWorkflowStepSelected,
  isPublishingStepSelected,
} from '../../../../../../_shared/utils/contentItemUtils.ts';
import {
  DataUiElement,
  getDataUiContentElementAttribute,
  getDataUiElementAttribute,
  getDataUiElementInComponentAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getSelectedText } from '../../../../../../_shared/utils/selectionUtils.ts';
import { formatCurrentUserName } from '../../../../../../_shared/utils/usersUtils.ts';
import { IProjectContributor } from '../../../../../../data/models/users/ProjectContributor.ts';
import { AiReviewButtonPlaceholder } from '../../../../../../paperModels/aiReview/richText/plugins/ReviewByGuidelines/components/AiReviewButtonPlaceholder.tsx';
import { ElementType } from '../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { TypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { isGuidelinesTypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { AiTranslateButtonPlaceholder } from '../../../../../richText/plugins/ai/actions/TranslateContent/components/AiTranslateButtonPlaceholder.tsx';
import { AiElementStatusPlaceholder } from '../../../../../richText/plugins/ai/components/status/AiElementStatusPlaceholder.tsx';
import { CommentsContext } from '../../../../components/CommentsContext.tsx';
import {
  getContentComponentIdAttribute,
  getElementIdAttribute,
  getElementSupportsCommentsAttribute,
} from '../../../../constants/elementAttributes.ts';
import { getElementStatus } from '../../../../utils/itemElementUtils.ts';
import { LanguageContext } from '../../../ContentComponent/context/LanguageContext.tsx';
import { RevisionsContext } from '../../../Revisions/context/RevisionsContext.tsx';
import { SpaceTakenByActions } from '../../constants/uiConstants.ts';
import { FriendlyWarning } from '../../containers/FriendlyWarning.tsx';
import { CommentButton } from '../../containers/elements/subComponents/CommentButton.tsx';
import { ContentItemElementHeaderInfo } from './ContentItemElementHeaderInfo.tsx';
import { ErrorMessage } from './subComponents/Error.tsx';
import { Guidelines } from './subComponents/Guidelines.tsx';
import { ItemElementHeaderSection } from './subComponents/ItemElementHeaderSection.tsx';
import { ItemElementHeaderSubsection } from './subComponents/ItemElementHeaderSubsection.tsx';
import { ItemElementLabel } from './subComponents/ItemElementLabel.tsx';
import { ItemElementTag } from './subComponents/ItemElementTag.tsx';

export interface IItemElementOwnProps {
  readonly className?: string;
  readonly contentComponentId?: Uuid;
  readonly customDataUiElement?: DataUiElement;
  readonly defaultTabIndex?: boolean;
  readonly disabled: boolean;
  readonly escapeHandledByEditor?: boolean;
  readonly focusEditor?: () => void;
  readonly headerClassName?: string;
  readonly isCompact?: boolean;
  readonly isDisplayOnly?: boolean;
  readonly onContentClick?: () => void;
  readonly onCustomScrollToTarget?: CustomOnScrollCallback;
  readonly onHeaderClick?: () => void;
  readonly title?: string;
  readonly titleId?: string;
  readonly typeElement: TypeElement;
}

interface IItemElementStateProps {
  readonly areContentItemElementsHighlighting: boolean;
  readonly canUpdateContent: boolean;
  readonly commentThreadId: Uuid | null;
  readonly currentUserId?: UserId;
  readonly elementSupportsComments: boolean;
  readonly errorMessages?: ReadonlyArray<string>;
  readonly friendlyWarningMessages?: ReadonlyArray<string>;
  readonly onNewComment?: () => void;
  readonly hasFocusedComment: boolean;
  readonly hideErrorMessages?: boolean;
  readonly hideNonLocalizableElementInfo?: boolean;
  readonly isFocused: boolean;
  readonly isHighlighted: boolean;
  readonly isLocked: boolean;
  readonly isOutdated?: boolean;
  readonly isRefreshPending?: boolean;
  readonly lockedByUser: IProjectContributor | null;
  readonly revisionChangedBy?: IUserInfo;
  readonly showAiReviewButton?: boolean;
  readonly showAiTranslateButton?: boolean;
  readonly showElementStatus: boolean;
  readonly showIncompleteItemWarningsBeforePublish?: boolean;
  readonly showLimitationWarning?: boolean;
  readonly validationPassed: boolean;
}

interface IItemElementDispatchProps {
  readonly focusItemElement: () => void;
}

type ItemElementProps = IItemElementOwnProps & IItemElementStateProps & IItemElementDispatchProps;

export const itemElementCompactWidth = gridUnit * 45;

const ItemElementCompactWrapper = styled.div`
  display: inline-block;
  max-width: 100%;
  width: ${px(itemElementCompactWidth)};
`;

export const ItemElement = forwardRef<HTMLDivElement, PropsWithChildren<ItemElementProps>>(
  (props, forwardedRef) => {
    const {
      areContentItemElementsHighlighting,
      canUpdateContent,
      children,
      className,
      commentThreadId,
      contentComponentId,
      currentUserId,
      customDataUiElement,
      defaultTabIndex,
      disabled,
      elementSupportsComments,
      errorMessages,
      escapeHandledByEditor,
      focusItemElement,
      friendlyWarningMessages,
      onNewComment,
      hasFocusedComment,
      headerClassName,
      hideErrorMessages,
      hideNonLocalizableElementInfo,
      isCompact,
      isDisplayOnly,
      isFocused,
      isHighlighted,
      isLocked,
      isOutdated,
      isRefreshPending,
      lockedByUser,
      revisionChangedBy,
      showAiReviewButton,
      showAiTranslateButton,
      showElementStatus,
      showIncompleteItemWarningsBeforePublish,
      showLimitationWarning,
      title,
      titleId,
      typeElement,
      validationPassed,
    } = props;

    const [isCaretFocused, setIsCaretFocused] = useState(false);

    const { refObject: itemElementWrapperRef, refToForward } = useAttachRef(forwardedRef);

    const onCaretFocusChange = useCallback(
      (newIsFocused: boolean): void => setIsCaretFocused(newIsFocused),
      [],
    );
    const { languageId } = useContext(LanguageContext);
    const isInRevisions = useContext(RevisionsContext);
    const onCaretFocusChangeDebounced = useDebouncedCallback(onCaretFocusChange, 50);
    const isVariantReadonly = useSelector((state) => {
      const assignment = state.contentApp.editedContentItemVariant?.assignment;
      return isPublishingStepSelected(assignment) || isArchivedWorkflowStepSelected(assignment);
    });
    useEffect(() => onCaretFocusChangeDebounced.cancel, [onCaretFocusChangeDebounced]);

    useEffect(() => {
      if (isFocused) {
        const element = itemElementWrapperRef.current;
        if (element && !isWholeNodeVisibleVertically(element)) {
          scrollToView(element, 'start', 'auto', SpaceTakenByActions);
        }

        focusItemElement();
      }
    }, [isFocused, focusItemElement, itemElementWrapperRef]);

    useEffect(() => {
      if (!isOutdated) {
        onCaretFocusChange(false);
      }
    }, [isOutdated, onCaretFocusChange]);

    const { allowNewComments } = useContext(CommentsContext);

    const focusEditor = (event: KeyboardEvent): void => {
      event.preventDefault();
      const isWrapperFocused = event.target === itemElementWrapperRef.current;
      if (isWrapperFocused) {
        props.focusEditor?.();
      }
    };

    const focusWrapper = (): void => {
      itemElementWrapperRef.current?.focus();
    };

    const onFocus = (): void => {
      onCaretFocusChangeDebounced(true);
    };

    const onBlur = (): void => {
      onCaretFocusChangeDebounced(false);
    };

    const handleElementHeaderClick = (event: React.MouseEvent<HTMLElement>): void => {
      const { onHeaderClick } = props;
      if (onHeaderClick) {
        event.stopPropagation(); // important for nested item elements and focus!

        // allow text selection and copying
        if (!getSelectedText().length) {
          onHeaderClick();
        }
      }
    };

    const handleElementContentClick = (event: React.MouseEvent<HTMLElement>): void => {
      const { onContentClick } = props;
      // don't handle children's click events, only the currentTarget's
      if (onContentClick && event.target === event.currentTarget) {
        event.stopPropagation(); // important for nested item elements and focus!

        // allow text selection and copying
        if (!getSelectedText().length) {
          onContentClick();
        }
      }
    };

    const canEdit = !disabled && canUpdateContent;
    const hasComment = elementSupportsComments && !!commentThreadId;
    const isCommentButtonVisible =
      elementSupportsComments && !isDisplayOnly && (allowNewComments || hasComment);

    const isAiTranslateButtonVisible = showAiTranslateButton && canEdit;

    const isGuidelines = isGuidelinesTypeElement(typeElement);
    const isCustomElement = typeElement.type === ElementType.Custom;

    const isDefaultVariant = languageId === DefaultVariantId;
    const isHighlightedNonLocalizableElement =
      typeElement.isNonLocalizable &&
      canUpdateContent &&
      !isDefaultVariant &&
      !isInRevisions &&
      !isVariantReadonly &&
      !isLocked &&
      !isOutdated;

    const elementClassName = classNames(className, 'content-item-element', {
      'content-item-element--is-caret-focused': isCaretFocused,
      'content-item-element--is-highlighted': isHighlighted && !hideErrorMessages,
      'content-item-element--is-highlighted-nle': isHighlightedNonLocalizableElement,
      'content-item-element--is-disabled': !canEdit || isGuidelines,
      'content-item-element--is-highlighting':
        isHighlighted && areContentItemElementsHighlighting && !disabled,
      'content-item-element--has-focus-comment': hasFocusedComment && !isDisplayOnly,
      'content-item-element--has-comment': hasComment && !hasFocusedComment && !isDisplayOnly,
    });

    const dataUiContentComponentIdAttribute = contentComponentId
      ? getContentComponentIdAttribute(contentComponentId)
      : {};
    const dataUiElementIsNotInComponent = 'empty';
    const dataUiElementInComponentAttribute = contentComponentId
      ? getDataUiElementInComponentAttribute(contentComponentId)
      : getDataUiElementInComponentAttribute(dataUiElementIsNotInComponent);

    const renderUserName = currentUserId && formatCurrentUserName(currentUserId, false);

    const hotkeysProps = useHotkeys({
      [ShortcutsConfig.Escape]: escapeHandledByEditor ? undefined : focusWrapper,
      [ShortcutsConfig.Enter]: focusEditor,
    });

    return (
      <div
        {...hotkeysProps}
        className={classNames('content-item-element__wrapper', {
          'content-item-element__wrapper--is-disabled': !canEdit,
          'content-item-element__wrapper--is-highlighted-nle': isHighlightedNonLocalizableElement,
        })}
        {...(customDataUiElement
          ? getDataUiElementAttribute(customDataUiElement)
          : getDataUiContentElementAttribute(typeElement.type))}
        {...getDataUiObjectNameAttribute(typeElement.name)}
        {...dataUiElementInComponentAttribute}
        {...getElementIdAttribute(typeElement.elementId)}
      >
        <div
          className={elementClassName}
          tabIndex={defaultTabIndex && !disabled ? 0 : undefined}
          data-validation-passed={validationPassed}
          ref={refToForward}
          onFocus={onFocus}
          onBlur={onBlur}
          {...getElementIdAttribute(typeElement.elementId)}
          {...getElementSupportsCommentsAttribute(elementSupportsComments)}
          // Decorate each element with content component ID,
          // so that nested components of the same type are then correctly queried
          {...dataUiContentComponentIdAttribute}
        >
          {!isGuidelines && (
            <div
              className={classNames(headerClassName, 'content-item-element__header')}
              {...getDataUiElementAttribute(DataUiElement.ItemElementMetadata)}
              onClick={handleElementHeaderClick}
            >
              <ItemElementHeaderSection>
                <ItemElementHeaderSubsection isFirst>
                  <Row alignX="start" alignY="center" spacingX={Spacing.M} noWrap>
                    <Column>
                      <Row alignX="start" alignY="center" spacingX={Spacing.S} noWrap>
                        <Column>
                          <ItemElementLabel id={titleId}>
                            {title || typeElement.name}
                          </ItemElementLabel>
                        </Column>
                        {isCustomElement && (
                          <Column>
                            <ItemElementTag text="Custom element" />
                          </Column>
                        )}
                      </Row>
                    </Column>
                    <Column>
                      {(showElementStatus || showLimitationWarning) && (
                        <ElementStatus
                          option={getElementStatus(showLimitationWarning, validationPassed)}
                          showIncompleteItemWarningsBeforePublish={
                            showIncompleteItemWarningsBeforePublish
                          }
                        />
                      )}
                    </Column>
                  </Row>
                  {revisionChangedBy && renderUserName && (
                    <ItemElementLabel alignedRight>
                      Changed by: {renderUserName(revisionChangedBy)}
                    </ItemElementLabel>
                  )}
                </ItemElementHeaderSubsection>
                <ContentItemElementHeaderInfo
                  hideNonLocalizableElementInfo={hideNonLocalizableElementInfo}
                  hideSimultaneousEditingStatus={isInRevisions}
                  isOutdated={isOutdated}
                  isRefreshPending={isRefreshPending}
                  lockedByUser={lockedByUser}
                  typeElement={typeElement}
                >
                  {(isAiTranslateButtonVisible || showAiReviewButton) && (
                    <AiElementStatusPlaceholder
                      elementId={typeElement.elementId}
                      contentComponentId={contentComponentId}
                    />
                  )}
                  {showAiReviewButton && (
                    <AiReviewButtonPlaceholder
                      elementId={typeElement.elementId}
                      contentComponentId={contentComponentId}
                    />
                  )}
                  {isAiTranslateButtonVisible && (
                    <AiTranslateButtonPlaceholder
                      elementId={typeElement.elementId}
                      contentComponentId={contentComponentId}
                    />
                  )}
                  {isCommentButtonVisible && onNewComment && (
                    <CommentButton
                      commentThreadId={commentThreadId}
                      hasFocusedComment={hasFocusedComment}
                      onNewComment={onNewComment}
                    />
                  )}
                </ContentItemElementHeaderInfo>
              </ItemElementHeaderSection>
              <Guidelines text={typeElement.guidelines} />
            </div>
          )}
          <div
            className={classNames('content-item-element__content', {
              'content-item-element__content--has-guidelines': isGuidelines,
            })}
            onClick={handleElementContentClick}
          >
            {isCompact ? (
              <ItemElementCompactWrapper>{children}</ItemElementCompactWrapper>
            ) : (
              children
            )}
          </div>
          <ErrorMessage
            errors={errorMessages}
            hideErrors={hideErrorMessages}
            messageBarClassName={classNames(
              'content-item-element__alert-message',
              'content-item-element__alert-message--is-error',
            )}
            messageClassName="content-item-element__alert-inner-message"
          />
          <FriendlyWarning friendlyWarnings={friendlyWarningMessages} hideWarning={disabled} />
        </div>
      </div>
    );
  },
);

ItemElement.displayName = 'ItemElement';
