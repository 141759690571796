import { Box } from '@kontent-ai/component-library/Box';
import { Checkbox } from '@kontent-ai/component-library/Checkbox';
import { Hint } from '@kontent-ai/component-library/Hint';
import { Spacing } from '@kontent-ai/component-library/tokens';

import classNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { OrderByDirection } from '../../models/OrderBy.ts';
import { Icon } from '../../uiComponents/Icon/Icon.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
  getDataUiColumnNameAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { hasProperties } from '../../utils/validation/hasProperties.ts';

export type Column = {
  readonly columnName: string;
  readonly orderBy: OrderByDirection;
  readonly className?: string;
  readonly onClick?: () => void;
  readonly infoTooltipText?: string;
};

type DataTableHeadRowProps = {
  readonly columns: Immutable.List<Column>;
  readonly showCheckboxes?: boolean;
  readonly preventMultipleSelection?: boolean;
  readonly allEntriesSelected?: boolean;
  readonly isAllEntriesSelectorDisabled?: boolean;
  readonly onCheckboxChange?: (isChecked: boolean) => void;
};

const propTypes: PropTypeMap<DataTableHeadRowProps> = {
  columns: (
    props: DataTableHeadRowProps,
    propName: keyof DataTableHeadRowProps,
    componentName: string,
  ) => {
    if (Immutable.List.isList(props[propName])) {
      return null;
    }

    const columnNameProperty = {
      name: 'columnName',
      type: 'string',
    };

    if (
      propName === 'columns' &&
      props.columns.every((column) => hasProperties([columnNameProperty], column))
    ) {
      return new Error(
        `Property ${propName} in ${componentName} is supposed to be a list of objects with a string attributes called 'columnName' and 'orderBy'.`,
      );
    }

    return null;
  },
  allEntriesSelected: PropTypes.bool,
  showCheckboxes: PropTypes.bool,
  preventMultipleSelection: PropTypes.bool,
  onCheckboxChange: PropTypes.func,
  isAllEntriesSelectorDisabled: PropTypes.bool,
};

export const DataTableHeadRow: React.FC<DataTableHeadRowProps> = ({
  columns,
  showCheckboxes,
  preventMultipleSelection,
  allEntriesSelected,
  isAllEntriesSelectorDisabled,
  onCheckboxChange,
}) => {
  return (
    <thead>
      <tr>
        {showCheckboxes ? (
          <th className="data-table__column-head data-table__column-head--checkbox">
            {!preventMultipleSelection && (
              <Checkbox
                ariaLabel="Select all"
                id="select-all"
                checkboxState={isAllEntriesSelectorDisabled ? 'disabled' : 'default'}
                checked={allEntriesSelected}
                name="Select"
                onToggle={(isSelected) => onCheckboxChange?.(isSelected)}
                {...getDataUiActionAttribute(DataUiAction.Select)}
              />
            )}
          </th>
        ) : null}
        {columns
          .map((column: Column, index: number) => {
            const className = classNames('data-table__column-head', {
              'data-table__column-head--is-clickable': !!column.onClick,
              'data-table__column-head--is-selected': column.orderBy !== OrderByDirection.None,
            });
            return (
              <th
                className={classNames(className, column.className)}
                key={index}
                onClick={column.onClick}
                {...getDataUiColumnNameAttribute(column.columnName)}
              >
                <div className="data-table__column-name">
                  <span>{column.columnName}</span>
                  {(column.onClick || column.orderBy !== OrderByDirection.None) && (
                    <div
                      className={classNames('data-table__ordering-mark', {
                        'data-table__ordering-mark--hidden':
                          column.orderBy === OrderByDirection.None,
                      })}
                    >
                      <Icon
                        className="data-table__ordering-mark-icon"
                        iconName={
                          column.orderBy === OrderByDirection.Descending
                            ? IconName.CaretDown
                            : IconName.CaretUp
                        }
                      />
                    </div>
                  )}
                  {column.infoTooltipText && (
                    <Box marginLeft={Spacing.XS}>
                      <Hint tooltipText={column.infoTooltipText} tooltipPlacement="right" />
                    </Box>
                  )}
                </div>
              </th>
            );
          })
          .toArray()}
      </tr>
    </thead>
  );
};

DataTableHeadRow.displayName = 'DataTableHeadRow';
DataTableHeadRow.propTypes = propTypes;
