import React from 'react';
import { useHistory } from 'react-router';
import { DefaultVariantId } from '../../../../../../../_shared/constants/variantIdValues.ts';
import { useVariantTranslation } from '../../../../../../../_shared/features/AI/hooks/useVariantTranslation.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemId } from '../../../../../../../_shared/models/ContentItemId.type.ts';
import { ActiveCapabilityType } from '../../../../../../../_shared/models/activeCapability.type.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { getCurrentUserId } from '../../../../../../../_shared/selectors/getCurrentUser.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import {
  isArchivedWorkflowStepSelected,
  isPublishingStepSelected,
} from '../../../../../../../_shared/utils/contentItemUtils.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { getAllLanguagesWithDefaultSuffix } from '../../../../../../../data/reducers/languages/selectors/getLanguages.ts';
import {
  getCurrentProject,
  getCurrentProjectId,
} from '../../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import {
  variantQuickActionsNotificationClose,
  variantTranslationStarted,
} from '../../../actions/contentItemEditingActions.ts';
import { copyContentValuesFromDifferentExistingVariant } from '../../../actions/thunkContentItemEditingActions.ts';
import { CopyFromLanguageQuickMenu } from '../../../components/contentItemEditorNotifications/variantQuickActionsNotification/CopyFromLanguageQuickMenu.tsx';
import { TranslateFromLanguageQuickMenu } from '../../../components/contentItemEditorNotifications/variantQuickActionsNotification/TranslateFromLanguageQuickMenu.tsx';
import { VariantQuickActionsNotificationBar as VariantQuickActionsNotificationBarComponent } from '../../../components/contentItemEditorNotifications/variantQuickActionsNotification/VariantQuickActionsNotificationBar.tsx';
import { isVariantTranslationEnabled } from '../../../utils/editingActions/availabilityUtils/isVariantTranslationEnabled.ts';
import { getLanguageOptionsMemoized } from '../../selectors/copyContentFromVariantMenuOptions.ts';

type VariantQuickActionsNotificationBarProps = {
  readonly contentItemId: ContentItemId;
  readonly notificationBarMessage: React.ReactNode;
  readonly notificationBarTools: ReadonlyArray<React.ReactNode>;
  readonly onClose: () => void;
  readonly projectEnvironmentId: Uuid;
};

const QuickActionsNotificationBar: React.FC<VariantQuickActionsNotificationBarProps> = ({
  contentItemId,
  notificationBarMessage,
  notificationBarTools,
  onClose,
  projectEnvironmentId,
}) => {
  const { translationStatus } = useVariantTranslation(
    projectEnvironmentId,
    contentItemId.itemId,
    contentItemId.variantId,
  );

  if (translationStatus) {
    return null;
  }

  return (
    <VariantQuickActionsNotificationBarComponent
      notificationBarTools={notificationBarTools}
      notificationBarMessage={notificationBarMessage}
      onClose={onClose}
    />
  );
};

export const VariantQuickActionsNotificationBar: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userId = useSelector(getCurrentUserId);
  const contentItemId = useSelector((s) => s.contentApp.editedContentItemVariant?.id);
  const projectEnvironmentId = useSelector(getCurrentProjectId);
  const collectionId = useSelector((s) => s.contentApp.editedContentItem?.collectionId ?? null);
  const selectedLanguageId = useSelector(getSelectedLanguageIdOrThrow);
  const allLanguages = useSelector((s) => getAllLanguagesWithDefaultSuffix(s.data.languages));
  const selectedVariantLanguage =
    allLanguages.get(selectedLanguageId) || allLanguages.get(DefaultVariantId);
  const isInPublishingStep = useSelector(
    (s) =>
      s.contentApp.editedContentItemVariant &&
      isPublishingStepSelected(s.contentApp.editedContentItemVariant.assignment),
  );
  const isInArchivingStep = useSelector(
    (s) =>
      s.contentApp.editedContentItemVariant &&
      isArchivedWorkflowStepSelected(s.contentApp.editedContentItemVariant.assignment),
  );
  const visible = useSelector(
    (s) =>
      s.contentApp.editedContentItem &&
      s.contentApp.isVariantEditedForTheFirstTime &&
      !isInPublishingStep &&
      !isInArchivingStep &&
      hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.UpdateContent, s),
  );
  const isItemVariantTranslationsEnabled = useSelector(
    (s) =>
      s.contentApp.editedContentItemVariant &&
      isVariantTranslationEnabled(s, s.contentApp.editedContentItemVariant),
  );
  const menuOptions = useSelector((s) =>
    visible
      ? getLanguageOptionsMemoized(
          s.data.languages,
          selectedLanguageId,
          s.contentApp.contentItemVariants,
          s.data.user,
          getCurrentProject(s).projectId,
          collectionId,
        )
      : null,
  );
  if (!contentItemId) {
    return null;
  }

  if (!visible || !menuOptions?.length) {
    return null;
  }

  const copyFromLanguageMenu = (
    <CopyFromLanguageQuickMenu
      key="copy-from-language"
      menuOptions={menuOptions}
      handleVariantSelect={(variantId: Uuid) =>
        dispatch(
          copyContentValuesFromDifferentExistingVariant(
            history,
            variantId,
            ContentItemEditingEventOrigins.QuickActions,
          ),
        )
      }
    />
  );

  const aiTranslateVariantMenu = (
    <TranslateFromLanguageQuickMenu
      key="translate-with-ai"
      projectEnvironmentId={projectEnvironmentId}
      contentItemId={contentItemId}
      menuOptions={menuOptions}
      userId={userId}
      onTranslationStarted={() =>
        dispatch(
          variantTranslationStarted(
            projectEnvironmentId,
            contentItemId.itemId,
            contentItemId.variantId,
          ),
        )
      }
    />
  );

  const notificationBarTools = isItemVariantTranslationsEnabled
    ? [aiTranslateVariantMenu, copyFromLanguageMenu]
    : [copyFromLanguageMenu];

  return (
    <QuickActionsNotificationBar
      projectEnvironmentId={projectEnvironmentId}
      contentItemId={contentItemId}
      notificationBarTools={notificationBarTools}
      notificationBarMessage={
        <>
          Language variant created in&nbsp;<b>{selectedVariantLanguage?.name || ''}</b>.
          {!isItemVariantTranslationsEnabled &&
            ' Looking to copy content quickly from another language?'}
        </>
      }
      onClose={() => dispatch(variantQuickActionsNotificationClose())}
    />
  );
};
