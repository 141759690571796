import PropTypes from 'prop-types';
import React from 'react';
import { useOurFocusRing } from '../../../hooks/useOurFocusRing.ts';
import { Column } from '../../../layout/Row/Column.tsx';
import { Row } from '../../../layout/Row/Row.tsx';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { BaseColor } from '../../../tokens/quarks/colors.ts';
import { IconSize } from '../../../tokens/quarks/iconSize.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { getDataUiComponentAttribute } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { CLPropTypes } from '../../../validators/propTypes.ts';
import { iconPropType } from '../../Icons/iconPropType.ts';
import { Icon } from '../../Icons/types.ts';
import { OptionalTooltip } from '../../Tooltip/OptionalTooltip.tsx';
import { TooltipPropsExtension, tooltipExtensionPropTypes } from '../../_utils/propPrefabs.ts';
import { SimpleStatusAlign } from '../types/simpleStatusAlign.ts';
import { StyledIcon } from './StyledIcon.tsx';
import { StyledLabel } from './StyledLabel.tsx';
import { StyledSimpleStatus } from './StyledSimpleStatus.tsx';

const simpleStatusStyleValues = ['default', 'inverse'] as const;

type SimpleStatusStyle = (typeof simpleStatusStyleValues)[number];

export interface ISimpleStatusProps
  extends Pick<TooltipPropsExtension, 'tooltipText' | 'tooltipPlacement'> {
  readonly disabledFocus?: boolean;
  readonly flavor: 'Default' | 'Error' | 'Success' | 'Warning';
  readonly icon?: Icon;
  readonly iconAlign?: SimpleStatusAlign;
  readonly iconColor: BaseColor;
  readonly iconColorInverse: BaseColor;
  readonly label?: string;
  readonly labelColor: BaseColor;
  readonly labelColorInverse: BaseColor;
  readonly simpleStatusStyle?: SimpleStatusStyle;
}

export const baseSimpleStatusPropTypes = {
  disabledFocus: PropTypes.bool,
  icon: iconPropType,
  iconAlign: PropTypes.oneOf(Object.values(SimpleStatusAlign)),
  label: PropTypes.string,
  simpleStatusStyle: PropTypes.oneOf(simpleStatusStyleValues),
  tooltipPlacement: tooltipExtensionPropTypes.tooltipPlacement,
  tooltipText: tooltipExtensionPropTypes.tooltipText,
};

const propTypes: PropTypeMap<ISimpleStatusProps> = {
  flavor: PropTypes.oneOf(['Default', 'Error', 'Success', 'Warning'] as const).isRequired,
  iconColor: CLPropTypes.colors.baseColor.isRequired,
  iconColorInverse: CLPropTypes.colors.baseColor.isRequired,
  labelColor: CLPropTypes.colors.baseColor.isRequired,
  labelColorInverse: CLPropTypes.colors.baseColor.isRequired,
  ...baseSimpleStatusPropTypes,
};

const SimpleStatus = React.forwardRef<HTMLDivElement, ISimpleStatusProps>(
  (
    {
      disabledFocus,
      flavor,
      icon,
      iconAlign = SimpleStatusAlign.Left,
      iconColor,
      iconColorInverse,
      label,
      labelColor,
      labelColorInverse,
      simpleStatusStyle = 'default',
      tooltipPlacement = 'top',
      tooltipText,
      ...restProps
    },
    forwardedRef,
  ) => {
    const { isFocusVisible, focusProps } = useOurFocusRing();
    const isInverseStyle = simpleStatusStyle === 'inverse';

    const renderedIcon = icon && (
      <Column width="content">
        <StyledIcon
          as={icon}
          color={isInverseStyle ? iconColorInverse : iconColor}
          size={IconSize.S}
        />
      </Column>
    );

    return (
      <OptionalTooltip
        text={label ?? ''}
        tooltipText={tooltipText}
        placement={tooltipPlacement}
        customRenderText={(ref) => (
          <StyledSimpleStatus
            tabIndex={disabledFocus ? undefined : 0}
            ref={forwardedRef}
            isFocusVisible={isFocusVisible}
            {...restProps}
            {...focusProps}
            {...getDataUiComponentAttribute(SimpleStatus)}
          >
            <Row alignY="center" spacing={Spacing.S} noWrap>
              {iconAlign === SimpleStatusAlign.Left && renderedIcon}
              {label ? (
                <Column>
                  <StyledLabel $color={isInverseStyle ? labelColorInverse : labelColor} ref={ref}>
                    {label}
                  </StyledLabel>
                </Column>
              ) : (
                <SrOnly>{tooltipText}</SrOnly>
              )}
              {iconAlign === SimpleStatusAlign.Right && renderedIcon}
            </Row>
          </StyledSimpleStatus>
        )}
      />
    );
  },
);

SimpleStatus.displayName = 'SimpleStatus';
SimpleStatus.propTypes = propTypes;

export { SimpleStatus as BaseSimpleStatusComponent };
