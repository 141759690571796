import { InputLabel } from '@kontent-ai/component-library/Input';
import { LabelSize } from '@kontent-ai/component-library/Label';
import { Stack } from '@kontent-ai/component-library/Stack';
import { colorTextDefault, spacingElementLabel } from '@kontent-ai/component-library/tokens';
import React, { ComponentProps, ReactElement, useId } from 'react';
import { FieldPathByValue, FieldValues, useController } from 'react-hook-form';
import { DateTime } from '../../models/DateTime.ts';
import { HookFormProps } from '../../types/hookFormProps.ts';
import { showFieldError } from '../../utils/validation/showFieldError.ts';
import { DatetimePicker } from '../DatetimePicker/DatetimePicker.tsx';

type Props<TFormShape extends FieldValues> = Omit<
  ComponentProps<typeof DatetimePicker>,
  'onChange' | 'value'
> & {
  readonly formProps: HookFormProps<TFormShape>;
  readonly name: FieldPathByValue<TFormShape, DateTime | null>;
  readonly label: string;
};

export const ValidatedDateTimePicker = <TFormValues extends FieldValues>(
  props: Props<TFormValues>,
): ReactElement | null => {
  const { formProps, name, label, ...otherProps } = props;

  const { field, fieldState, formState } = useController({
    control: formProps.control,
    name,
  });

  const { error } = fieldState;
  const showError = showFieldError(formState, error) || !(field.value?.isValid ?? true);
  const controlComponentId = useId();

  return (
    <Stack spacing={spacingElementLabel}>
      <InputLabel color={colorTextDefault} htmlFor={controlComponentId} size={LabelSize.L}>
        {label}
      </InputLabel>
      <DatetimePicker
        errorMessage={showError ? (error?.message ?? 'Provide a valid date.') : null}
        hasError={showError}
        onChange={field.onChange}
        ref={field.ref}
        value={field.value?.value || ''}
        {...otherProps}
      />
    </Stack>
  );
};

(ValidatedDateTimePicker as React.FC).displayName = 'ValidatedDateTimePicker';
