import React, { memo } from 'react';
import { AiActionTrackingProps } from '../../../../_shared/features/AI/types/AiActionTrackingProps.type.ts';
import { DataUiInput } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ReviewByGuidelinesPlugin } from '../../../../paperModels/aiReview/richText/plugins/ReviewByGuidelines/ReviewByGuidelinesPlugin.tsx';
import { useAiReviewPlugins } from '../../../../paperModels/aiReview/richText/plugins/ReviewByGuidelines/useAiReviewPlugins.ts';
import { AiActionName } from '../../../../repositories/serverModels/ai/AiActionName.type.ts';
import {
  createEditorWithPlugins,
  getBaseEditor,
  usePluginWithParams,
} from '../../editorCore/Editor.composition.tsx';
import { EditorProps } from '../../editorCore/types/Editor.composition.type.ts';
import { Props } from '../../editorCore/types/Editor.contract.type.ts';
import { useTranslateContent } from '../../plugins/ai/actions/TranslateContent/TranslateContentPlugin.tsx';
import { useApiLimitations } from '../../plugins/apiLimitations/ApiLimitationsPlugin.tsx';
import { SimpleTextEditorLimitations } from '../../plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { useCompositionModeAdjustments } from '../../plugins/behavior/CompositionModeAdjustmentsPlugin.tsx';
import { useFocus } from '../../plugins/behavior/FocusPlugin.tsx';
import { useForceSelectionOnBlur } from '../../plugins/behavior/ForceSelectionOnBlurPlugin.tsx';
import { useForceSingleUnstyledBlock } from '../../plugins/behavior/ForceSingleUnstyledBlockPlugin.tsx';
import { useOnChange } from '../../plugins/behavior/OnChangePlugin.tsx';
import { useSpellCheck } from '../../plugins/behavior/SpellCheckPlugin.tsx';
import { useClipboard } from '../../plugins/clipboard/ClipboardPlugin.tsx';
import { useComments } from '../../plugins/comments/CommentsPlugin.tsx';
import {
  EnterKeyBehavior,
  useCustomInputHandling,
} from '../../plugins/customInputHandling/CustomInputHandlingPlugin.tsx';
import { useEntityApi } from '../../plugins/entityApi/EntityApiPlugin.tsx';
import { useInlineAiPlugins } from '../../plugins/inlineAi/useInlineAiPlugins.ts';
import { useKeyboardShortcuts } from '../../plugins/keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { textKeyCommandMap } from '../../plugins/keyboardShortcuts/api/editorKeyboardUtils.ts';
import { useTextApi } from '../../plugins/textApi/TextApiPlugin.tsx';
import { useBlockToolbar } from '../../plugins/toolbars/BlockToolbarPlugin.tsx';
import { useInlineToolbar } from '../../plugins/toolbars/InlineToolbarPlugin.tsx';
import { useUndoRedo } from '../../plugins/undoRedo/UndoRedoPlugin.tsx';
import { usePlaceholder } from '../../plugins/visuals/PlaceholderPlugin.tsx';
import { useStyles } from '../../plugins/visuals/StylesPlugin.tsx';
import { useWrapper } from '../../plugins/visuals/WrapperPlugin.tsx';
import { useSimpleMultilineTextInputStatus } from './plugins/SimpleMultilineTextInputStatusPlugin.tsx';

const useComposedEditor = () =>
  createEditorWithPlugins(
    getBaseEditor(),
    usePlaceholder,
    useWrapper,
    useCompositionModeAdjustments,
    useFocus,
    usePluginWithParams(useApiLimitations, SimpleTextEditorLimitations),
    usePluginWithParams(useKeyboardShortcuts, textKeyCommandMap),
    useTextApi,
    useClipboard,
    useUndoRedo,
    useStyles,
    useOnChange,
    useInlineToolbar,
    useBlockToolbar,
    useForceSelectionOnBlur,
    usePluginWithParams(useCustomInputHandling, EnterKeyBehavior.AlwaysSoftNewLine),
    useForceSingleUnstyledBlock,
    useComments,
    useSpellCheck,
    useSimpleMultilineTextInputStatus,
    useTranslateContent,
    useEntityApi,
    ...useInlineAiPlugins(AiActionName.PlainTextInlineInstruction),
    ...useAiReviewPlugins(),
  );

export type SimpleMultilineTextInputProps = EditorProps<typeof useComposedEditor> &
  Partial<Props<ReviewByGuidelinesPlugin>> &
  AiActionTrackingProps;

export const SimpleMultilineTextInput: React.FC<SimpleMultilineTextInputProps> = memo((props) => {
  const { ComposedEditor } = useComposedEditor();

  return (
    <ComposedEditor dataUiInput={props.dataUiInput ?? DataUiInput.ItemTextElement} {...props} />
  );
});

SimpleMultilineTextInput.displayName = 'SimpleMultilineTextInput';
