import { useMenuTrigger } from '@react-aria/menu';
import { useMenuTriggerState } from '@react-stately/menu';
import { MenuTriggerProps } from '@react-types/menu';
import React, { PropsWithChildren } from 'react';
import { MenuDivider } from './components/MenuDivider.tsx';
import { MenuItem } from './components/MenuItem.tsx';
import { MenuList } from './components/MenuList.tsx';
import { MenuOutwardLink } from './components/MenuOutwardLink.tsx';
import { MenuRouterLink } from './components/MenuRouterLink.tsx';
import { MenuSection } from './components/MenuSection.tsx';
import { MenuTrigger } from './components/MenuTrigger.tsx';
import { Submenu } from './components/Submenu.tsx';
import { SubmenuTriggerItem } from './components/SubmenuTriggerItem.tsx';
import { MenuContextProvider } from './contexts/MenuContext.tsx';

type Props = MenuTriggerProps;

const MenuComponent = (props: PropsWithChildren<Props>) => {
  const menuTriggerState = useMenuTriggerState(props);

  const triggerRef = React.useRef(null);
  const menuListRef = React.useRef(null);

  const { menuTriggerProps, menuProps } = useMenuTrigger(
    {
      type: 'menu',
    },
    menuTriggerState,
    triggerRef,
  );

  return (
    <MenuContextProvider
      menuTriggerProps={menuTriggerProps}
      menuTriggerRef={triggerRef}
      menuTriggerState={menuTriggerState}
      menuListProps={menuProps}
      menuListRef={menuListRef}
    >
      {props.children}
    </MenuContextProvider>
  );
};

const menuComposition = {
  Divider: MenuDivider,
  Item: MenuItem,
  List: MenuList,
  OutwardLink: MenuOutwardLink,
  RouterLink: MenuRouterLink,
  Section: MenuSection,
  Submenu,
  SubmenuTrigger: SubmenuTriggerItem,
  Trigger: MenuTrigger,
} as const;

export const Menu = Object.assign(MenuComponent, menuComposition);
