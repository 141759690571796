import { isElement } from '@kontent-ai/DOM';
import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';
import { useObserveElementPresence } from '@kontent-ai/hooks';
import React from 'react';
import { createPortal } from 'react-dom';
import { AiOperationState } from '../../../../../../../_shared/features/AI/types/AiOperationState.ts';
import {
  AiError,
  getAiErrorMessage,
} from '../../../../../../../_shared/features/AI/types/aiErrors.ts';
import { ElementReference } from '../../../../../../itemEditor/features/ContentItemEditing/containers/hooks/useItemElementReference.ts';
import {
  AiActionStatus,
  getAiOperationStatus,
} from '../../../components/status/AiActionStatus.tsx';
import { getAiElementStatusPlaceholderId } from '../../../components/status/AiElementStatusPlaceholder.tsx';
import { translateContentErrorMessageByErrorCode } from '../../../utils/translateContentAiErrors.ts';
import { AiTranslateButton } from './AiTranslateButton.tsx';
import { getAiTranslateButtonPlaceholderId } from './AiTranslateButtonPlaceholder.tsx';

type Props = {
  readonly aiOperationState: AiOperationState;
  readonly availableLanguages: ReadonlyArray<IBaseSelectItem>;
  readonly disabled: boolean;
  readonly element: ElementReference;
  readonly error: AiError | null;
  readonly perform: (sourceLanguageId: string) => void;
};

export const AiTranslateAction: React.FC<Props> = ({
  aiOperationState,
  availableLanguages,
  disabled,
  element,
  error,
  perform,
}) => {
  const { current: buttonPlaceholder } = useObserveElementPresence(
    getAiTranslateButtonPlaceholderId(element.elementId, element.contentComponentId),
  );

  const { current: statusPlaceholder } = useObserveElementPresence(
    getAiElementStatusPlaceholderId(element.elementId, element.contentComponentId),
  );

  return (
    <>
      {isElement(statusPlaceholder) &&
        createPortal(
          <AiActionStatus
            label={getStatusLabel(aiOperationState, error)}
            status={getAiOperationStatus(aiOperationState, error)}
          />,
          statusPlaceholder,
        )}
      {isElement(buttonPlaceholder) &&
        createPortal(
          <AiTranslateButton
            aiOperationState={aiOperationState}
            availableLanguages={availableLanguages}
            disabled={disabled}
            perform={perform}
          />,
          buttonPlaceholder,
        )}
    </>
  );
};

const getStatusLabel = (aiOperationState: AiOperationState, error: AiError | null): string => {
  if (error) return getAiErrorMessage(error, translateContentErrorMessageByErrorCode);

  return aiOperationState === AiOperationState.Pending ? 'Translating' : 'Translation finished';
};
