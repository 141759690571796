import { useIdWithPrefix } from '@kontent-ai/hooks';
import React, { useState } from 'react';
import { FullScreenModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/FullScreenModalDialog.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { ContentItemId } from '../../../../../_shared/models/ContentItemId.type.ts';
import { OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { StyledDataTable } from '../../shared/components/StyledDataTable.tsx';
import { Widget } from '../../shared/components/Widget.tsx';
import { WidgetListingContentShowMoreButton } from '../../shared/components/WidgetListingContentShowMoreButton.tsx';
import { WidgetListingStateContent } from '../../shared/components/WidgetListingStateContent.tsx';
import { entityListWidgetDisplayCount } from '../../shared/constants/widgetConstants.ts';
import { WidgetListingState } from '../../shared/types/WidgetListingState.type.ts';
import { createContentItemId } from '../../shared/utils/createContentItemId.ts';
import { TasksAssignedToYouWidgetDetailContainer as TasksAssignedToYouWidgetDetail } from '../containers/TasksAssignedToYouWidgetDetailContainer.tsx';
import { YourTaskWithPathToItem } from '../types/YourTaskWithPathToItem.type.ts';
import { TasksAssignedToYouWidgetRow } from './TasksAssignedToYouWidgetRow.tsx';
import { WidgetEmptyState } from './WidgetEmptyState.tsx';

type TasksAssignedToYouWidgetProps = {
  readonly onDetailTaskClick: (taskId: Uuid, itemIdentity: ContentItemId) => void;
  readonly onShowMoreClick?: () => void;
  readonly onTaskClick: (taskId: Uuid, itemIdentity: ContentItemId) => void;
  readonly tasks: ReadonlyArray<YourTaskWithPathToItem>;
  readonly tasksState: WidgetListingState;
};

export const TasksAssignedToYouWidget: React.FC<TasksAssignedToYouWidgetProps> = ({
  onDetailTaskClick,
  onShowMoreClick,
  onTaskClick,
  tasks,
  tasksState,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const openDetail = () => {
    onShowMoreClick?.();
    setIsDialogOpen(true);
  };

  const closeDetail = () => setIsDialogOpen(false);

  const widgetTitleId = useIdWithPrefix('your-tasks');

  return (
    <>
      <Widget>
        <Widget.Title text="Your tasks" id={widgetTitleId} />
        <WidgetListingStateContent state={tasksState} renderEmptyState={() => <WidgetEmptyState />}>
          <Widget.Body>
            <StyledDataTable
              dataUiCollectionName={DataUiCollection.ContentTasks}
              header={<DataTableHeadRow columns={tableHeadColumns} />}
              ariaLabelledBy={widgetTitleId}
            >
              {tasks.slice(0, entityListWidgetDisplayCount).map((task) => (
                <TasksAssignedToYouWidgetRow
                  key={task.id}
                  task={task}
                  onTaskClick={() =>
                    onTaskClick(task.id, createContentItemId(task.itemId, task.variantId))
                  }
                  rowFocusLinkAriaLabel={`visit content item with task — ${task.description}`}
                />
              ))}
            </StyledDataTable>
          </Widget.Body>

          <Widget.Footer>
            <Widget.CenterContent>
              <WidgetListingContentShowMoreButton
                onClick={openDetail}
                ariaDescribedBy={widgetTitleId}
              />
            </Widget.CenterContent>
          </Widget.Footer>
        </WidgetListingStateContent>
      </Widget>

      <FullScreenModalDialog
        headline="Your tasks"
        isDismissable
        isOpen={isDialogOpen}
        onClose={closeDetail}
      >
        <TasksAssignedToYouWidgetDetail
          tasks={tasks}
          onTaskClick={onDetailTaskClick}
          ariaLabelledBy={widgetTitleId}
        />
      </FullScreenModalDialog>
    </>
  );
};

const tableHeadColumns = Immutable.List.of<Column>(
  {
    columnName: 'Task',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--2',
  },
  {
    columnName: 'Assigned by',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--1',
  },
  {
    columnName: 'Due date',
    orderBy: OrderByDirection.Descending,
    className: 'data-table__column--1',
  },
);
