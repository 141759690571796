import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {
  SimpleTabTitle,
  SimpleTabTitleModifier,
} from '../../../../../_shared/components/TabMenu/SimpleTabTitle.tsx';
import { TabBadgeType } from '../../../../../_shared/components/TabMenu/TabBadgeType.tsx';
import { TabWrapper } from '../../../../../_shared/components/TabMenu/TabWrapper.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { pluralizeWithCount } from '../../../../../_shared/utils/stringUtils.ts';
import { IContentGroup } from '../../../../contentInventory/content/models/contentTypeElements/types/ContentGroup.ts';

export const ContentGroupTabCssClass = 'content-group-tab';
export const SelectedContentGroupTabCssClass = 'content-group-tab--is-selected';

function getIncompleteElementsTooltip(incompleteElements: number): string | null {
  if (!incompleteElements) {
    return null;
  }
  return `${pluralizeWithCount('element', incompleteElements)} left to complete`;
}

function getCommentsTooltip(comments: number): string | null {
  if (!comments) {
    return null;
  }
  return `${comments} ${comments === 1 ? 'comment' : 'comments'}`;
}

function getTooltipText(incompleteElements: number, comments: number): string | undefined {
  return (
    [getIncompleteElementsTooltip(incompleteElements), getCommentsTooltip(comments)]
      .filter((x) => !!x)
      .join('\n') || undefined
  );
}

export interface IContentGroupTabOwnProps {
  readonly badgeType?: TabBadgeType;
  readonly contentGroup: IContentGroup;
  readonly hideComments?: boolean;
  readonly incompleteElements: number;
  readonly selected: boolean;
  readonly onSelect: () => void;
}

interface IContentGroupTabProps extends IContentGroupTabOwnProps {
  readonly commentCount?: number;
  readonly onCommentsClick: () => void;
}

const propTypes: PropTypesShape<IContentGroupTabProps> = {
  badgeType: PropTypes.oneOf(Object.values(TabBadgeType)),
  commentCount: PropTypes.number,
  contentGroup: PropTypes.object.isRequired,
  hideComments: PropTypes.bool,
  incompleteElements: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  onCommentsClick: PropTypes.func,
  selected: PropTypes.bool.isRequired,
};

export const ContentGroupTab: React.FC<IContentGroupTabProps> = ({
  badgeType,
  commentCount,
  contentGroup,
  hideComments,
  incompleteElements,
  selected,
  onCommentsClick,
  onSelect,
}) => {
  const tooltipText = getTooltipText(incompleteElements, commentCount || 0);
  const showComments = !hideComments && !!commentCount;

  return (
    <Tooltip tooltipText={tooltipText} placement="top">
      <TabWrapper
        className={classNames(ContentGroupTabCssClass, {
          [SelectedContentGroupTabCssClass]: selected,
        })}
        dataUiAttributes={{
          ...getDataUiElementAttribute(DataUiElement.ContentGroup),
          ...getDataUiObjectNameAttribute(contentGroup.name),
        }}
        selected={selected}
        onClick={onSelect}
        badgeType={badgeType}
      >
        <div className="tabbed-navigation__tab-layout">
          <SimpleTabTitle
            title={contentGroup.name}
            modifier={SimpleTabTitleModifier.IsInContentGroup}
          />
          {showComments && (
            <div className="tabbed-navigation__tab-info">
              <QuinaryButton onClick={onCommentsClick} tooltipPlacement="top" tooltipText="">
                <QuinaryButton.Icon
                  icon={Icons.Bubble}
                  screenReaderText={`${commentCount} comments`}
                />
                <QuinaryButton.Badge value={commentCount} />
              </QuinaryButton>
            </div>
          )}
        </div>
      </TabWrapper>
    </Tooltip>
  );
};

ContentGroupTab.displayName = 'ContentGroupTab';
ContentGroupTab.propTypes = propTypes;
