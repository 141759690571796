import { Callout } from '@kontent-ai/component-library/Callout';
import { Card } from '@kontent-ai/component-library/Card';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { isAiReviewEnabled } from '../../../../../_shared/selectors/aiSelectors.ts';
import { useFeatureActivationDialog } from '../../hooks/useFeatureActivationDialog.ts';
import { useGetFeatureActivationState } from '../../hooks/useGetFeatureActivationState.ts';
import { activateInnovationLabFeature } from '../../thunks/activateInnovationLabFeature.ts';
import { deactivateInnovationLabFeature } from '../../thunks/deactivateInnovationLabFeature.ts';
import { InnovationLabCardComponent } from '../../types/InnovationLabCardComponent.type.ts';
import { FeatureActivationDialog } from '../FeatureActivationDialog.tsx';
import { FeatureDeactivationDialog } from '../FeatureDeactivationDialog.tsx';
import { InnovationLabFeatureButton } from '../InnovationLabFeatureButton.tsx';
import { InnovationLabFeatureCard } from '../InnovationLabFeatureCard.tsx';
import { InnovationLabFeatureGenericallyUnavailable } from '../InnovationLabFeatureGenericallyUnavailable.tsx';
import { InnovationLabStatus } from '../InnovationLabFeatureStatus.tsx';
import { InnovationLabGenericConsent } from '../InnovationLabGenericConsent.tsx';
import { InnovationLabGenericDeactivationWarning } from '../InnovationLabGenericDeactivationWarning.tsx';

export const AiReviewCard: InnovationLabCardComponent = ({ featureInfo }) => {
  const activationState = useGetFeatureActivationState(AiReviewCard.isFeatureEnabledSelector);

  const {
    dialogDisplayed,
    isAsyncOperationInProgress,
    onCardActionButtonClick,
    onDialogActionClick,
    onDialogClose,
  } = useFeatureActivationDialog(
    featureInfo,
    activationState,
    activateInnovationLabFeature('ai-review'),
    deactivateInnovationLabFeature('ai-review'),
  );

  const featureState = featureInfo.status;

  return (
    <>
      <InnovationLabFeatureCard
        cardLabel={AiReviewCard.featureName}
        component="section"
        featureState={featureState}
      >
        <Card.Headline
          renderAuxiliaryElements={() => <InnovationLabStatus activationState={activationState} />}
        >
          {AiReviewCard.featureName}
        </Card.Headline>

        <Card.Body>
          <Stack spacing={Spacing.M}>
            <p>
              Use AI to review your content based on your specific instructions. Define content
              review guidelines for individual content elements, so your content creators can check
              if what they wrote complies with the guidelines.
            </p>
            <InnovationLabFeatureGenericallyUnavailable
              activationState={activationState}
              featureState={featureState}
            />
          </Stack>
        </Card.Body>

        <Card.Footer>
          <InnovationLabFeatureButton
            activationState={activationState}
            featureName={AiReviewCard.featureName}
            featureState={featureState}
            isAsyncOperationInProgress={isAsyncOperationInProgress}
            onClick={onCardActionButtonClick}
          />
        </Card.Footer>
      </InnovationLabFeatureCard>
      <FeatureActivationDialog
        headline={`Activate ${AiReviewCard.featureName}`}
        isOpen={dialogDisplayed === 'activation'}
        isActivating={isAsyncOperationInProgress}
        onClose={onDialogClose}
        onActivate={onDialogActionClick}
      >
        <Stack spacing={Spacing.M}>
          <Callout calloutType="quickTip" hideSubheadline>
            <p>
              <b>Innovation Lab feature activation</b>
            </p>
            <p>You’re about to activate the {AiReviewCard.featureName}.</p>
            <p>
              The AI can analyze your written content and suggest relevant changes. You can provide
              feedback on the suggestions you receive from the AI to help us improve your
              experience.
            </p>
          </Callout>
          <InnovationLabGenericConsent />
        </Stack>
      </FeatureActivationDialog>
      <FeatureDeactivationDialog
        headline={`Deactivate ${AiReviewCard.featureName}`}
        isOpen={dialogDisplayed === 'deactivation'}
        isDeactivating={isAsyncOperationInProgress}
        onClose={onDialogClose}
        onDeactivate={onDialogActionClick}
      >
        <InnovationLabGenericDeactivationWarning featureName={AiReviewCard.featureName} />
      </FeatureDeactivationDialog>
    </>
  );
};

AiReviewCard.isFeatureEnabledSelector = isAiReviewEnabled;
AiReviewCard.featureName = 'Review content with AI';
AiReviewCard.releaseDate = '2024-10-17T00:00:00.000Z';
