import { assert, Collection } from '@kontent-ai/utils';
import { EditorState } from 'draft-js';
import Immutable from 'immutable';
import { ClipboardEvent, forwardRef, useCallback } from 'react';
import { useHistory } from 'react-router';
import { rememberReturnScrollId } from '../../../../../../../_shared/actions/sharedActions.ts';
import { trackUserEventWithData } from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { CreateAutoScrollId } from '../../../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { TrackedEvent } from '../../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { TrackUserEventWithData } from '../../../../../../../_shared/models/TrackUserEvent.type.ts';
import { ActiveCapabilityType } from '../../../../../../../_shared/models/activeCapability.type.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { getLinkedContentItemPath } from '../../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { RequiredAssetCreationMetadata } from '../../../../../../../repositories/serverModels/AssetServerModels.type.ts';
import { removeTemporaryAssetIdentifiers } from '../../../../../../contentInventory/assets/actions/assetLibraryActions.ts';
import { uploadAssetsFromEditing } from '../../../../../../contentInventory/assets/actions/thunkAssetLibraryActions.ts';
import { IOnAssetFinished } from '../../../../../../contentInventory/assets/actions/thunks/createAssets.ts';
import { FileWithThumbnail } from '../../../../../../contentInventory/assets/models/FileWithThumbnail.type.ts';
import { ElementType } from '../../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { IRichTextTypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/RichTextTypeElement.ts';
import {
  convertContentComponentToContentItemVariant,
  pasteContentToRichText,
} from '../../../../../../richText/actions/thunkRichTextActions.ts';
import {
  RichTextInput,
  RichTextInputProps,
} from '../../../../../../richText/editors/richText/RichTextInput.tsx';
import { getRichTextElementStatistics } from '../../../../../../richText/plugins/apiStatistics/api/editorStatistics.ts';
import { IFocusable } from '../../../../../../richText/plugins/behavior/FocusPlugin.tsx';
import { PasteContentToRichTextParams } from '../../../../../../richText/plugins/clipboard/thunks/pasteContentToRichText.ts';
import { OnAddComment } from '../../../../../../richText/plugins/comments/CommentsPlugin.tsx';
import { IApprovedSuggestion } from '../../../../../../richText/plugins/comments/api/editorSuggestionUtils.ts';
import { InsertContentComponent } from '../../../../../../richText/plugins/contentComponents/api/EditorContentComponentApi.type.ts';
import { TrackRTECommandUsed } from '../../../../../../richText/plugins/keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import {
  EmptyContentComponents,
  IContentComponent,
} from '../../../../../models/contentItem/ContentComponent.ts';
import {
  getCommentThreadIdByExternalSegmentIdMappingForElement,
  getFocusedCommentThreadIdForElement,
  getTextElementCommentThreadsByExternalSegmentId,
} from '../../../../../selectors/elementCommentThreads.ts';
import { CommentThreadState } from '../../../../../types/CommentThreadState.ts';
import { IRichTextWarningResult } from '../../../../../utils/itemElementWarningCheckers/types/IRichTextItemElementWarningResult.type.ts';
import { getValidationSelectorId } from '../../../../../utils/itemElementWarningCheckers/utils/getValidationSelectorId.ts';
import { prepareNewContentItemDialogForContentComponent } from '../../../../NewContentItem/actions/thunkNewContentItemActions.ts';
import { getAvailableContentTypesForContentComponent } from '../../../../NewContentItem/utils/getAvailableContentTypesForContentComponent.ts';
import { focusedCommentThreadChanged } from '../../../actions/contentItemEditingActions.ts';
import {
  addCommentToRichTextElement,
  blurCommentThread,
  changeRichStringElementValue,
  createNewContentComponent,
  duplicateContentItem,
  markSuggestionAsApproved,
  setRichTextElementClipboard,
  trackContentComponentCreated,
} from '../../../actions/thunkContentItemEditingActions.ts';
import { ReadonlyEmptyElementPlaceholder } from '../../../models/ReadonlyEmptyElementPlaceholder.ts';
import { ElementReference, useItemElementReference } from '../../hooks/useItemElementReference.ts';

type IRichTextInputOwnProps = Pick<
  RichTextInputProps,
  | 'autoFocus'
  | 'className'
  | 'disabled'
  | 'editedEntityName'
  | 'editorState'
  | 'onContentChange'
  | 'onEscape'
>;

interface IContentItemRichTextInputContainerProps extends IRichTextInputOwnProps {
  readonly typeElement: IRichTextTypeElement;
}

const emptyCommentThreadIdMapping: ReadonlyMap<Uuid, Uuid> = new Map();
const emptyThreads: ReadonlyMap<Uuid, CommentThreadState> = new Map();

export const ContentItemRichTextInput = forwardRef<
  IFocusable,
  IContentItemRichTextInputContainerProps
>(({ typeElement, ...otherProps }, ref) => {
  const { elementId } = typeElement;
  const element = useItemElementReference(typeElement);
  const { elementName = null, itemId = null, contentComponentId = null } = element ?? {};

  const allowCreateCommentThread = useSelector((state) =>
    hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.ViewContent, state),
  );
  const approvedSuggestions = useSelector((state) => state.contentApp.editorUi.approvedSuggestions);

  const threads = useSelector((state) =>
    elementId
      ? getTextElementCommentThreadsByExternalSegmentId(
          state.contentApp.editedContentItemVariantComments.commentThreads,
          elementId,
          contentComponentId,
        )
      : emptyThreads,
  );
  const commentThreadIdMapping = useSelector((state) =>
    elementId
      ? getCommentThreadIdByExternalSegmentIdMappingForElement(
          state.contentApp.editedContentItemVariantComments.commentThreads,
          elementId,
          contentComponentId,
        )
      : emptyCommentThreadIdMapping,
  );
  const focusedCommentThreadId = useSelector((s) =>
    elementId
      ? getFocusedCommentThreadIdForElement(
          s.contentApp.editedContentItemVariantComments.commentThreads,
          elementId,
          contentComponentId ?? null,
          s.contentApp.editedContentItemVariantComments.focusedCommentThreadId,
        )
      : null,
  );
  const isPossiblyIncorrectPlacementWarningDismissed = useSelector(
    (state) => state.sharedApp.userProperties.dismissedPossiblyIncorrectPlacementWarning,
  );
  const statistics = useSelector((state) =>
    itemId ? getRichTextElementStatistics(state.sharedApp.currentProjectId, itemId) : undefined,
  );
  const validationResultSelectorId = getValidationSelectorId(
    elementId,
    contentComponentId ?? undefined,
  );
  const validationResult = useSelector(
    (state) =>
      state.contentApp.itemValidationWarnings.get(
        validationResultSelectorId,
      ) as IRichTextWarningResult,
  );
  const contentItemCollectionId = useSelector(
    (state) => state.contentApp.editedContentItem?.collectionId ?? '',
  );

  const singleUsableContentTypeIdForComponent = useSelector((state) => {
    const contentTypesForComponent = getAvailableContentTypesForContentComponent(state);

    return contentTypesForComponent.size === 1
      ? (contentTypesForComponent.first()?.id ?? null)
      : null;
  });

  const dispatch = useDispatch();

  const initializeNewContentItemDialog = useCallback(
    (allowedContentTypeIds: Immutable.Set<Uuid>) =>
      dispatch(prepareNewContentItemDialogForContentComponent({ allowedContentTypeIds })),
    [],
  );

  const onBlurCommentThread = useCallback(() => dispatch(blurCommentThread()), []);

  const onConvertContentComponentToContentItemVariant = useCallback(
    (elementReference: ElementReference, componentId: Uuid, selectedWorkflowId?: Uuid) =>
      dispatch(
        convertContentComponentToContentItemVariant(
          elementReference,
          componentId,
          selectedWorkflowId,
        ),
      ),
    [],
  );

  const onAddComment: OnAddComment = useCallback(
    (editorState, type, api) => {
      assert(element, () => 'Missing edited element reference');
      return dispatch(addCommentToRichTextElement(element, editorState, type, api));
    },
    [element],
  );

  const onCreateContentComponent = useCallback(
    (
      elementReference: ElementReference,
      insertContentComponentItem: InsertContentComponent,
      editorState: EditorState,
      placeholderBlockKey: string,
      preselectedContentTypeId?: Uuid,
    ) => {
      dispatch(trackContentComponentCreated());
      return dispatch(
        createNewContentComponent(
          elementReference,
          insertContentComponentItem,
          editorState,
          placeholderBlockKey,
          preselectedContentTypeId,
        ),
      );
    },
    [],
  );

  const onDuplicateModularContentItem = useCallback(
    (contentItemId: Uuid, destinationCollectionId: Uuid) =>
      dispatch(duplicateContentItem(contentItemId, destinationCollectionId)),
    [],
  );

  const onFilesUpload = useCallback(
    (
      files: Map<Uuid, FileWithThumbnail>,
      metadata: RequiredAssetCreationMetadata,
      onAssetFinished: IOnAssetFinished,
    ) => dispatch(uploadAssetsFromEditing(files, metadata, onAssetFinished)),
    [],
  );

  const onFocusCommentThread = useCallback(
    (threadId: Uuid) => dispatch(focusedCommentThreadChanged(threadId)),
    [],
  );

  const onPasteContent = useCallback(
    async (
      editorState: EditorState,
      params: PasteContentToRichTextParams,
    ): Promise<EditorState> => {
      if (element?.itemId && element?.rootRichTextElementId) {
        const result = await dispatch(
          pasteContentToRichText(element.elementId, element.elementType)(
            {
              editorState,
              contentComponents: EmptyContentComponents,
            },
            params,
          ),
        );
        if (result.contentComponents.size > 0) {
          // Store newly pasted content components to the top level rich text element
          dispatch(
            changeRichStringElementValue(
              element.itemId,
              element.rootRichTextElementId,
              (elementData) => ({
                ...elementData,
                contentComponents: new Map<Uuid, IContentComponent>([
                  ...Collection.getEntries(elementData.contentComponents),
                  ...Collection.getEntries(result.contentComponents),
                ]),
              }),
            ),
          );
        }
        return result.editorState;
      }
      return editorState;
    },
    [element],
  );

  const onPostprocessingAssetsFinished = useCallback(
    (oldAssetIds: UuidArray) => dispatch(removeTemporaryAssetIdentifiers(oldAssetIds)),
    [],
  );

  const history = useHistory();

  const onRedirectToContentItem = useCallback(
    (contentItemId: Uuid) => {
      const currentPath = history.location.pathname;
      const targetPath = getLinkedContentItemPath(currentPath, contentItemId);

      history.push(targetPath);

      const returnScrollId = CreateAutoScrollId.forContentItem(contentItemId);
      dispatch(
        rememberReturnScrollId(targetPath, {
          path: currentPath,
          scrollId: returnScrollId,
        }),
      );
    },
    [history],
  );

  const onSuggestionApplied = useCallback(
    (suggestion: IApprovedSuggestion) =>
      dispatch(markSuggestionAsApproved(suggestion.commentThreadId, suggestion.suggestionId)),
    [],
  );

  const onSetRichTextClipboard = useCallback(
    (e: ClipboardEvent, editorState: EditorState) => {
      if (element) {
        dispatch(setRichTextElementClipboard(e, editorState, element));
      }
    },
    [element],
  );

  const onTrackRTECommandUsed = useCallback<TrackRTECommandUsed>(
    (commandInfo) =>
      dispatch(
        trackUserEventWithData(TrackedEvent.RTECommandUsed, {
          elementId,
          elementName: elementName ?? '',
          elementType: ElementType.RichText,
          ...commandInfo,
        }),
      ),
    [elementId, elementName],
  );

  const onTrackUserEventWithData: TrackUserEventWithData = useCallback(
    (...args) => dispatch(trackUserEventWithData(...args)),
    [],
  );

  if (!element) {
    return null;
  }

  return (
    <RichTextInput
      {...otherProps}
      focusableRef={ref}
      aiGuidelinesIds={typeElement.aiGuidelinesIds}
      allowCreateCommentThread={allowCreateCommentThread}
      approvedSuggestions={approvedSuggestions}
      commentThreadIdMapping={commentThreadIdMapping}
      commentThreads={threads}
      contentItemCollectionId={contentItemCollectionId}
      element={element}
      focusedCommentThreadId={focusedCommentThreadId}
      isPossiblyIncorrectPlacementWarningDismissed={isPossiblyIncorrectPlacementWarningDismissed}
      limitations={typeElement}
      singleUsableContentTypeIdForComponent={singleUsableContentTypeIdForComponent}
      statistics={statistics}
      validationResult={validationResult}
      initializeNewContentItemDialog={initializeNewContentItemDialog}
      onBlurCommentThread={onBlurCommentThread}
      onConvertContentComponentToContentItemVariant={onConvertContentComponentToContentItemVariant}
      onAddComment={onAddComment}
      onCreateContentComponent={onCreateContentComponent}
      onDuplicateModularContentItem={onDuplicateModularContentItem}
      onFilesUpload={onFilesUpload}
      onFocusCommentThread={onFocusCommentThread}
      onPasteContent={onPasteContent}
      onPostprocessingAssetsFinished={onPostprocessingAssetsFinished}
      onRedirectToContentItem={onRedirectToContentItem}
      onSuggestionApplied={onSuggestionApplied}
      placeholder={otherProps.disabled ? ReadonlyEmptyElementPlaceholder.StringElement : undefined}
      setRichTextClipboard={onSetRichTextClipboard}
      trackRTECommandUsed={onTrackRTECommandUsed}
      trackUserEventWithData={onTrackUserEventWithData}
    />
  );
});

ContentItemRichTextInput.displayName = 'ContentItemRichTextInput';
