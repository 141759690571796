import { isArray } from '@kontent-ai/utils';
import { useNumberFormatter } from '@react-aria/i18n';
import { useHover } from '@react-aria/interactions';
import { useSlider } from '@react-aria/slider';
import { mergeProps } from '@react-aria/utils';
import { useSliderState } from '@react-stately/slider';
import { FocusableProps } from '@react-types/shared';
import { SliderProps } from '@react-types/slider';
import PropTypes from 'prop-types';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { useOurFocusRing } from '../../hooks/useOurFocusRing.ts';
import { Column } from '../../layout/Row/Column.tsx';
import { Row } from '../../layout/Row/Row.tsx';
import { Stack } from '../../layout/Stack/Stack.tsx';
import { SrOnly } from '../../styles/srOnly.tsx';
import { colorTextDefault, colorTextDisabled } from '../../tokens/decision/colors.ts';
import { BorderRadius } from '../../tokens/quarks/border.ts';
import { Spacing } from '../../tokens/quarks/spacing.ts';
import { Typography } from '../../tokens/quarks/typography.ts';
import { px } from '../../tokens/utils/utils.ts';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { CLPropTypes } from '../../validators/propTypes.ts';
import { Tooltip } from '../Tooltip/Tooltip.tsx';
import { Thumb } from './components/Thumb.tsx';
import {
  sliderTrackHeight,
  sliderTrackLineBackground,
  sliderTrackLineBackgroundDisabled,
  sliderTrackLineHeight,
  sliderTrackLineProgressBackground,
  sliderTrackLineProgressBackgroundDisabled,
} from './tokens.ts';

const ensureValueIsInRange = (value: number, min: number, max: number): number => {
  if (value < min) {
    return min;
  }

  if (value > max) {
    return max;
  }

  return value;
};

const StyledTrack = styled.div<{ readonly $disabled?: boolean }>`
  position: relative;
  width: 100%;
  height: ${px(sliderTrackHeight)};
  cursor: ${({ $disabled }) => ($disabled ? 'auto' : 'pointer')};
`;

const StyledTrackLine = styled.div<{ readonly $disabled?: boolean }>`
  position: absolute;
  top: 50%;

  width: 100%;
  height: ${px(sliderTrackLineHeight)};
  border-radius: ${px(BorderRadius.Pill)};
  margin-top: -${px(sliderTrackLineHeight / 2)};

  overflow: hidden;
  background: ${({ $disabled }) =>
    $disabled ? sliderTrackLineBackgroundDisabled : sliderTrackLineBackground};
`;

interface IStyledTrackProgressProps {
  readonly $disabled?: boolean;
  readonly $thumbPercentage: number;
}

const StyledTrackProgress = styled.div.attrs<IStyledTrackProgressProps>(({ $thumbPercentage }) => ({
  style: {
    width: `${$thumbPercentage * 100}%`,
  },
}))<IStyledTrackProgressProps>`
  height: 100%;
  width: 50%;
  background: ${({ $disabled }) =>
    $disabled ? sliderTrackLineProgressBackgroundDisabled : sliderTrackLineProgressBackground};
`;

const StyledBoundaryLabel = styled.span(Typography.BodyMedium);

type PickedSliderProps = Pick<
  SliderProps<number>,
  'onChange' | 'onChangeEnd' | 'step' | 'value' | 'defaultValue'
>;

export interface ISliderProps extends PickedSliderProps, FocusableProps {
  readonly disabled?: boolean;
  readonly formatOptions?: Intl.NumberFormatOptions;
  readonly label: string;
  readonly maxValue: number;
  readonly minValue: number;
  readonly renderMaxValueLabel?: (maxValue: number) => ReactNode;
  readonly renderMinValueLabel?: (minValue: number) => ReactNode;
}

const minMaxValuePropTypeValidator: React.Validator<number> = (
  props: ISliderProps,
  propName: keyof ISliderProps,
  componentName,
) => {
  const value = props[propName];

  if (!value) {
    return null;
  }

  if (typeof value !== 'number' || value < props.minValue || value > props.maxValue) {
    return new Error(
      `${propName} in ${componentName} must be a number in the range of [minValue, maxValue]`,
    );
  }

  return null;
};

const propTypes: PropTypeMap<ISliderProps> = {
  autoFocus: PropTypes.bool,
  defaultValue: CLPropTypes.multiple(PropTypes.number, minMaxValuePropTypeValidator),
  disabled: PropTypes.bool,
  formatOptions: PropTypes.object,
  label: PropTypes.string.isRequired,
  maxValue: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onChangeEnd: PropTypes.func,
  onFocus: PropTypes.func,
  onFocusChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  renderMaxValueLabel: PropTypes.func,
  renderMinValueLabel: PropTypes.func,
  step: PropTypes.number.isRequired,
  value: CLPropTypes.multiple(PropTypes.number, minMaxValuePropTypeValidator),
};

const getValueOrFirstItem = (value: number | ReadonlyArray<number>): number =>
  isArray(value) ? (value[0] ?? -1) : value;

export const Slider = React.forwardRef<HTMLDivElement, ISliderProps>(
  (
    {
      autoFocus,
      defaultValue,
      disabled,
      formatOptions,
      label,
      maxValue,
      minValue,
      onBlur,
      onChange,
      onChangeEnd,
      onFocus,
      onFocusChange,
      onKeyDown,
      onKeyUp,
      renderMaxValueLabel,
      renderMinValueLabel,
      step = 1,
      value,
      ...otherProps
    },
    forwardedRef,
  ) => {
    const trackRef = React.useRef(null);
    const numberFormatter = useNumberFormatter(formatOptions);

    const focusableProps: FocusableProps = {
      autoFocus,
      onBlur,
      onFocus,
      onFocusChange,
      onKeyDown,
      onKeyUp,
    };

    const ariaSliderProps: SliderProps = {
      isDisabled: disabled,
      label,
      minValue,
      maxValue,
      onChange: (newValue) => onChange?.(getValueOrFirstItem(newValue)),
      onChangeEnd: (newValue) => onChangeEnd?.(getValueOrFirstItem(newValue)),
      step,
      value: value ? [ensureValueIsInRange(value, minValue, maxValue)] : undefined,
      defaultValue: defaultValue
        ? [ensureValueIsInRange(defaultValue, minValue, maxValue)]
        : undefined,
    };

    const state = useSliderState({
      ...ariaSliderProps,
      numberFormatter,
    });

    const { groupProps, trackProps, labelProps, outputProps } = useSlider(
      ariaSliderProps,
      state,
      trackRef,
    );

    const { focusProps, isFocusVisible } = useOurFocusRing();

    const { hoverProps, isHovered } = useHover({});

    const output = state.getThumbValueLabel(0);
    const isDragging = state.isThumbDragging(0);

    const minValueLabel = state.getFormattedValue(minValue);
    const maxValueLabel = state.getFormattedValue(maxValue);

    return (
      <div
        ref={forwardedRef}
        {...mergeProps(hoverProps, otherProps)}
        {...getDataUiComponentAttribute(Slider)}
      >
        <SrOnly>
          <label {...labelProps}>{label}</label>
          <output {...outputProps}>{output}</output>
        </SrOnly>

        <Stack {...groupProps}>
          {/* The track element holds the visible track line and the thumb. */}
          <StyledTrack $disabled={disabled} ref={trackRef} {...trackProps}>
            <StyledTrackLine $disabled={disabled}>
              <StyledTrackProgress
                $disabled={disabled}
                $thumbPercentage={state.getThumbPercent(0)}
              />
            </StyledTrackLine>

            <Tooltip
              delay={[0, 0]}
              placement="top"
              tooltipText={output}
              visible={isHovered || isDragging || isFocusVisible}
            >
              <Thumb
                disabled={disabled}
                focusProps={focusProps}
                isFocusVisible={isFocusVisible}
                state={state}
                trackRef={trackRef}
                {...focusableProps}
              />
            </Tooltip>
          </StyledTrack>
          <Row
            spacing={Spacing.S}
            css={`color: ${disabled ? colorTextDisabled : colorTextDefault};`}
          >
            <Column>
              {renderMinValueLabel ? (
                renderMinValueLabel(minValue)
              ) : (
                <StyledBoundaryLabel>{minValueLabel}</StyledBoundaryLabel>
              )}
            </Column>
            <Column width="content">
              {renderMaxValueLabel ? (
                renderMaxValueLabel(maxValue)
              ) : (
                <StyledBoundaryLabel>{maxValueLabel}</StyledBoundaryLabel>
              )}
            </Column>
          </Row>
        </Stack>
      </div>
    );
  },
);

Slider.displayName = 'Slider';
Slider.propTypes = propTypes;
