import { Box } from '@kontent-ai/component-library/Box';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Size, Spacing, Typography } from '@kontent-ai/component-library/tokens';
import React, { useId } from 'react';
import { MultilineInput } from '../../../../../../_shared/components/MultilineInput.tsx';
import { ValidationConstants } from '../../../../../../_shared/constants/validationConstants.ts';
import {
  DataUiElement,
  DataUiInput,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ILanguage } from '../../../../../../data/models/languages/Language.ts';
import { ReadonlyEmptyElementPlaceholder } from '../../../../../itemEditor/features/ContentItemEditing/models/ReadonlyEmptyElementPlaceholder.ts';
import { ITextTypeElement } from '../../../../content/models/contentTypeElements/TextTypeElement.ts';
import { AssetElementWrapper } from '../Elements/AssetElementWrapper.tsx';

interface IProps {
  readonly buttons: JSX.Element;
  readonly descriptionLabel: string;
  readonly errorNotificationBar?: JSX.Element;
  readonly isEditable: boolean;
  readonly language: ILanguage;
  readonly languageDescription: string;
  readonly loader?: JSX.Element;
  readonly onChangeDescription: (variantId: Uuid, newDescription: string) => void;
  readonly placeholder: string;
  readonly typeElement: ITextTypeElement;
}

export const AssetDescription: React.FC<IProps> = ({
  buttons,
  descriptionLabel,
  errorNotificationBar,
  isEditable,
  language,
  languageDescription,
  loader,
  onChangeDescription,
  placeholder,
  typeElement,
}) => {
  const assetDescriptionLabelId = useId();

  return (
    <AssetElementWrapper
      disabled={!isEditable}
      typeElement={typeElement}
      dataUiElement={DataUiElement.AssetDescription}
      title={descriptionLabel}
      titleId={assetDescriptionLabelId}
    >
      <Tooltip
        tooltipText={isEditable ? undefined : 'You can’t edit description in this language.'}
        placement="bottom-start"
      >
        <Stack className="form__input-with-buttons-wrapper" spacing={Spacing.S}>
          <Inline>
            <Box minHeight={Size.M} flexGrow={1} display="flex" alignItems="center">
              {loader ? (
                loader
              ) : (
                <MultilineInput
                  aria-labelledby={assetDescriptionLabelId}
                  dataUiInput={DataUiInput.AssetDescription}
                  hasNotAllowedCursorWhenDisabled
                  isDisabled={!isEditable}
                  maxLength={ValidationConstants.RichTextElementValueMaxLength}
                  onChange={(name) =>
                    isEditable ? onChangeDescription(language.id, name) : undefined
                  }
                  placeholder={
                    isEditable ? placeholder : ReadonlyEmptyElementPlaceholder.StringElement
                  }
                  typography={Typography.BodyLarge}
                  value={languageDescription}
                />
              )}
            </Box>
            {isEditable && buttons}
          </Inline>
          {errorNotificationBar}
        </Stack>
      </Tooltip>
    </AssetElementWrapper>
  );
};

AssetDescription.displayName = 'AssetDescription';
