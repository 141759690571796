import { Box } from '@kontent-ai/component-library/Box';
import { TabItem, TabView } from '@kontent-ai/component-library/TabView';
import {
  Spacing,
  spacingMainLayoutLeft,
  spacingMainLayoutRight,
  spacingMainLayoutTop,
} from '@kontent-ai/component-library/tokens';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router';
import { EnsureContentProductionCapabilities } from '../../../_shared/components/EnsureContentProductionCapabilities.tsx';
import { EnsureSelectedVariantIdInRoute } from '../../../_shared/components/EnsureSelectedVariantIdInRoute.tsx';
import { PageTitle } from '../../../_shared/components/PageTitle.tsx';
import { useRedirectPropsWithSameSearch } from '../../../_shared/components/routing/useRedirectPropsWithSameSearch.tsx';
import { AppNames } from '../../../_shared/constants/applicationNames.ts';
import {
  ContentPaceRoute,
  ContentStatusRoute,
  MissionControlCalendarRoute,
  MissionControlDashboardRoute,
  MissionControlLanguageAgnosticRouteParams,
  MissionControlLanguageDependentRoute,
  MissionControlLanguageDependentRouteParams,
  MissionControlProjectOverviewRoute,
  MissionControlQuickstartRoute,
  MissionControlYourWorkRoute,
} from '../../../_shared/constants/routePaths.ts';
import { AuthorizedSection } from '../../../_shared/containers/routing/AuthorizedSection.tsx';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useGetLastRouteSegment } from '../../../_shared/hooks/useGetLastRouteSegment.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import {
  getSelectedLanguageId,
  getSelectedLanguageIdOrRouteMacro,
} from '../../../_shared/selectors/getSelectedLanguageId.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
  getDataUiObjectNameAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Capability, getUserCapability } from '../../../_shared/utils/permissions/capability.ts';
import { buildPath } from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import { projectHasQuickstart } from '../../../_shared/utils/trialUtils.ts';
import { getCurrentProject } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { contentRequiredCapabilities } from '../../contentInventory/shared/utils/contentInventoryRequiredCapabilities.ts';
import { localizedRouteLeft } from '../../home/actions/homeActions.ts';
import { EnsureSelectedArrangementForCalendar } from '../../home/containers/EnsureSelectedArrangementForCalendar.tsx';
import { Quickstart } from '../../quickstart/containers/Quickstart.tsx';
import {
  ensureSelectedVariantForMissionControlLanguageDependentRoutes,
  initMissionControl,
} from '../actions/thunkMissionControlActions.ts';
import { Calendar } from '../calendar/components/Calendar.tsx';
import { ContentPace } from '../contentPace/containers/ContentPace.tsx';
import { isContentPaceEnabled } from '../contentPace/selectors/isContentPaceEnabled.ts';
import { ContentStatusTabContent } from '../contentStatus/components/ContentStatusTabContent.tsx';
import { MissionControlDemoContextProvider } from '../contexts/MissionControlDemoContext.tsx';
import { DashboardTabContent } from '../dashboard/container/DashboardTabContent.tsx';
import { ProjectOverview } from '../projectOverview/components/ProjectOverview.tsx';
import { missionControlRequiredCapabilities } from '../shared/missionControlRequiredCapabilities.ts';
import { YourWork } from '../yourWork/components/YourWork.tsx';
import { MissionControlDemoModeToggle } from './MissionControlDemoModeToggle.tsx';
import { MissionControlTabPanel } from './MissionControlTabPanel.tsx';

export const MissionControlPage: React.FC = () => {
  const selectedSectionPath = useGetLastRouteSegment();
  const dispatch = useDispatch();

  const tabs = useTabs();

  const selectedTab = tabs.find((tab) => tab.to.endsWith(selectedSectionPath));

  useEffect(() => {
    dispatch(initMissionControl());
  }, []);

  return (
    <MissionControlDemoContextProvider>
      <Box display="flex" flexDirection="column" height="100%" paddingTop={spacingMainLayoutTop}>
        <Box
          paddingLeft={spacingMainLayoutLeft}
          paddingRight={spacingMainLayoutRight}
          paddingBottom={Spacing.XXL}
        >
          <PageTitle>{AppNames.MissionControl}</PageTitle>
        </Box>

        <Box flexGrow={1} minHeight={0}>
          <TabView selectedKey={selectedTab?.id || ''} items={tabs}>
            <Box paddingLeft={spacingMainLayoutLeft} paddingRight={spacingMainLayoutRight}>
              <TabView.TabGroup
                {...getDataUiCollectionAttribute(DataUiCollection.MissionControlNavigationMenu)}
                renderAuxElement={() => <MissionControlDemoModeToggle />}
              />
            </Box>
            <MissionControlTabPanel>{Routes}</MissionControlTabPanel>
          </TabView>
        </Box>
      </Box>
    </MissionControlDemoContextProvider>
  );
};

const tabs = {
  quickstart: {
    id: 'quickstart',
    name: AppNames.Quickstart,
    buildLink: (projectId: Uuid) =>
      buildPath<MissionControlLanguageAgnosticRouteParams>(MissionControlQuickstartRoute, {
        projectId,
      }),
    requiresOneOfCapabilities: missionControlRequiredCapabilities,
    routePath: MissionControlQuickstartRoute,
  },
  yourWork: {
    id: 'your-work',
    name: AppNames.YourWork,
    buildLink: (projectId: Uuid) =>
      buildPath<MissionControlLanguageAgnosticRouteParams>(MissionControlYourWorkRoute, {
        projectId,
      }),
    requiresOneOfCapabilities: contentRequiredCapabilities,
    routePath: MissionControlYourWorkRoute,
  },
  dashboard: {
    id: 'dashboard',
    name: AppNames.Dashboard,
    buildLink: (projectId: Uuid) =>
      buildPath<MissionControlLanguageAgnosticRouteParams>(MissionControlDashboardRoute, {
        projectId,
      }),
    requiresOneOfCapabilities: contentRequiredCapabilities,
    routePath: MissionControlDashboardRoute,
  },
  contentStatus: {
    id: 'content-status',
    name: AppNames.ContentStatus,
    buildLink: (projectId: Uuid) =>
      buildPath<MissionControlLanguageAgnosticRouteParams>(ContentStatusRoute, {
        projectId,
      }),
    requiresOneOfCapabilities: contentRequiredCapabilities,
    routePath: ContentStatusRoute,
  },
  contentPace: {
    id: 'content-pace',
    name: AppNames.ContentPace,
    buildLink: (projectId: Uuid) =>
      buildPath<MissionControlLanguageAgnosticRouteParams>(ContentPaceRoute, {
        projectId,
      }),
    requiresOneOfCapabilities: contentRequiredCapabilities,
    routePath: ContentPaceRoute,
  },
  projectOverview: {
    id: 'project-overview',
    name: AppNames.ProjectOverview,
    buildLink: (projectId: Uuid, languageId: Uuid) =>
      buildPath<MissionControlLanguageDependentRouteParams>(MissionControlProjectOverviewRoute, {
        projectId,
        variantId: languageId,
      }),
    requiresOneOfCapabilities: contentRequiredCapabilities,
    routePath: MissionControlProjectOverviewRoute,
  },
  calendar: {
    id: 'calendar',
    name: AppNames.Calendar,
    buildLink: (projectId: Uuid, languageId: Uuid) =>
      buildPath<MissionControlLanguageDependentRouteParams>(MissionControlCalendarRoute, {
        projectId,
        variantId: languageId,
      }),
    requiresOneOfCapabilities: contentRequiredCapabilities,
    routePath: MissionControlCalendarRoute,
  },
} satisfies Record<string, MissionControlTab>;

type MissionControlTab = {
  id: string;
  name: AppNames;
  buildLink: ((projectId: Uuid) => string) | ((projectId: Uuid, languageId: Uuid) => string);
  requiresOneOfCapabilities: ReadonlyArray<Capability>;
  routePath: string;
};

const tabStructure = [
  tabs.quickstart,
  tabs.yourWork,
  tabs.dashboard,
  tabs.contentStatus,
  tabs.contentPace,
  tabs.projectOverview,
  tabs.calendar,
];

const mapTabToMissionControlTabItem = (
  tab: MissionControlTab,
  projectId: Uuid,
  languageId: Uuid,
): MissionControlTabItem => ({
  id: tab.id,
  label: tab.name,
  to: tab.buildLink(projectId, languageId),
  ...getDataUiObjectNameAttribute(tab.id),
});

type MissionControlTabItem = TabItem & {
  readonly id: string;
  readonly label: AppNames;
  readonly to: string;
};

const useTabs = (): ReadonlyArray<MissionControlTabItem> => {
  const shouldShowQuickstart = useSelector((s) => projectHasQuickstart(s, getCurrentProject(s)));
  const shouldShowContentPace = useSelector(isContentPaceEnabled);
  const project = useSelector(getCurrentProject);
  const projectId = project.projectId;
  const selectedLanguageIdWithMacroFallback = useSelector(getSelectedLanguageIdOrRouteMacro);
  const userCapability = getUserCapability(project);

  return tabStructure
    .filter((tab) => (tab.id === 'content-pace' ? shouldShowContentPace : true))
    .filter((tab) => (tab.id === 'quickstart' ? shouldShowQuickstart : true))
    .filter((tab) => userCapability.canOneOf(tab.requiresOneOfCapabilities))
    .map((tab) =>
      mapTabToMissionControlTabItem(tab, projectId, selectedLanguageIdWithMacroFallback),
    );
};

const Routes: React.FC = () => {
  const getRedirectPropsWithSameSearch = useRedirectPropsWithSameSearch();
  const { projectId } = useParams<MissionControlLanguageAgnosticRouteParams>();
  const shouldShowQuickstart = useSelector((s) => projectHasQuickstart(s, getCurrentProject(s)));
  const shouldShowContentPace = useSelector(isContentPaceEnabled);
  const defaultRoute = shouldShowQuickstart
    ? tabs.quickstart.buildLink(projectId)
    : tabs.yourWork.buildLink(projectId);

  return (
    <Switch>
      {shouldShowQuickstart && (
        <Route path={tabs.quickstart.routePath}>
          <AuthorizedSection
            appName={tabs.quickstart.name}
            requiresOneOfCapabilities={tabs.quickstart.requiresOneOfCapabilities}
          >
            <Quickstart />
          </AuthorizedSection>
        </Route>
      )}
      <Route exact path={tabs.yourWork.routePath}>
        <AuthorizedSection
          appName={tabs.yourWork.name}
          requiresOneOfCapabilities={tabs.yourWork.requiresOneOfCapabilities}
        >
          <EnsureContentProductionCapabilities appName={tabs.yourWork.name}>
            <YourWork />
          </EnsureContentProductionCapabilities>
        </AuthorizedSection>
      </Route>
      <Route exact path={tabs.contentStatus.routePath}>
        <AuthorizedSection
          appName={tabs.contentStatus.name}
          requiresOneOfCapabilities={tabs.contentStatus.requiresOneOfCapabilities}
        >
          <EnsureContentProductionCapabilities appName={tabs.contentStatus.name}>
            <ContentStatusTabContent />
          </EnsureContentProductionCapabilities>
        </AuthorizedSection>
      </Route>
      <Route exact path={tabs.dashboard.routePath}>
        <AuthorizedSection
          appName={tabs.dashboard.name}
          requiresOneOfCapabilities={tabs.dashboard.requiresOneOfCapabilities}
        >
          <EnsureContentProductionCapabilities appName={tabs.dashboard.name}>
            <DashboardTabContent />
          </EnsureContentProductionCapabilities>
        </AuthorizedSection>
      </Route>
      {shouldShowContentPace && (
        <Route exact path={tabs.contentPace.routePath}>
          <AuthorizedSection
            appName={tabs.contentPace.name}
            requiresOneOfCapabilities={tabs.contentPace.requiresOneOfCapabilities}
          >
            <EnsureContentProductionCapabilities appName={tabs.contentPace.name}>
              <ContentPace />
            </EnsureContentProductionCapabilities>
          </AuthorizedSection>
        </Route>
      )}
      <Route path={MissionControlLanguageDependentRoute}>
        <LanguageDependentRoutes />
      </Route>
      <Route>
        <Redirect {...getRedirectPropsWithSameSearch({ to: defaultRoute })} />
      </Route>
    </Switch>
  );
};

const LanguageDependentRoutes: React.FC = () => {
  const { projectId, variantId } = useParams<MissionControlLanguageDependentRouteParams>();

  return (
    <EnsureSelectedVariantIdInRoute
      key={`${projectId}${variantId}`}
      currentRouteVariantId={variantId}
      ensureSelectedVariant={ensureSelectedVariantForMissionControlLanguageDependentRoutes}
      getSelectedLanguageIdFromStore={getSelectedLanguageId}
      localizedRouteLeft={localizedRouteLeft}
    >
      <Switch>
        <Route exact path={tabs.projectOverview.routePath}>
          <AuthorizedSection
            appName={tabs.projectOverview.name}
            requiresOneOfCapabilities={tabs.projectOverview.requiresOneOfCapabilities}
          >
            <EnsureContentProductionCapabilities appName={tabs.projectOverview.name}>
              <ProjectOverview />
            </EnsureContentProductionCapabilities>
          </AuthorizedSection>
        </Route>
        <Route exact path={tabs.calendar.routePath}>
          <AuthorizedSection
            appName={AppNames.Calendar}
            requiresOneOfCapabilities={tabs.calendar.requiresOneOfCapabilities}
          >
            <EnsureContentProductionCapabilities appName={tabs.calendar.name}>
              <EnsureSelectedArrangementForCalendar>
                <Calendar />
              </EnsureSelectedArrangementForCalendar>
            </EnsureContentProductionCapabilities>
          </AuthorizedSection>
        </Route>
      </Switch>
    </EnsureSelectedVariantIdInRoute>
  );
};
