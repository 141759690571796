import { Tooltip, disabledAriaTippyOptions } from '@kontent-ai/component-library/Tooltip';
import { useBreadcrumbItem } from '@react-aria/breadcrumbs';
import { PressEvent } from '@react-types/shared';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ITaxonomyTerm } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';

type Props = {
  readonly folder: ITaxonomyTerm;
  readonly isInTooltip?: boolean;
  readonly isLast: boolean;
  readonly onPress?: (event: PressEvent) => void;
};

const propTypes: PropTypesShape<Props> = {
  folder: PropTypes.object.isRequired,
  isInTooltip: PropTypes.bool,
  isLast: PropTypes.bool.isRequired,
  onPress: PropTypes.func,
};

const AssetFolderBreadcrumbItem: React.FC<Props> = ({ folder, isInTooltip, isLast, onPress }) => {
  const ref = useRef(null);

  const { itemProps } = useBreadcrumbItem(
    { children: folder.name, elementType: 'div', onPress, isCurrent: isLast },
    ref,
  );

  return (
    <Tooltip
      tooltipText={folder.name}
      placement={isInTooltip ? 'right' : 'bottom'}
      tippyOptions={disabledAriaTippyOptions}
    >
      <div
        className={classNames('asset-breadcrumbs__item', {
          'asset-breadcrumbs__item--is-last': isLast,
          'asset-breadcrumbs__item--is-clickable': !!onPress && !isLast,
          'asset-breadcrumbs__item--is-in-tooltip': isInTooltip,
        })}
        ref={ref}
        {...itemProps}
        {...getDataUiElementAttribute(DataUiElement.AssetFolderBreadcrumbItem)}
      >
        <Icon iconName={IconName.FolderInverted} className="asset-breadcrumbs__item-icon" />
        <span className="asset-breadcrumbs__item-name">{folder.name}</span>
      </div>
    </Tooltip>
  );
};

AssetFolderBreadcrumbItem.displayName = 'AssetFolderBreadcrumbItem';
AssetFolderBreadcrumbItem.propTypes = propTypes;

export { AssetFolderBreadcrumbItem };
