import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React from 'react';
import { ControlShortcutTemplate } from '../constants/shortcutSymbols.ts';
import { Button, ButtonSize } from '../uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../uiComponents/Button/buttonStyle.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../utils/dataAttributes/DataUiAttributes.ts';

interface ISaveChangesButtonStateProps {
  /** Use 'inverse' on dark backgrounds, e.g. status bar, otherwise use 'default'. */
  readonly buttonStyle: 'default' | 'inverse';
  readonly customClassName?: string;
  readonly dataUiAction?: DataUiAction;
  readonly disabled?: boolean;
  readonly isBeingSaved: boolean;
  readonly shortcuts?: string;
  readonly size?: ButtonSize;
  readonly text?: string;
  readonly tooltipText?: string;
}

interface ISaveChangesButtonDispatchProps {
  readonly onSave: () => void;
}

type SaveChangesButtonProps = ISaveChangesButtonStateProps & ISaveChangesButtonDispatchProps;

export const SaveChangesButton: React.FC<SaveChangesButtonProps> = (props) => {
  const {
    buttonStyle = 'default',
    customClassName,
    dataUiAction,
    disabled,
    isBeingSaved,
    onSave,
    shortcuts,
    size,
    text,
    tooltipText,
  } = props;

  const saveText = text || 'Save changes';
  const uiAction = dataUiAction || DataUiAction.Save;

  const isDisabled = isBeingSaved || disabled;

  return (
    <Tooltip
      tooltipText={isDisabled ? tooltipText : (tooltipText ?? saveText)}
      placement="bottom-end"
      shortcuts={isDisabled ? undefined : (shortcuts ?? ControlShortcutTemplate('s'))}
    >
      <Button
        customButtonClassName={customClassName}
        style={buttonStyle === 'default' ? ButtonStyle.Primary : ButtonStyle.PrimaryInverted}
        size={size}
        disabled={isDisabled}
        onClick={() => onSave()}
        hasLoader={isBeingSaved}
        {...getDataUiActionAttribute(uiAction)}
      >
        {isBeingSaved ? 'Saving' : saveText}
      </Button>
    </Tooltip>
  );
};

SaveChangesButton.displayName = 'SaveChangesButton';
