import { Button } from '@kontent-ai/component-library/Button';
import { Link } from 'react-router-dom';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type UsersToolbarActionsProps = {
  readonly createNewItemLinkPath: string | undefined;
};

export const UsersToolbarActions: React.FC<UsersToolbarActionsProps> = ({
  createNewItemLinkPath,
}) =>
  createNewItemLinkPath ? (
    <Link to={createNewItemLinkPath} {...getDataUiActionAttribute(DataUiAction.CreateNew)}>
      <Button buttonStyle="primary">Invite Users</Button>
    </Link>
  ) : null;

UsersToolbarActions.displayName = 'UsersToolbarActions';
