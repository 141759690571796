export const Data_AiGuidelines_Failed = 'Data_AiGuidelines_Failed';
export const Data_AiGuidelines_Started = 'Data_AiGuidelines_Started';
export const Data_AiGuidelines_Success = 'Data_AiGuidelines_Success';

export const Data_AdministratedSubscriptions_Failed = 'Data_AdministratedSubscriptions_Failed';
export const Data_AdministratedSubscriptions_Started = 'Data_AdministratedSubscriptions_Started';
export const Data_AdministratedSubscriptions_Success = 'Data_AdministratedSubscriptions_Success';

export const Data_AssetListing_Started = 'Data_AssetListing_Started';
export const Data_AssetListing_Success = 'Data_AssetListing_Success';

export const Data_AssetFolders_Failed = 'Data_AssetFolders_Failed';
export const Data_AssetFolders_Started = 'Data_AssetFolders_Started';
export const Data_AssetFolders_Success = 'Data_AssetFolders_Success';

export const Data_Assets_Loaded = 'Data_Assets_Loaded';

export const Data_AssetType_Failed = 'Data_AssetType_Failed';
export const Data_AssetType_Started = 'Data_AssetType_Started';
export const Data_AssetType_Success = 'Data_AssetType_Success';

export const Data_AuditEvents_Failed = 'Data_AuditEvents_Failed';
export const Data_AuditEvents_Started = 'Data_AuditEvents_Started';
export const Data_AuditEvents_Success = 'Data_AuditEvents_Success';

export const Data_Collections_Failed = 'Data_Collections_Failed';
export const Data_Collections_Started = 'Data_Collections_Started';
export const Data_Collections_Success = 'Data_Collections_Success';

export const Data_ContentTypes_Failed = 'Data_ContentTypes_Failed';
export const Data_ContentTypes_Started = 'Data_ContentTypes_Started';
export const Data_ContentTypes_Success = 'Data_ContentTypes_Success';

export const Data_ContentTypesUsage_Failed = 'Data_ContentTypesUsage_Failed';
export const Data_ContentTypesUsage_Started = 'Data_ContentTypesUsage_Started';
export const Data_ContentTypesUsage_Success = 'Data_ContentTypesUsage_Success';

export const Data_Filters_Failed = 'Data_Filters_Failed';
export const Data_Filters_Started = 'Data_Filters_Started';
export const Data_Filters_Success = 'Data_Filters_Success';

export const Data_Languages_Failed = 'Data_Languages_Failed';
export const Data_Languages_Started = 'Data_Languages_Started';
export const Data_Languages_Success = 'Data_Languages_Success';

export const Data_LanguageUsages_Failed = 'Data_LanguageUsages_Failed';
export const Data_LanguageUsages_Started = 'Data_LanguageUsages_Started';
export const Data_LanguageUsages_Success = 'Data_LanguageUsages_Success';

export const Data_LatestProductUpdate_Failed = 'Data_LatestProductUpdate_Failed';
export const Data_LatestProductUpdate_Started = 'Data_LatestProductUpdate_Started';
export const Data_LatestProductUpdate_Success = 'Data_LatestProductUpdate_Success';

export const Data_ListingContentItems_FullTextSearchTurnedOff =
  'Data_ListingContentItems_FullTextSearchTurnedOff';
export const Data_ListingContentItems_FullTextSearchTurnedOn =
  'Data_ListingContentItems_FullTextSearchTurnedOn';
export const Data_ListingContentItems_Started = 'Data_ListingContentItems_Started';
export const Data_ListingContentItems_Success = 'Data_ListingContentItems_Success';
export const Data_ListingContentItems_FullTextSearchBecameUnavailable =
  'Data_ListingContentItems_FullTextSearchBecameUnavailable';
export const Data_ListingContentItems_LoadUsages = 'Data_ListingContentItems_LoadUsages';

export const Data_ItemsAssignedToCurrentUser_Started = 'Data_ItemsAssignedToCurrentUser_Started';
export const Data_ItemsAssignedToCurrentUser_Success = 'Data_ItemsAssignedToCurrentUser_Success';
export const Data_ItemsAssignedToCurrentUser_Failed = 'Data_ItemsAssignedToCurrentUser_Failed';

export const Data_ItemsRecentlyEditedByCurrentUser_Started =
  'Data_ItemsRecentlyEditedByCurrentUser_Started';
export const Data_ItemsRecentlyEditedByCurrentUser_Success =
  'Data_ItemsRecentlyEditedByCurrentUser_Success';
export const Data_ItemsRecentlyEditedByCurrentUser_Failed =
  'Data_ItemsRecentlyEditedByCurrentUser_Failed';

export const Data_ListingContentItemsWithAllVariants_Success =
  'Data_ListingContentItemsWithAllVariants_Success';
export const Data_ListingContentItemsWithAllVariants_Failed =
  'Data_ListingContentItemsWithAllVariants_Failed';

export const Data_PlansForSelection_Failed = 'Data_PlansForSelection_Failed';
export const Data_PlansForSelection_Started = 'Data_PlansForSelection_Started';
export const Data_PlansForSelection_Success = 'Data_PlansForSelection_Success';

export const Data_ProjectContainer_LoadActiveUsers_Failed =
  'Data_ProjectContainer_LoadActiveUsers_Failed';
export const Data_ProjectContainer_LoadActiveUsers_Success =
  'Data_ProjectContainer_LoadActiveUsers_Success';

export const Data_Projects_Started = 'Data_Projects_Started';
export const Data_Projects_Success = 'Data_Projects_Success';
export const Data_Projects_Failed = 'Data_Projects_Failed';

export const Data_Roles_Started = 'Data_Roles_Started';
export const Data_Roles_Success = 'Data_Roles_Success';
export const Data_Roles_Failed = 'Data_Roles_Failed';

export const Data_Sitemap_Started = 'Data_Sitemap_Started';
export const Data_Sitemap_Success = 'Data_Sitemap_Success';
export const Data_Sitemap_Failed = 'Data_Sitemap_Failed';

export const Data_Snippets_Started = 'Data_Snippets_Started';
export const Data_Snippets_Success = 'Data_Snippets_Success';
export const Data_Snippets_Failed = 'Data_Snippets_Failed';

export const Data_SnippetUsages_Started = 'Data_SnippetsUsage_Started';
export const Data_SnippetUsages_Success = 'Data_SnippetsUsage_Success';
export const Data_SnippetUsages_Failed = 'Data_SnippetsUsage_Failed';

export const Data_Spaces_Failed = 'Data_Spaces_Failed';
export const Data_Spaces_Started = 'Data_Spaces_Started';
export const Data_Spaces_Success = 'Data_Spaces_Success';

export const Data_TaxonomyGroups_Started = 'Data_TaxonomyGroups_Started';
export const Data_TaxonomyGroups_Success = 'Data_TaxonomyGroups_Success';
export const Data_TaxonomyGroups_Failed = 'Data_TaxonomyGroups_Failed';

export const Data_UserInfo_Failed = 'Data_UserInfo_Failed';
export const Data_UserInfo_Started = 'Data_UserInfo_Started';
export const Data_UserInfo_Success = 'Data_UserInfo_Success';

export const Data_UserNormalizedRoleWithSettings_Failed =
  'Data_UserNormalizedRoleWithSettings_Failed';
export const Data_UserNormalizedRoleWithSettings_Started =
  'Data_UserNormalizedRoleWithSettings_Started';
export const Data_UserNormalizedRoleWithSettings_Success =
  'Data_UserNormalizedRoleWithSettings_Success';

export const Data_UserProjectsInfo_Failed = 'Data_UserProjectsInfo_Failed';
export const Data_UserProjectsInfo_Started = 'Data_UserProjectsInfo_Started';
export const Data_UserProjectsInfo_Success = 'Data_UserProjectsInfo_Success';

export const Data_Users_Failed = 'Data_Users_Failed';
export const Data_Users_Started = 'Data_Users_Started';
export const Data_Users_Success = 'Data_Users_Success';

export const Data_Webhooks_Started = 'Data_Webhooks_Started';
export const Data_Webhooks_Success = 'Data_Webhooks_Success';
export const Data_Webhooks_Failed = 'Data_Webhooks_Failed';

export const Data_Workflow_Started = 'Data_Workflow_Started';
export const Data_Workflow_Success = 'Data_Workflow_Success';
export const Data_Workflow_Failed = 'Data_Workflow_Failed';

export const Data_WorkflowsUsage_Started = 'Data_WorkflowsUsage_Started';
export const Data_WorkflowsUsage_Success = 'Data_WorkflowsUsage_Success';
export const Data_WorkflowsUsage_Failed = 'Data_WorkflowsUsage_Failed';

export const Data_ContentTypeComponentUsage_Started = 'Data_ContentTypeComponentUsage_Started';
export const Data_ContentTypeComponentUsage_Finished = 'Data_ContentTypeComponentUsage_Finished';
export const Data_ContentTypeComponentUsage_Failed = 'Data_ContentTypeComponentUsage_Failed';

export const Data_EntityWebhooks_Started = 'Data_EntityWebhooks_Started';
export const Data_EntityWebhooks_Success = 'Data_EntityWebhooks_Success';
