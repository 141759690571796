import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { MenuItemWithMouseHover } from '@kontent-ai/component-library/MenuItem';
import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';
import { useVerticalMenu } from '@kontent-ai/component-library/VerticalMenu';
import { spacingPopupDistance } from '@kontent-ai/component-library/tokens';
import React, { useState } from 'react';
import { DropDownMenuControlled } from '../../../../../../../../component-library/components/DropDownMenu/DropDownMenuControlled.tsx';
import { AiOperationState } from '../../../../../../../_shared/features/AI/types/AiOperationState.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly aiOperationState: AiOperationState;
  readonly availableLanguages: ReadonlyArray<IBaseSelectItem>;
  readonly disabled: boolean;
  readonly perform: (sourceLanguageId: string) => void;
};

export const AiTranslateButton: React.FC<Props> = ({
  aiOperationState,
  availableLanguages,
  disabled,
  perform,
}) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const { verticalMenuState, VerticalMenu, verticalMenuProps } = useVerticalMenu([
    {
      id: 'availableLanguages',
      items: availableLanguages,
      label: 'Translate from',
      type: 'section',
    },
  ]);

  return (
    <DropDownMenuControlled
      onDropDownVisibilityChange={setIsMenuVisible}
      isDropDownVisible={isMenuVisible}
      tippyOptions={{
        placement: 'bottom-end',
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['top-end'],
              },
            },
          ],
        },
        offset: [0, spacingPopupDistance],
      }}
      renderDropDown={() => (
        <VerticalMenu
          {...verticalMenuProps}
          aria-label="Translate from"
          renderItem={({ item }) => (
            <MenuItemWithMouseHover
              menuItemState="default"
              text={item.textValue}
              onPress={() => {
                if (item.value) {
                  perform(item.value.id);
                }
                setIsMenuVisible(false);
              }}
            />
          )}
          state={verticalMenuState}
          contain={false}
        />
      )}
      renderTrigger={(triggerProps) => (
        <QuinaryButton
          tooltipText={isMenuVisible ? '' : getTooltipText(disabled, aiOperationState)}
          tooltipPlacement="bottom"
          disabled={disabled}
          activated={isMenuVisible}
          onClick={() => setIsMenuVisible(true)}
          ref={triggerProps.ref}
          {...getDataUiElementAttribute(DataUiElement.AiTranslateButton)}
        >
          <QuinaryButton.Icon icon={Icons.Translate} />
        </QuinaryButton>
      )}
    />
  );
};

const getTooltipText = (isDisabled: boolean, aiOperationState: AiOperationState): string => {
  if (isDisabled) {
    return aiOperationState === AiOperationState.Pending
      ? 'Translating'
      : 'Nothing to translate. Provide content in other language variants first.';
  }
  return 'Translate with AI';
};
