import { notNull } from '@kontent-ai/utils';
import { useBreadcrumbs } from '@react-aria/breadcrumbs';
import Immutable from 'immutable';
import ImmutablePropTypes from 'immutable-prop-types';
import PropTypes from 'prop-types';
import React from 'react';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ITaxonomyTerm } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';
import { AssetFolderBreadcrumbItem } from './AssetFolderBreadcrumbItem.tsx';
import { AssetFolderEllipsisBreadcrumbItem } from './AssetFolderEllipsisBreadcrumbItem.tsx';
import { AssetFolderHomeBreadcrumbItem } from './AssetFolderHomeBreadcrumbItem.tsx';

export interface IAssetFoldersBreadcrumbsStateProps {
  readonly openedFolders: Immutable.List<ITaxonomyTerm>;
}

export interface IAssetListingFoldersBreadcrumbsDispatchProps {
  readonly onFolderOpen: (folderId: Uuid) => void;
  readonly onFolderReset: () => void;
}

type Props = IAssetFoldersBreadcrumbsStateProps & IAssetListingFoldersBreadcrumbsDispatchProps;

const propTypes: PropTypeMap<Props> = {
  onFolderOpen: PropTypes.func.isRequired,
  onFolderReset: PropTypes.func.isRequired,
  openedFolders: ImmutablePropTypes.list.isRequired,
};

const itemSeparatorNode: JSX.Element = (
  <div className="asset-breadcrumbs__item-separator">
    <Icon iconName={IconName.ChevronRight} />
  </div>
);

const createFolderNodes = (
  folders: Immutable.List<ITaxonomyTerm>,
  onFolderOpen: (id: Uuid) => void,
): JSX.Element[] => {
  return folders.toArray().map((folder, index) => {
    const isLastFolder = index === folders.count() - 1;
    const onPress = () => onFolderOpen(folder.id);

    return (
      <React.Fragment key={folder.id}>
        {itemSeparatorNode}
        <AssetFolderBreadcrumbItem folder={folder} isLast={isLastFolder} onPress={onPress} />
      </React.Fragment>
    );
  });
};

const AssetFoldersBreadcrumbs: React.FC<Props> = ({
  onFolderOpen,
  onFolderReset,
  openedFolders,
}) => {
  const { navProps } = useBreadcrumbs({});

  if (openedFolders.isEmpty()) {
    return null;
  }

  const foldersExceedLimit = openedFolders.count() > 4;
  const foldersToRender = foldersExceedLimit
    ? Immutable.List(
        [
          openedFolders.get(0) ?? null,
          openedFolders.get(-2) ?? null,
          openedFolders.get(-1) ?? null,
        ].filter(notNull),
      )
    : openedFolders;

  const foldersNodes = createFolderNodes(foldersToRender, onFolderOpen);

  if (foldersExceedLimit) {
    const ellipsisNode: JSX.Element = (
      <React.Fragment key="ellipsis">
        {itemSeparatorNode}
        <AssetFolderEllipsisBreadcrumbItem
          onFolderOpen={onFolderOpen}
          onFolderReset={onFolderReset}
          openedFolders={openedFolders}
        />
      </React.Fragment>
    );

    foldersNodes.splice(1, 0, ellipsisNode);
  }

  return (
    <div
      className="asset-breadcrumbs"
      {...navProps}
      {...getDataUiElementAttribute(DataUiElement.Breadcrumbs)}
    >
      <AssetFolderHomeBreadcrumbItem onClick={onFolderReset} />
      {foldersNodes}
    </div>
  );
};

AssetFoldersBreadcrumbs.displayName = 'AssetFoldersBreadcrumbs';
AssetFoldersBreadcrumbs.propTypes = propTypes;

export { AssetFoldersBreadcrumbs };
