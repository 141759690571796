import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import { BrowseButton } from '../../../../../_shared/components/BrowseButton.tsx';
import { EmptyState } from '../../../../../_shared/components/EmptyState/EmptyState.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { FileWithThumbnail } from '../../models/FileWithThumbnail.type.ts';

export interface IEmptyAssetLibraryMessageProps {
  readonly onCreateFolder: (() => void) | null;
  readonly onUpload: ((files: ReadonlyArray<FileWithThumbnail>) => void) | null;
}

export const EmptyAssetLibraryMessage: React.FC<IEmptyAssetLibraryMessageProps> = ({
  onCreateFolder,
  onUpload,
}) => {
  return (
    <EmptyState size="shrink">
      <EmptyState.Image
        title="You’ll find all your assets and folders right here."
        url="/images/warnings/Assets.svg"
      />
      <EmptyState.Title>You’ll find all your assets and folders right here.</EmptyState.Title>
      <EmptyState.Content>
        When you add a file to the project, it’s stored here in the asset library.
      </EmptyState.Content>
      <EmptyState.Footer>
        <BrowseButton
          buttonStyle="primary"
          dataUiAction={DataUiAction.SelectFiles}
          linkName="Select files"
          multiple
          onUpload={onUpload}
          supportClick={!!onUpload}
          tooltipText={onUpload ? undefined : 'You don’t have permission to upload assets here.'}
        />
        <span>or</span>
        <Button
          buttonStyle="primary"
          disabled={!onCreateFolder}
          onClick={onCreateFolder ? onCreateFolder : undefined}
          tooltipText={
            onCreateFolder ? undefined : 'You don’t have permission to create folders here.'
          }
          {...getDataUiActionAttribute(DataUiAction.CreateFolder)}
        >
          Create a folder
        </Button>
      </EmptyState.Footer>
    </EmptyState>
  );
};

EmptyAssetLibraryMessage.displayName = 'EmptyAssetLibraryMessage';
