import PropTypes from 'prop-types';
import React from 'react';
import {
  PolymorphicComponent,
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../@types/PolymorphicComponent.type.ts';
import { BaseBoxProps, Box, BoxBorder } from '../../layout/Box/Box.tsx';
import {
  colorAlertIcon,
  colorBackgroundLowEmphasis,
  colorInfoIcon,
  colorWarningIcon,
} from '../../tokens/decision/colors.ts';
import { BaseColor } from '../../tokens/quarks/colors.ts';
import { BoxShadow } from '../../tokens/quarks/shadow.ts';
import { paperBorderRadius } from './tokens.ts';

export const PaperLevel = {
  Default: BoxShadow.None,
  Elevated: BoxShadow.M,
  Popout: BoxShadow.L,
} as const;

export type PaperLevel = (typeof PaperLevel)[keyof typeof PaperLevel];

type AllowedCustomBorderColors =
  | typeof colorAlertIcon
  | typeof colorBackgroundLowEmphasis
  | typeof colorWarningIcon
  | typeof colorInfoIcon;

type BasePaperProps = Readonly<{ level?: PaperLevel; borderColor?: AllowedCustomBorderColors }>;

const propTypes: PropTypeMap<BasePaperProps> = {
  level: PropTypes.oneOf(Object.values(PaperLevel)),
  borderColor: PropTypes.oneOf([
    colorAlertIcon,
    colorBackgroundLowEmphasis,
    colorInfoIcon,
    colorWarningIcon,
  ]),
};

export type PaperProps = BasePaperProps & Omit<BaseBoxProps, 'border' | 'boxShadow'>;

export const Paper = React.forwardRef(
  <TElement extends React.ElementType = 'div'>(
    {
      backgroundColor = BaseColor.White,
      borderColor,
      borderRadius = paperBorderRadius,
      children,
      className,
      component,
      level = PaperLevel.Default,
      ...otherProps
    }: React.PropsWithChildren<PaperProps> & PolymorphicComponentProps<TElement>,
    forwardedRef: PolymorphicRef<TElement>,
  ) => (
    <Box
      backgroundColor={backgroundColor}
      border={BoxBorder.LowEmphasis}
      borderColor={borderColor}
      borderRadius={borderRadius}
      boxShadow={level}
      className={className}
      component={component ?? 'div'}
      ref={forwardedRef}
      {...otherProps}
    >
      {children}
    </Box>
  ),
) as PolymorphicComponent<PaperProps>;

Paper.displayName = 'Paper';
Paper.propTypes = propTypes;
