import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import PropTypes from 'prop-types';
import React from 'react';
import { WorkflowStatus } from '../../../../component-library/components/WorkflowStatus/WorkflowStatus.tsx';
import { IContentItemVariantReference } from '../../../applications/itemEditor/models/contentItem/ContentItemVariantReference.ts';
import { renderWorkflowStepColor } from '../../../data/models/workflow/utils/renderWorkflowStepColor.ts';
import { UsageItemReference } from '../../utils/usage/usageUtils.ts';
import { AutoScrollId } from '../AutoScroll/AutoScrollId.ts';
import { AutoScrollLink } from '../AutoScroll/AutoScrollLink.tsx';

interface IEnabledUsageInItemVariantProps {
  readonly buildLink: (variantReference: IContentItemVariantReference) => string;
  readonly onClick?: () => void;
  readonly onNavigateToItem?: () => void;
  readonly scrollId?: AutoScrollId;
  readonly toScrollId: AutoScrollId;
  readonly typeName: string | null;
  readonly usage: UsageItemReference;
}

const propTypes: PropTypesShape<IEnabledUsageInItemVariantProps> = {
  buildLink: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onNavigateToItem: PropTypes.func,
  scrollId: PropTypes.object,
  toScrollId: PropTypes.object.isRequired,
  typeName: PropTypes.string,
  usage: PropTypes.object.isRequired,
};

const ExtraPublishedVersionNote: React.FC<IEnabledUsageInItemVariantProps> = ({
  scrollId,
  toScrollId,
  usage,
  buildLink,
  onNavigateToItem,
}) => {
  if (!usage.secondary) {
    return null;
  }

  const publishedVersion = usage.secondary;
  return (
    <div className="content-item-usage__additional-note">
      {'\u21b3 Also used in '}
      <AutoScrollLink
        aria-label={`Navigates to published version of item ${usage.primary.name}`}
        scrollId={scrollId}
        to={buildLink(publishedVersion)}
        toScrollId={toScrollId}
        onNavigated={onNavigateToItem}
      >
        published version
      </AutoScrollLink>{' '}
      of this item
    </div>
  );
};

export const EnabledUsageInItemVariant: React.FC<IEnabledUsageInItemVariantProps> = (props) => {
  const { buildLink, onClick, onNavigateToItem, scrollId, toScrollId, typeName, usage } = props;

  return (
    <div className="content-item-usage" onClick={onClick}>
      <AutoScrollLink
        aria-label={`Navigates to ${usage.primary.name}`}
        className="content-item-usage__primary-action content-item-usage__primary-action--is-clickable"
        scrollId={scrollId}
        to={buildLink(usage.primary)}
        toScrollId={toScrollId}
        onNavigated={onNavigateToItem}
      >
        <div
          className="content-item-usage__title"
          data-hj-suppress=""
          title={typeName ? `${usage.primary.name} (${typeName})` : usage.primary.name}
        >
          {usage.primary.name}
        </div>
        <Box marginLeft={Spacing.S}>
          <WorkflowStatus
            primary={{
              name: usage.primary.assignment.workflowStatus.name,
              background: renderWorkflowStepColor(usage.primary.assignment.workflowStatus.color),
            }}
          />
        </Box>
      </AutoScrollLink>
      <ExtraPublishedVersionNote
        buildLink={buildLink}
        toScrollId={toScrollId}
        scrollId={scrollId}
        usage={usage}
        typeName={typeName}
        onNavigateToItem={onNavigateToItem}
      />
    </div>
  );
};

EnabledUsageInItemVariant.displayName = 'EnabledUsageInItemVariant';
EnabledUsageInItemVariant.propTypes = propTypes;
