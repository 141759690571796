import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { GeneralTag } from '@kontent-ai/component-library/Selects';
import { gridUnit } from '@kontent-ai/component-library/tokens';
import Immutable from 'immutable';
import React, { ComponentProps, useState, useCallback, useEffect } from 'react';
import { FullScreenModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/FullScreenModalDialog.tsx';
import { Tag } from '../../../../../../component-library/components/Tag/Tag.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import { stringifyContentItemId } from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ListingRequestOptionsModel } from '../../../../../repositories/utils/ensureAllRequestedDataFetched.ts';
import { MissionControlRepository } from '../../../../unifiedMissionControl/repositories/MissionControlRepository.type.ts';
import { UnchangedPublishedItemsListingRequestOptionsModel } from '../../../../unifiedMissionControl/widgets/unchangedPublishedContent/types/UnchangedPublishedItemsListingRequestOptionsModel.type.ts';
import { daysSinceDateTimeStamp } from '../../../utils/daysSinceDate.ts';
import { StyledDataTable } from '../../shared/components/StyledDataTable.tsx';
import { Widget } from '../../shared/components/Widget.tsx';
import { WidgetListingContentShowMoreButton } from '../../shared/components/WidgetListingContentShowMoreButton.tsx';
import { WidgetListingStateContent } from '../../shared/components/WidgetListingStateContent.tsx';
import { NoUnchangedItemsMessage } from '../../shared/constants/uiConstants.ts';
import {
  entityListDetailDisplayCount,
  entityListWidgetDisplayCount,
} from '../../shared/constants/widgetConstants.ts';
import { useWidgetListingDataFetcher } from '../../shared/hooks/useWidgetListingDataFetcher.tsx';
import { UnchangedPublishedItemsWidgetDetailContainer } from '../containers/UnchangedPublishedItemsWidgetDetailContainer.tsx';
import { ContentTypeOption } from '../types/ContentTypeOption.type.ts';
import { createUnchangedPublishedItemFromServerModel } from '../utils/createUnchangedPublishedItemFromServerModel.ts';
import { UnchangedPublishedItemsWidgetRow } from './UnchangedPublishedItemsWidgetRow.tsx';

const renderGeneralTag = (
  count: number,
  defaultTagProps: Omit<ComponentProps<typeof Tag>, 'background' | 'children'>,
): React.ReactNode => (
  <GeneralTag
    countPlacement="before"
    countValue={count}
    label="Content types"
    {...defaultTagProps}
  />
);

type UnchangedPublishedItemsWidgetProps = Readonly<{
  contentTypeOptions: ContentTypeOption;
  itemsFetcher: MissionControlRepository['getUnchangedPublishedItems'];
  onOverviewItemClick?: () => void;
  onShowMoreClick?: () => void;
  useRowLinks: boolean;
}>;

export const UnchangedPublishedItemsWidget: React.FC<UnchangedPublishedItemsWidgetProps> = ({
  contentTypeOptions,
  itemsFetcher,
  onOverviewItemClick,
  onShowMoreClick,
  useRowLinks,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [selectedTypeIds, setSelectedTypeIds] = useState<UuidArray>([]);

  const selectedTypeIdsItemsFetcher = useCallback(
    (requestOptions: ListingRequestOptionsModel, abortSignal: AbortSignal) => {
      const options: UnchangedPublishedItemsListingRequestOptionsModel = {
        ...requestOptions,
        typeIds: selectedTypeIds,
      };

      return itemsFetcher(options, abortSignal);
    },
    [selectedTypeIds, itemsFetcher],
  );

  const {
    fetchInit,
    fetchMore,
    state: unchangedItemsState,
    data: unchangedItems,
  } = useWidgetListingDataFetcher(
    selectedTypeIdsItemsFetcher,
    createUnchangedPublishedItemFromServerModel,
  );

  useEffect(() => fetchInit(entityListWidgetDisplayCount).cancel, [fetchInit]);

  const openDetail = () => {
    onShowMoreClick?.();
    fetchMore(entityListDetailDisplayCount);
    setIsDialogOpen(true);
  };

  const closeDetail = () => setIsDialogOpen(false);

  const longestItemStaleness = daysSinceDateTimeStamp(unchangedItems[0]?.variant?.lastPublishedAt);

  return (
    <>
      <Widget>
        <Widget.Title text="Unchanged published items" />
        <Widget.AuxElement maxWidth={33 * gridUnit}>
          <MultiSelect
            items={contentTypeOptions}
            noWrap
            onSelectionChange={(selectedIds: ReadonlySet<Uuid>) =>
              setSelectedTypeIds([...selectedIds])
            }
            placeholder="All types"
            placeholderType="tag"
            selectedItemIds={selectedTypeIds}
            generalTagThreshold={2}
            renderGeneralTag={renderGeneralTag}
            {...getDataUiCollectionAttribute(DataUiCollection.ContentItems)}
          />
        </Widget.AuxElement>
        <WidgetListingStateContent
          state={unchangedItemsState}
          emptyStateMessage={NoUnchangedItemsMessage}
        >
          <Widget.Body>
            <StyledDataTable
              dataUiCollectionName={DataUiCollection.ContentItems}
              header={<DataTableHeadRow columns={widgetTableHeadColumns} />}
            >
              {unchangedItems.slice(0, entityListWidgetDisplayCount).map(({ item, variant }) => {
                const stalenessDaysCount = daysSinceDateTimeStamp(variant?.lastPublishedAt);
                return variant ? (
                  <UnchangedPublishedItemsWidgetRow
                    key={stringifyContentItemId(variant.id)}
                    id={variant.id}
                    itemName={item.name}
                    longestItemStaleness={longestItemStaleness}
                    onClick={onOverviewItemClick}
                    stalenessDaysCount={stalenessDaysCount}
                    useRowLinks={useRowLinks}
                  />
                ) : null;
              })}
            </StyledDataTable>
          </Widget.Body>

          <Widget.Footer>
            <Widget.CenterContent>
              <WidgetListingContentShowMoreButton onClick={openDetail} />
            </Widget.CenterContent>
          </Widget.Footer>
        </WidgetListingStateContent>
      </Widget>

      <FullScreenModalDialog
        headline="Unchanged published items"
        isOpen={isDialogOpen}
        isDismissable
        onClose={closeDetail}
      >
        <UnchangedPublishedItemsWidgetDetailContainer
          dataState={unchangedItemsState}
          unchangedItems={unchangedItems}
          useRowLinks={useRowLinks}
        />
      </FullScreenModalDialog>
    </>
  );
};

const widgetTableHeadColumns: Immutable.List<Column> = Immutable.List([
  {
    columnName: 'Name',
    orderBy: OrderByDirection.None,
  },
  {
    columnName: 'Days since published',
    orderBy: OrderByDirection.Descending,
  },
]);

UnchangedPublishedItemsWidget.displayName = 'UnchangedPublishedItemsWidget';
