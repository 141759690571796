import { Checkbox } from '@kontent-ai/component-library/Checkbox';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import {
  DataUiAction,
  getDataUiActionAttribute,
  getDataUiObjectNameAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { DataTableCell } from './DataTableCell.tsx';

interface IDataTableRowDataProps {
  readonly className?: string;
  readonly dataUiObjectName: string;
  readonly disabled?: boolean;
  readonly disabledCheckboxBalloonText?: string;
  readonly id: string;
  readonly isSelected?: boolean;
  readonly selectionDisabled?: boolean;
  readonly showCheckboxes?: boolean;
}

interface IDataTableRowCallbackProps {
  readonly onSelectionChange?: (isSelected: boolean) => void;
}

const propTypes: PropTypesShape<IDataTableRowDataProps & IDataTableRowCallbackProps> = {
  className: PropTypes.string,
  dataUiObjectName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  disabledCheckboxBalloonText: PropTypes.string,
  id: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  selectionDisabled: PropTypes.bool,
  showCheckboxes: PropTypes.bool,
};

export const DataTableRow: React.FC<
  React.PropsWithChildren<IDataTableRowDataProps & IDataTableRowCallbackProps>
> = ({
  children,
  className,
  dataUiObjectName,
  disabled,
  disabledCheckboxBalloonText,
  id,
  isSelected,
  onSelectionChange,
  selectionDisabled,
  showCheckboxes,
}) => (
  <tr
    className={classNames('data-table__row', className, {
      'data-table__row--is-selected': isSelected,
      'data-table__row--is-disabled': disabled,
    })}
    {...getDataUiObjectNameAttribute(dataUiObjectName)}
  >
    {showCheckboxes && (
      <DataTableCell className="data-table__column" hasCheckbox>
        <Checkbox
          ariaLabel="Select"
          checkboxState={selectionDisabled ? 'disabled' : 'default'}
          checked={isSelected}
          id={id}
          name="Select"
          onToggle={(newIsSelected) => onSelectionChange?.(newIsSelected)}
          tooltipPlacement="right"
          tooltipText={
            selectionDisabled && disabledCheckboxBalloonText
              ? disabledCheckboxBalloonText
              : undefined
          }
          {...getDataUiActionAttribute(DataUiAction.Select)}
        />
      </DataTableCell>
    )}
    {children}
  </tr>
);

DataTableRow.displayName = 'DataTableRow';
DataTableRow.propTypes = propTypes;
