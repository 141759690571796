import { createGuid } from '@kontent-ai/utils';
import { upsertUserProperty } from '../../../../../_shared/actions/thunkSharedActions.ts';
import {
  trackUserEvent,
  trackUserEventWithData,
} from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { projectNotificationService } from '../../../../../_shared/services/projectNotificationService.ts';
import {
  loadAssets,
  loadCollections,
  loadContentItemUsage,
  loadContentTypes,
  loadRoles,
  loadSitemap,
  loadSpaces,
  loadTaxonomyGroups,
  loadUsers,
  loadWorkflows,
} from '../../../../../data/actions/thunkDataActions.ts';
import { contentListingColumnsFilterStorage } from '../../../../../localStorages/contentListingColumnsFilterStorage.ts';
import { loadAiGuidelines } from '../../../../../paperModels/aiReview/data/actions/thunkAiReviewDataActions.ts';
import { setRichTextClipboard } from '../../../../richText/actions/thunkRichTextActions.ts';
import {
  convertElementsToDomainModel,
  ensureContentItemNameValidity,
} from '../../../stores/utils/contentItemHelperMethods.ts';
import { threadComparer } from '../../../utils/commentUtils.ts';
import {
  getAssetReferences,
  getReferencedContentItemIds,
  getUsedContentTypeIds,
} from '../../../utils/itemReferences/itemElementReferencesUtils.ts';
import {
  ensureLoadedContentTypes,
  loadContentTypeReferencesForItemEditing,
  loadDefaultListingItems,
  loadListingItems,
} from '../../LoadedItems/actions/thunkLoadedItemsActions.ts';
import {
  createNewContentItem,
  createNewLinkedItem,
  updateTypesPreferenceForEditing,
} from '../../NewContentItem/actions/thunkNewContentItemActions.ts';
import { ContentItemSidebarEditableSection } from '../constants/ContentItemSidebarEditableSection.ts';
import { createGetCommentsForContentItemVariant } from '../utils/contentItemVariantComments.ts';
import { createItemParser } from '../utils/parseContentItem.ts';
import {
  commentThreadResolved,
  commentThreadResolvingStarted,
  commentsLoaded,
  contentComponentsExpanded,
  contentItemNameChanged,
  contentItemVariantArchivingFailed,
  contentItemVariantArchivingFinished,
  contentItemVariantArchivingStarted,
  deactivateContentItemEditingAction,
  expandModularContentItemFinished,
  focusedCommentThreadChanged,
  highlightIncompleteItemElements,
  inlineCommentThreadAdded,
  resetIsHighlighting,
  updateAutogeneratedUrlSlugElements,
} from './contentItemEditingActions.ts';
import { createAddCommentToRichTextElementAction } from './thunks/addCommentToRichTextElement.ts';
import { createApproveSuggestionAction } from './thunks/approveSuggestion.ts';
import { createArchiveEditedContentItemVariantAction } from './thunks/archiveEditedContentItemVariant.ts';
import { archiveTaskCreator } from './thunks/archiveTask.ts';
import { createArchiveTemporaryContentItemAction } from './thunks/archiveTemporaryContentItem.ts';
import { assetElementValueChangedActionCreator } from './thunks/assetElementValueChanged.ts';
import { createAutoDispatchSavePending } from './thunks/autoDispatchSavePending.ts';
import { createAutoGenerateAllUrlSlugsAction } from './thunks/autoGenerateAllUrlSlugs.ts';
import { createAutoGenerateUrlSlugAction } from './thunks/autoGenerateUrlSlug.ts';
import { createBlurCommentThread } from './thunks/blurCommentThread.ts';
import { cancelContentItemVariantScheduledPublishCreator } from './thunks/cancelContentItemVariantScheduledPublish.ts';
import { cancelContentItemVariantScheduledUnpublishCreator } from './thunks/cancelContentItemVariantScheduledUnpublish.ts';
import { changeAssetElementValueActionCreator } from './thunks/changeAssetElementValue.ts';
import { changeRichStringElementValueActionCreator } from './thunks/changeRichStringElementValue.ts';
import { createChangeWorkflowAction } from './thunks/changeWorkflow.ts';
import { createCheckConcurrentConflictsBeforeSave } from './thunks/checkConcurrentConflictsBeforeSave.ts';
import { createCollapseAllContentComponentsAction } from './thunks/collapseAllContentComponents.ts';
import { createCollapseContentComponentAction } from './thunks/collapseContentComponent.ts';
import { createChangeGuidelinesElementVisibilityAction } from './thunks/collapseGuidelinesElement.ts';
import { completeTaskCreator } from './thunks/completeTask.ts';
import { createCopyContentValuesFromDifferentExistingVariantAction } from './thunks/copyContentValuesFromDifferentExistingVariant.ts';
import { createCreateCommentThreadItemAction } from './thunks/createCommentThreadItem.ts';
import { createCreateContentItemVariantAction } from './thunks/createContentItemVariant.ts';
import { createNewContentComponentActionCreator } from './thunks/createNewContentComponent.ts';
import { createNewItemFromLinkedItemsActionCreator } from './thunks/createNewItemFromLinkedItems.ts';
import { createCreateNewItemFromType } from './thunks/createNewItemFromType.ts';
import { createNewVersionActionCreator } from './thunks/createNewVersion.ts';
import { createOpenItemDetailsWithFocusedSection } from './thunks/createOpenItemDetailsWithFocusedSection.ts';
import { createReopenCommentThread } from './thunks/createReopenCommentThread.ts';
import { createShowCommentThreadsOnRemovedContentOnboarding } from './thunks/createShowCommentThreadsOnRemovedContentOnboarding.ts';
import { createTaskCreator } from './thunks/createTask.ts';
import { createUpdateAutogeneratedUrlSlugAfterDataPatch } from './thunks/createUpdateAutogeneratedUrlSlugAfterDataPatch.ts';
import { createCustomElementValueChangedAction } from './thunks/customElementValueChanged.ts';
import { createDateTimeElementValueChangedAction } from './thunks/dateTimeElementValueChanged.ts';
import { discardNewVersionActionCreator } from './thunks/discardNewVersion.ts';
import { createDisplayWarningsOnPublishSelectedAction } from './thunks/displayWarningsOnPublishSelected.ts';
import { createDuplicateContentItemAction } from './thunks/duplicateContentItem.ts';
import { createDuplicateEditedContentItemAction } from './thunks/duplicateEditedContentItem.ts';
import { createExpandAllContentComponentsAction } from './thunks/expandAllContentComponents.ts';
import { createExpandContentComponentAction } from './thunks/expandContentComponent.ts';
import { createExpandContentComponentsWithIncompleteElementsAction } from './thunks/expandContentComponentsWithIncompleteElements.ts';
import { expandModularContentItemActionCreator } from './thunks/expandModularContentItem.ts';
import { createFinishContentItemElementRefreshAction } from './thunks/finishContentItemElementRefresh.ts';
import {
  createEnsureThreadInViewportVisible,
  createFocusCommentThreadAction,
} from './thunks/focusCommentThread.ts';
import { createInitContentItemEditingAction } from './thunks/initContentItemEditing.ts';
import { loadContentItemReferencesActionCreator } from './thunks/loadContentItemReferences.ts';
import { createLoadFirstWorkflowStepCurrentRoleCanWorkWithAction } from './thunks/loadFirstWorkflowStepCurrentRoleCanWorkWith.ts';
import { createLoadPreviewConfiguration } from './thunks/loadPreviewConfiguration.ts';
import { loadRelatedContentItemElementsDataActionCreator } from './thunks/loadRelatedContentItemElementsData.ts';
import { loadTasksForItemVariantCreator } from './thunks/loadTasksForItemVariant.ts';
import { createMarkSuggestionAsApprovedAction } from './thunks/markSuggestionAsApproved.ts';
import { createLinkedItemsElementValueChangedAction } from './thunks/modularContentElementValueChanged.ts';
import { createMoveItemToArchivedStepAction } from './thunks/moveItemToArchivedStep.ts';
import { createOnMultipleChoiceOptionsChangeAction } from './thunks/multipleChoiceOptionsChanged.ts';
import { createNumberElementValueChangedAction } from './thunks/numberElementValueChanged.ts';
import { createPatchItemPropertyAction } from './thunks/patchItemProperty.ts';
import { createProcessDependentElementsAction } from './thunks/processDependentElements.ts';
import { publishContentItemVariantActionCreator } from './thunks/publishContentItemVariant.ts';
import { publishOneOrOpenModalActionCreator } from './thunks/publishOneOrOpenModal.ts';
import { createRefreshItemAfterLinkedEntityChange } from './thunks/refreshItemAfterLinkedEntityChange.ts';
import { createRefreshItemEditing } from './thunks/refreshItemEditing.ts';
import { createRegenerateUrlSlugAction } from './thunks/regenerateUrlSlug.ts';
import { createReleaseExpiredElementLocks } from './thunks/releaseExpiredElementLocks.ts';
import { reopenTaskCreator } from './thunks/reopenTask.ts';
import { createResolveCommentThread } from './thunks/resolveCommentThread.ts';
import { createRestoreFromArchivedStep } from './thunks/restoreFromArchivedStep.ts';
import { createRevalidateAssetAndRichTextElementsAction } from './thunks/revalidateAssetAndRichTextElements.ts';
import { createRevalidateEditedContentItemVariantElementsAction } from './thunks/revalidateEditedContentItemVariantElements.ts';
import { createRevalidateLinkedItemVariantElementsAction } from './thunks/revalidateLinkedItemVariantElements.ts';
import { createRichStringElementValueChangedAction } from './thunks/richStringElementValueChanged.ts';
import { createSaveContentItemCodenameToServerAction } from './thunks/saveContentItemCodenameToServer.ts';
import { createSaveContentItemNameToServerAction } from './thunks/saveContentItemNameToServer.ts';
import { createSaveContentItemSitemapToServerAction } from './thunks/saveContentItemSitemapToServer.ts';
import { createSaveEditedContentItemNameAction } from './thunks/saveEditedContentItemName.ts';
import { createSaveElementValuesToServerAction } from './thunks/saveElementValuesToServer.ts';
import { createSaveNewCommentThreadToServerAction } from './thunks/saveNewCommentThreadToServer.ts';
import { createSaveNewInlineCommentThreadToServerAction } from './thunks/saveNewInlineCommentThreadToServer.ts';
import { scheduleOneOrOpenModalActionCreator } from './thunks/scheduleOneOrOpenModal.ts';
import { schedulePublishOfContentItemVariantActionCreator } from './thunks/schedulePublishContentItemVariant.ts';
import { scheduleUnpublishOfContentItemVariantActionCreator } from './thunks/scheduleUnpublishContentItemVariant.ts';
import { createSelectContentGroupAction } from './thunks/selectContentGroup.ts';
import { createSelectContentGroupForAutoScrollAction } from './thunks/selectContentGroupForAutoScroll.ts';
import { createSelectContentGroupForElementAction } from './thunks/selectContentGroupForElement.ts';
import { createSelectContentGroupsByElementPathAction } from './thunks/selectContentGroupsByElementPath.ts';
import { createSelectWorkflowStepForEditedItemAction } from './thunks/selectWorkflowStepForEditedItem.ts';
import { createSetRichTextElementClipboardAction } from './thunks/setRichTextElementClipboard.ts';
import { createShowHighlightingOfIncompleteElementsAction } from './thunks/showHighlightingOfIncompleteElements.ts';
import { createShowIncompleteElementsInContentGroupAction } from './thunks/showIncompleteElementsInContentGroup.ts';
import { createStartContentItemElementRefreshAction } from './thunks/startContentItemElementRefresh.ts';
import { createStartNewInlineCommentThread } from './thunks/startNewInlineCommentThread.ts';
import { createStringElementValueChangedAction } from './thunks/stringElementValueChanged.ts';
import { createTaxonomyElementValueChangedAction } from './thunks/taxonomyElementValueChanged.ts';
import { createTrackAssignmentEvents } from './thunks/trackAssigmentEvents.ts';
import { createTrackContentComponentCreatedAction } from './thunks/trackContentComponentCreated.ts';
import {
  createTrackSampleContentItemEventAction,
  createTrackSampleContentItemOpened,
} from './thunks/trackSampleContentItemEvent.ts';
import { unpublishContentItemVariantActionCreator } from './thunks/unpublishContentItemVariant.ts';
import { createUnresolveCommentThread } from './thunks/unresolveCommentThread.ts';
import { updateAssignmentSectionActionCreator } from './thunks/updateAssignment.ts';
import { createUpdateCommentThreadItemAction } from './thunks/updateCommentThreadItem.ts';
import {
  createUpdateContributors,
  createUpdateContributorsInSidebar,
} from './thunks/updateContributors.ts';
import { createUpdateDueDate, createUpdateDueDateInSidebar } from './thunks/updateDueDate.ts';
import { createUpdateNote, createUpdateNoteInSidebar } from './thunks/updateNote.ts';
import { updateTaskCreator } from './thunks/updateTask.ts';
import { createUpdateWorkflowStepAction } from './thunks/updateWorkflowStep.ts';
import { createUrlSlugElementValueChangedAction } from './thunks/urlSlugElementValueChanged.ts';

const {
  contentItemRepository,
  previewConfigurationRepository,
  commentRepository,
  taskRepository,
  workflowRepository,
} = repositoryCollection;

export const createContentItemVariant = createCreateContentItemVariantAction({
  contentItemRepository,
  createGuid,
});

const getCommentsForContentItemVariant = createGetCommentsForContentItemVariant({
  threadComparer,
});

const trackAssignmentEvents = createTrackAssignmentEvents({
  trackUserEventWithData,
  trackUserEvent,
});

const itemParser = createItemParser({
  convertElementsToDomainModel,
  ensureContentItemNameValidity,
});

export const loadFirstWorkflowStepCurrentRoleCanWorkWith =
  createLoadFirstWorkflowStepCurrentRoleCanWorkWithAction({ workflowRepository });

export const onLinkedItemsElementValueChange = createLinkedItemsElementValueChangedAction();

export const richStringElementValueChanged = createRichStringElementValueChangedAction();

const revalidateEditedContentItemVariantElements =
  createRevalidateEditedContentItemVariantElementsAction();
const revalidateLinkedItemVariantElements = createRevalidateLinkedItemVariantElementsAction();

export const onUrlSlugElementValueChange = createUrlSlugElementValueChangedAction();

const updateAutogeneratedUrlSlugAfterDataPatch = createUpdateAutogeneratedUrlSlugAfterDataPatch({
  updateAutogeneratedUrlSlugElements,
  onUrlSlugElementValueChange,
});

export const trackSampleContentItemEvent = createTrackSampleContentItemEventAction({
  trackUserEvent: trackUserEventWithData,
});

const trackSampleContentItemOpened = createTrackSampleContentItemOpened({
  trackUserEvent: trackUserEventWithData,
});

export const autoDispatchSavePending = createAutoDispatchSavePending();

export const checkConcurrentConflictsBeforeSave = createCheckConcurrentConflictsBeforeSave({
  projectNotificationService,
});

export const saveElementValuesToServer = createSaveElementValuesToServerAction({
  checkConcurrentConflictsBeforeSave,
  contentItemRepository,
  updateAutogeneratedUrlSlugAfterDataPatch,
  trackSampleContentItemEvent,
});

export const processDependentElements = createProcessDependentElementsAction();

export const archiveEditedContentItemVariant = createArchiveEditedContentItemVariantAction({
  contentItemRepository,
  archiveVariantStarted: contentItemVariantArchivingStarted,
  archiveVariantFailed: contentItemVariantArchivingFailed,
  archiveVariantFinished: contentItemVariantArchivingFinished,
  loadContentTypes,
  trackUserEventWithData,
});

export const archiveTemporaryContentItem = createArchiveTemporaryContentItemAction({
  contentItemRepository,
  loadContentTypes,
  trackUserEventWithData,
});

export const duplicateContentItem = createDuplicateContentItemAction({
  contentItemRepository,
});

export const duplicateEditedContentItem = createDuplicateEditedContentItemAction({
  contentItemRepository,
});

export const autoGenerateUrlSlug = createAutoGenerateUrlSlugAction({
  contentItemRepository,
  saveElementValuesToServer,
});

export const regenerateUrlSlug = createRegenerateUrlSlugAction({
  autoGenerateUrlSlug,
});

export const autoGenerateAllUrlSlugs = createAutoGenerateAllUrlSlugsAction({
  autoGenerateUrlSlug,
});

export const regenerateAllUrlSlugs = createAutoGenerateAllUrlSlugsAction({
  autoGenerateUrlSlug: regenerateUrlSlug,
});

const loadContentItemReferences = loadContentItemReferencesActionCreator({
  getReferencedContentItemIds,
  loadListingItems,
  getAssetReferences,
  loadAssets,
  getContentTypeIdsUsedInContentComponents: getUsedContentTypeIds,
  ensureLoadedContentTypes,
});

export const loadRelatedContentItemElementsData = loadRelatedContentItemElementsDataActionCreator({
  loadContentItemReferences,
  loadDefaultListingItems,
  commentRepository,
  getCommentsForContentItemVariant,
  commentsLoaded,
});

export const createNewVersion = createNewVersionActionCreator({
  contentItemRepository,
  loadContentItemUsage,
  loadRelatedContentItemElementsData,
  parseContentItemVariant: itemParser.parseContentItemVariant,
});

export const assetElementValueChanged = assetElementValueChangedActionCreator();

export const changeAssetElementValue = changeAssetElementValueActionCreator({
  assetElementValueChanged,
});

export const changeRichStringElementValue = changeRichStringElementValueActionCreator({
  richStringElementValueChanged,
});

export const revalidateAssetAndRichTextElements = createRevalidateAssetAndRichTextElementsAction();

export const startNewInlineCommentThread = createStartNewInlineCommentThread({
  inlineCommentThreadAdded,
});

export const blurCommentThread = createBlurCommentThread();

export const selectContentGroup = createSelectContentGroupAction();

export const selectContentGroupsByElementPath = createSelectContentGroupsByElementPathAction({
  selectContentGroup,
});

export const selectContentGroupForElement = createSelectContentGroupForElementAction({
  selectContentGroupsByElementPath,
});

export const selectContentGroupForAutoScroll = createSelectContentGroupForAutoScrollAction({
  selectContentGroupForElement,
  selectContentGroupsByElementPath,
});

export const loadTasksForItemVariant = loadTasksForItemVariantCreator({
  taskRepository,
});

export const loadPreviewConfiguration = createLoadPreviewConfiguration({
  previewConfigurationRepository,
});

export const initContentItemEditing = createInitContentItemEditingAction({
  autoGenerateAllUrlSlugs,
  contentItemRepository,
  contentListingHiddenColumnsStorage: contentListingColumnsFilterStorage,
  createContentItemVariant,
  loadAiGuidelines,
  loadCollections,
  loadContentItemUsage,
  loadContentTypes,
  loadContentTypeReferencesForItemEditing,
  loadFirstWorkflowStepCurrentRoleCanWorkWith,
  loadPreviewConfiguration,
  loadRelatedContentItemElementsData,
  loadSitemap,
  loadTaxonomyGroups,
  loadUsers,
  loadWorkflows,
  loadRoles,
  loadSpaces,
  parseContentItem: itemParser.parseContentItem,
  revalidateEditedContentItemVariantElements,
  selectContentGroupForAutoScroll,
  trackSampleContentItemOpened,
  loadTasksForItemVariant,
});

export const refreshItemEditing = createRefreshItemEditing({
  initContentItemEditing,
});

export const onMultipleChoiceOptionsChange = createOnMultipleChoiceOptionsChangeAction({
  revalidateEditedContentItemVariantElements,
  processDependentElements,
});

export const onNumberElementValueChange = createNumberElementValueChangedAction();

export const onCustomElementValueChange = createCustomElementValueChangedAction();

export const onStringElementValueChange = createStringElementValueChangedAction();

export const onTaxonomyElementValueChange = createTaxonomyElementValueChangedAction({
  trackUserEventWithData,
});

export const onDateTimeElementValueChange = createDateTimeElementValueChangedAction();

export const expandContentComponentsWithIncompleteElements =
  createExpandContentComponentsWithIncompleteElementsAction({
    contentComponentsExpanded,
  });

export const addTask = createOpenItemDetailsWithFocusedSection({
  defaultFocus: ContentItemSidebarEditableSection.Tasks,
});

export const changeContributors = createOpenItemDetailsWithFocusedSection({
  defaultFocus: ContentItemSidebarEditableSection.Contributors,
});

export const changeDueDate = createOpenItemDetailsWithFocusedSection({
  defaultFocus: ContentItemSidebarEditableSection.DueDate,
});

export const changeNote = createOpenItemDetailsWithFocusedSection({
  defaultFocus: ContentItemSidebarEditableSection.Note,
});

export const publishContentItemVariant = publishContentItemVariantActionCreator({
  trackUserEvent: trackUserEventWithData,
  contentItemRepository,
  parseContentItemVariant: itemParser.parseContentItemVariant,
  loadRelatedContentItemElementsData,
  loadContentItemUsage,
});

export const discardNewVersion = discardNewVersionActionCreator({
  trackUserEvent: trackUserEventWithData,
  contentItemRepository,
  parseContentItemVariant: itemParser.parseContentItemVariant,
  loadRelatedContentItemElementsData,
  loadContentItemUsage,
});

export const publishOneOrOpenModal = publishOneOrOpenModalActionCreator({
  publishContentItemVariant,
});

export const schedulePublishOfContentItemVariant = schedulePublishOfContentItemVariantActionCreator(
  {
    trackUserEvent: trackUserEventWithData,
    contentItemRepository,
    parseContentItemVariant: itemParser.parseContentItemVariant,
    loadRelatedContentItemElementsData,
  },
);

export const scheduleOneOrOpenModal = scheduleOneOrOpenModalActionCreator({
  schedulePublishOfContentItemVariant,
});

export const resolveCommentThread = createResolveCommentThread({
  commentThreadResolvingStarted,
  commentThreadResolved,
  commentRepository,
  trackUserEventWithData,
});

export const updateCommentThreadItem = createUpdateCommentThreadItemAction({
  commentRepository,
});

export const createCommentThreadItemAction = createCreateCommentThreadItemAction({
  commentRepository,
  trackUserEventWithData,
});

export const saveContentItemNameToServer = createSaveContentItemNameToServerAction({
  contentItemRepository,
  regenerateAllUrlSlugs,
});

export const saveContentItemCodenameToServer = createSaveContentItemCodenameToServerAction({
  contentItemRepository,
});

export const saveContentItemSitemapToServer = createSaveContentItemSitemapToServerAction({
  contentItemRepository,
  trackUserEvent,
});

export const saveEditedContentItemName = createSaveEditedContentItemNameAction({
  nameChanged: contentItemNameChanged,
});

export const saveNewCommentThreadToServer = createSaveNewCommentThreadToServerAction({
  commentRepository,
  trackUserEventWithData,
});

export const saveNewInlineCommentThreadToServer = createSaveNewInlineCommentThreadToServerAction({
  commentRepository,
  trackUserEventWithData,
});

export const patchItemProperty = createPatchItemPropertyAction({
  contentItemRepository,
});

export const updateAssignmentSection = updateAssignmentSectionActionCreator({
  trackAssignmentEvents,
  contentItemRepository,
  parseContentItemVariant: itemParser.parseContentItemVariant,
  loadRelatedContentItemElementsData,
});

export const moveItemToArchivedStep = createMoveItemToArchivedStepAction({
  updateAssignmentSection,
});

export const changeWorkflowStep = createUpdateWorkflowStepAction({
  updateAssignmentSection,
});

export const changeWorkflow = createChangeWorkflowAction({
  updateAssignmentSection,
  loadFirstWorkflowStepCurrentRoleCanWorkWith,
});

export const unpublishContentItemVariant = unpublishContentItemVariantActionCreator({
  trackUserEvent: trackUserEventWithData,
  contentItemRepository,
  parseContentItemVariant: itemParser.parseContentItemVariant,
  loadRelatedContentItemElementsData,
  loadContentItemUsage,
});

export const scheduleUnpublishOfContentItemVariant =
  scheduleUnpublishOfContentItemVariantActionCreator({
    trackUserEvent: trackUserEventWithData,
    contentItemRepository,
    parseContentItemVariant: itemParser.parseContentItemVariant,
    loadRelatedContentItemElementsData,
  });

export const cancelContentItemVariantsScheduledPublish =
  cancelContentItemVariantScheduledPublishCreator({
    contentItemRepository,
    parseContentItemVariant: itemParser.parseContentItemVariant,
    loadRelatedContentItemElementsData,
  });

export const cancelContentItemVariantsScheduledUnpublish =
  cancelContentItemVariantScheduledUnpublishCreator({
    contentItemRepository,
    loadRelatedContentItemElementsData,
    parseContentItemVariant: itemParser.parseContentItemVariant,
    trackUserEvent: trackUserEventWithData,
  });

export const unresolveCommentThread = createUnresolveCommentThread({
  commentRepository,
  trackUserEventWithData,
});

export const reopenCommentThread = createReopenCommentThread({
  unresolveCommentThread,
});

export const markSuggestionAsApproved = createMarkSuggestionAsApprovedAction({
  commentRepository,
});

export const approveSuggestion = createApproveSuggestionAction();

export const copyContentValuesFromDifferentExistingVariant =
  createCopyContentValuesFromDifferentExistingVariantAction({
    contentItemRepository,
    initContentItemEditing,
  });

export const ensureExpandedModularContentItemsData = expandModularContentItemActionCreator({
  ensureLoadedContentTypes,
  contentItemRepository,
  loadContentItemReferences,
  loadDefaultListingItems,
  revalidateLinkedItemVariantElements,
  expandModularContentItemFinished,
});

export const highlightIncompleteContentElements = createShowHighlightingOfIncompleteElementsAction({
  resetIsHighlighting,
  highlightIncompleteItemElements,
});

export const collapseAllContentComponents = createCollapseAllContentComponentsAction({
  deactivateContentItemEditingAction,
  blurCommentThread,
  trackUserEventWithData,
});

export const expandAllContentComponents = createExpandAllContentComponentsAction({
  deactivateContentItemEditingAction,
  contentComponentsExpanded,
  trackUserEventWithData,
});

export const collapseContentComponent = createCollapseContentComponentAction({
  blurCommentThread,
  trackUserEventWithData,
});

export const expandContentComponent = createExpandContentComponentAction({
  trackUserEventWithData,
});

const ensureThreadInViewportVisible = createEnsureThreadInViewportVisible({
  contentComponentsExpanded,
  selectContentGroupForElement,
});

export const focusCommentThread = createFocusCommentThreadAction({
  focusedCommentThreadChanged,
  ensureThreadInViewportVisible,
  trackUserEventWithData,
});

export const showCommentThreadsOnRemovedContentOnboarding =
  createShowCommentThreadsOnRemovedContentOnboarding({
    upsertUserProperty,
  });

export const showIncompleteElementsInContentGroup =
  createShowIncompleteElementsInContentGroupAction({
    expandContentComponentsWithIncompleteElements,
    selectContentGroupsByElementPath,
  });

export const displayWarningsOnPublishSelected = createDisplayWarningsOnPublishSelectedAction({
  showIncompleteElementsInContentGroup,
});

export const selectWorkflowStepForEditedItem = createSelectWorkflowStepForEditedItemAction({
  displayWarningsOnPublishSelected,
});

export const createNewItemFromLinkedItems = createNewItemFromLinkedItemsActionCreator({
  createNewLinkedItem,
});

export const createNewItemFromType = createCreateNewItemFromType({
  trackUserEventWithData,
  archiveTemporaryContentItem,
  createNewContentItem,
});

export const createTask = createTaskCreator({
  taskRepository,
  trackUserEventWithData,
});

export const updateTask = updateTaskCreator({
  taskRepository,
  trackUserEventWithData,
});

export const archiveTask = archiveTaskCreator({
  taskRepository,
  trackUserEventWithData,
});

export const completeTask = completeTaskCreator({
  taskRepository,
  trackUserEventWithData,
});

export const reopenTask = reopenTaskCreator({
  taskRepository,
  trackUserEventWithData,
});

export const updateDueDateInSidebar = createUpdateDueDateInSidebar({
  updateAssignmentSection,
});

export const updateDueDate = createUpdateDueDate({
  updateAssignmentSection,
});

export const updateNoteInSidebar = createUpdateNoteInSidebar({
  updateAssignmentSection,
});

export const updateNote = createUpdateNote({
  updateAssignmentSection,
});

export const updateContributorsInSidebar = createUpdateContributorsInSidebar({
  updateAssignmentSection,
});

export const updateContributors = createUpdateContributors({
  updateAssignmentSection,
});

export const restoreFromArchivedStep = createRestoreFromArchivedStep({
  updateAssignmentSection,
});

export const releaseExpiredElementLocks = createReleaseExpiredElementLocks();

export const startContentItemElementRefresh = createStartContentItemElementRefreshAction({
  contentItemRepository,
  loadRelatedContentItemElementsData,
  convertElementsToDomainModel,
  trackUserEventWithData,
  autoGenerateUrlSlug,
});

export const finishContentItemElementRefresh = createFinishContentItemElementRefreshAction({
  revalidateEditedContentItemVariantElements,
});

export const refreshItemAfterLinkedEntityChange = createRefreshItemAfterLinkedEntityChange({
  contentItemRepository,
});

export const trackContentComponentCreated = createTrackContentComponentCreatedAction({
  trackUserEventWithData,
});

export const setRichTextElementClipboard = createSetRichTextElementClipboardAction({
  setRichTextClipboard,
});

export const createNewContentComponent = createNewContentComponentActionCreator({
  changeRichStringElementValue,
  ensureLoadedContentTypes,
  updateTypesPreference: updateTypesPreferenceForEditing,
});

export const addCommentToRichTextElement = createAddCommentToRichTextElementAction({
  startNewInlineCommentThread,
});

export const changeGuidelinesElementVisibility = createChangeGuidelinesElementVisibilityAction();
