import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { Collection } from '@kontent-ai/utils';
import React from 'react';
import { BarItemAnimation } from '../../../../_shared/components/BarItems/BarItemAnimation.tsx';
import { TextFilter } from '../../../../_shared/components/TextFilter.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { pluralizeWithCount } from '../../../../_shared/utils/stringUtils.ts';
import { ISpace, emptySpace } from '../../../../data/models/space/space.ts';
import { ExpandedSpaceBarItemHeight } from '../constants/spacesUiConstants.ts';
import { ExpandedSpaceBar } from '../containers/ExpandedSpaceBar.tsx';
import { ActionInProgress } from '../types/acionInProgress.ts';
import { SpaceDeletion, SpaceDeletionState } from '../types/spaceDeletion.type.ts';
import { DeletedSpaceListingMessage } from './DeletedSpaceListingMessage.tsx';
import { SpaceBar } from './SpaceBar.tsx';
import { SpacesFilterEmptyState } from './SpacesFilterEmptyState.tsx';
import { SpacesHeader } from './SpacesHeader.tsx';

type Props = {
  readonly actionInProgress: ActionInProgress;
  readonly editedSpaceId: Uuid | null;
  readonly filterSearchPhrase: string;
  readonly initialNewSpaceName: string;
  readonly inputSearchPhrase: string;
  readonly isCreateFormOpen: boolean;
  readonly isEditingEnabled: boolean;
  readonly isPlanLimitReached: boolean;
  readonly isWebSpotlightActive: boolean;
  readonly onCloseCreateForm: () => void;
  readonly onCloseEditingForm: () => void;
  readonly onCreate: (
    name: string,
    collectionIds: ReadonlyArray<Uuid>,
    rootItemId: Uuid | null,
    shouldCreateNewRootItem: boolean,
    onSuccess?: () => void,
    onFailure?: () => void,
  ) => void;
  readonly onDelete: (space: ISpace) => void;
  readonly onFilterChange: (searchPhrase: string) => void;
  readonly onOpenCreateForm: (initialSpaceName: string) => void;
  readonly onOpenEditingForm: (spaceId: Uuid) => void;
  readonly onRestore: (id: Uuid) => void;
  readonly onUpdate: (
    space: ISpace,
    shouldCreateNewRootItem: boolean,
    onSuccess?: () => void,
    onFailure?: () => void,
  ) => void;
  readonly planLimit: number | null;
  readonly spaceDeletion: SpaceDeletion | null;
  readonly spaces: ReadonlyArray<ISpace>;
};

export const SpacesListing: React.FC<Props> = ({
  actionInProgress,
  editedSpaceId,
  filterSearchPhrase,
  initialNewSpaceName,
  inputSearchPhrase,
  isCreateFormOpen,
  isEditingEnabled,
  isPlanLimitReached,
  isWebSpotlightActive,
  onCloseCreateForm,
  onCloseEditingForm,
  onCreate,
  onDelete,
  onFilterChange,
  onOpenCreateForm,
  onOpenEditingForm,
  onRestore,
  onUpdate,
  planLimit,
  spaceDeletion,
  spaces,
}) => {
  const allCodeNames = new Set(spaces.map(({ codeName }) => codeName));
  const isCreatingEnabled = !editedSpaceId && !isPlanLimitReached;
  const isFilteringEnabled = !editedSpaceId && !isCreateFormOpen;

  const listingMessage =
    spaceDeletion?.state === SpaceDeletionState.Done ? (
      <DeletedSpaceListingMessage deletedSpace={spaceDeletion.data} onRestore={onRestore} />
    ) : (
      <Label size={LabelSize.L}>{pluralizeWithCount('space', spaces.length)}</Label>
    );

  return (
    <Stack spacing={Spacing.XL}>
      <TextFilter
        autofocus
        disabled={!isFilteringEnabled}
        onChange={onFilterChange}
        placeholder="Filter"
        text={inputSearchPhrase}
        ariaLabel="Search spaces"
      />
      {!isCreateFormOpen && (
        <SpacesHeader
          isCreatingEnabled={isCreatingEnabled}
          isPlanLimitReached={isPlanLimitReached}
          listingMessage={listingMessage}
          onOpenCreateForm={() => onOpenCreateForm('')}
          planLimit={planLimit}
        />
      )}
      <Stack
        component="ul"
        className="bar-item__list"
        spacing={Spacing.M}
        {...getDataUiElementAttribute(DataUiElement.BarItemList)}
      >
        <BarItemAnimation
          estimatedMaxHeightWhenExpanded={ExpandedSpaceBarItemHeight}
          renderCollapsed={() => null}
          renderExpanded={() => (
            <li {...getDataUiElementAttribute(DataUiElement.BarItemNode)}>
              <ExpandedSpaceBar
                actionInProgress={actionInProgress}
                space={{
                  ...emptySpace,
                  name: initialNewSpaceName,
                }}
                onCancel={onCloseCreateForm}
                onSave={onCreate}
              />
            </li>
          )}
          shouldBeExpanded={isCreateFormOpen}
        />
        {!isCreateFormOpen && filterSearchPhrase && !spaces.length ? (
          <SpacesFilterEmptyState
            onCreate={() => onOpenCreateForm(filterSearchPhrase)}
            searchPhrase={filterSearchPhrase}
          />
        ) : (
          spaces.map((space) => (
            <SpaceBar
              key={space.id}
              actionInProgress={actionInProgress}
              isEdited={space.id === editedSpaceId}
              isEditingEnabled={isEditingEnabled}
              isWebSpotlightActive={isWebSpotlightActive}
              onCancel={onCloseEditingForm}
              onDelete={() => onDelete(space)}
              onEdit={() => onOpenEditingForm(space.id)}
              onSave={onUpdate}
              relatedCodeNames={Collection.remove(allCodeNames, space.codeName)}
              searchPhrase={filterSearchPhrase}
              space={space}
            />
          ))
        )}
      </Stack>
    </Stack>
  );
};

SpacesListing.displayName = 'SpacesListing';
