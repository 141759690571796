import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Placement } from '@kontent-ai/component-library/types';
import React, { useRef } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { rememberReturnScrollId, rememberScrollId } from '../../actions/sharedActions.ts';
import { useAutoScroll } from '../../hooks/useAutoScroll.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { AutoScrollId } from './AutoScrollId.ts';
import { AutoScrollComponentProps } from './autoScrollPropTypes.ts';

// Borrowed from router Link component
const isModifiedEvent = (event: React.MouseEvent<HTMLAnchorElement>): boolean =>
  !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

type AutoScrollLinkProps = AutoScrollComponentProps & {
  readonly 'aria-label'?: string;
  readonly className?: string;
  readonly dataUiAction?: DataUiAction;
  readonly onNavigated?: () => void;
  readonly tabIndex?: number;
  readonly title?: string;
  readonly to: string;
  readonly tooltip?: string;
  readonly tooltipPosition?: Placement;
  readonly toScrollId?: AutoScrollId;
};

export const AutoScrollLink: React.FC<React.PropsWithChildren<AutoScrollLinkProps>> = (props) => {
  const {
    alternativeScrollIds,
    children,
    className,
    dataUiAction,
    onNavigated,
    scrollOptions,
    scrollId,
    tabIndex,
    title,
    to,
    toScrollId,
    tooltip,
    tooltipPosition,
    ...restProps
  } = props;

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const scrollTargetRef = useRef(null);

  useAutoScroll({ alternativeScrollIds, scrollTargetRef, scrollId, scrollOptions });

  const onClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    // When the link performs navigation, we want to remember current id and set target id
    if (e.button === 0 && !isModifiedEvent(e)) {
      // Remember current
      if (scrollId) {
        dispatch(rememberReturnScrollId(to, { path: pathname, scrollId }));
      }

      // Remember new if required
      if (toScrollId) {
        dispatch(rememberScrollId({ path: to, scrollId: toScrollId }));
      }

      onNavigated?.();
    }
  };

  return (
    <Tooltip placement={tooltipPosition ?? 'bottom'} tooltipText={tooltip}>
      <Link
        ref={scrollTargetRef}
        to={to}
        className={className}
        onClick={onClick}
        tabIndex={tabIndex}
        title={title}
        {...restProps}
        {...(dataUiAction && getDataUiActionAttribute(dataUiAction))}
      >
        {children}
      </Link>
    </Tooltip>
  );
};

AutoScrollLink.displayName = 'AutoScrollLink';
