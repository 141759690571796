import { InvariantException, isAbortError, isXMLHttpRequest } from '@kontent-ai/errors';
import { assert } from '@kontent-ai/utils';
import { History } from 'history';
import { Dispatch, ThunkFunction, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { modalOpened } from '../../../../../../_shared/actions/sharedActions.ts';
import { trackUserEventWithData } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { ModalDialogType } from '../../../../../../_shared/constants/modalDialogType.ts';
import {
  ContentItemEditorRouteParams,
  ContentItemEditorRoutes,
  ContentItemRoute,
  ContentItemRouteParams,
  ContentItemsRoute,
  ContentItemsRouteParams,
} from '../../../../../../_shared/constants/routePaths.ts';
import {
  FindRightContentTrackedEvent,
  TrackedEvent,
} from '../../../../../../_shared/constants/trackedEvent.ts';
import { canCreateItemVariant } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { redirectToDefaultRoute } from '../../../../../../_shared/utils/routing/redirectToDefaultRoute.ts';
import {
  buildPath,
  getEditedContentItemId,
  matchPath,
  parseContentItemIds,
} from '../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { ILoadContentItemUsagesAction } from '../../../../../../data/actions/thunks/loadContentItemUsagesActionCreator.ts';
import { IWorkflowStep } from '../../../../../../data/models/workflow/WorkflowStep.ts';
import { getCurrentProject } from '../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { IContentListingHiddenColumnsStorage } from '../../../../../../localStorages/contentListingColumnsFilterStorage.ts';
import { guidelinesVisibilityStorage } from '../../../../../../localStorages/guidelinesVisibilityStorage.ts';
import { IContentItemRepository } from '../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { IContentItemVariantReferenceServerModel } from '../../../../../../repositories/serverModels/ContentItemUsageModel.type.ts';
import {
  IActiveCapabilitiesForVariantServerModel,
  IContentItemVariantServerModel,
  IContentItemWithVariantServerModel,
  IContentItemWithVariantsServerModel,
} from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { contentListingColumnsInit } from '../../../../../contentInventory/content/features/ContentItemInventory/actions/contentItemInventoryActions.ts';
import {
  ICompiledContentType,
  getCompiledContentTypeFromServerModel,
} from '../../../../../contentInventory/content/models/CompiledContentType.ts';
import { TemporaryContentItemState } from '../../../../../contentInventory/content/models/temporaryContentItemState.ts';
import { IVariantActiveCapabilities } from '../../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import { getAccessDeniedToContentItemRoute } from '../../../../../contentInventory/shared/utils/accessDeniedRouteUtils.ts';
import { getContentTypeConversionOptions } from '../../../../../contentModels/shared/selectors/contentTypeElementSelector.ts';
import { AccessDeniedToContentItemAction } from '../../../../../errorHandling/constants/AccessDeniedToContentItemAction.ts';
import {
  IContentItemVariantReference,
  getContentItemVariantReferenceFromServerModel,
} from '../../../../models/contentItem/ContentItemVariantReference.ts';
import {
  IEditedContentItem,
  mapEditedContentItemFromServerModel,
} from '../../../../models/contentItem/edited/EditedContentItem.ts';
import { EditedContentItemVariant } from '../../../../models/contentItem/edited/EditedContentItemVariant.ts';
import { ICompiledContentItemElementData } from '../../../../models/contentItemElements/ICompiledContentItemElement.ts';
import { getWorkflowsCurrentUserCanCreateVariantIn } from '../../../../selectors/workflows/getWorkflowsCurrentUserCanCreateVariantIn.ts';
import { ILoadContentTypeReferencesForItemEditingAction } from '../../../LoadedItems/actions/thunks/loadContentTypeReferencesForItemEditing.ts';
import { waitForPendingOperations } from '../../../safeRedirect/utils/pendingOperationsUtils.ts';
import {
  ContentItemEditing_Init_Aborted,
  ContentItemEditing_Init_Failed,
  ContentItemEditing_Init_Finished,
  ContentItemEditing_Init_FinishedNoVariant,
  ContentItemEditing_Init_Ready,
  ContentItemEditing_Init_Started,
} from '../../constants/contentItemEditingActionTypes.ts';
import { IParseContentItem } from '../../utils/parseContentItem.ts';
import { removeTemporaryItemReference } from '../../utils/removeTemporaryItemReference.ts';
import {
  ItemWithVariantAndFilterPayload,
  focusedCommentThreadChanged,
} from '../contentItemEditingActions.ts';
import { AutoGenerateAllUrlSlugsAction } from './autoGenerateAllUrlSlugs.ts';
import { ICreateContentItemVariant } from './createContentItemVariant.ts';
import { ILoadFirstWorkflowStepCurrentRoleCanWorkWithAction } from './loadFirstWorkflowStepCurrentRoleCanWorkWith.ts';
import { ILoadRelatedContentItemElementsDataAction } from './loadRelatedContentItemElementsData.ts';
import { ILoadTasksForItemVariantAction } from './loadTasksForItemVariant.ts';

type SimpleLoadAction = (abortSignal?: AbortSignal) => ThunkPromise;

interface IDeps {
  readonly autoGenerateAllUrlSlugs: AutoGenerateAllUrlSlugsAction;
  readonly contentItemRepository: Pick<
    IContentItemRepository,
    'tryGetItemWithVariant' | 'getItemWithAllVariants' | 'getContentTypeForItem'
  >;
  readonly contentListingHiddenColumnsStorage: IContentListingHiddenColumnsStorage;
  readonly createContentItemVariant: ICreateContentItemVariant;
  readonly loadAiGuidelines: SimpleLoadAction;
  readonly loadCollections: SimpleLoadAction;
  readonly loadContentItemUsage: ILoadContentItemUsagesAction;
  readonly loadContentTypes: SimpleLoadAction;
  readonly loadContentTypeReferencesForItemEditing: ILoadContentTypeReferencesForItemEditingAction;
  readonly loadFirstWorkflowStepCurrentRoleCanWorkWith: ILoadFirstWorkflowStepCurrentRoleCanWorkWithAction;
  readonly loadPreviewConfiguration: SimpleLoadAction;
  readonly loadRelatedContentItemElementsData: ILoadRelatedContentItemElementsDataAction;
  readonly loadSitemap: SimpleLoadAction;
  readonly loadTaxonomyGroups: SimpleLoadAction;
  readonly loadUsers: SimpleLoadAction;
  readonly loadWorkflows: SimpleLoadAction;
  readonly loadRoles: SimpleLoadAction;
  readonly loadSpaces: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadTasksForItemVariant: ILoadTasksForItemVariantAction;
  readonly parseContentItem: IParseContentItem;
  readonly revalidateEditedContentItemVariantElements: () => ThunkFunction;
  readonly selectContentGroupForAutoScroll: (currentPath: string) => ThunkFunction;
  readonly trackSampleContentItemOpened: () => ThunkFunction;
}

export type InitContentItemEditing = (
  history: History,
  commentThreadId: Uuid | null,
  operationId: Uuid,
  abortSignal?: AbortSignal,
) => ThunkPromise;

const reduceVariantToItemReference = (
  variant: IContentItemVariantServerModel,
  name: string,
  typeId: Uuid,
  activeCapabilities: IActiveCapabilitiesForVariantServerModel,
): IContentItemVariantReferenceServerModel => ({
  _id: variant.id,
  activeCapabilities,
  archived: variant.archived,
  assignment: variant.assignment,
  isPublishedVersion: false,
  name,
  publishingState: variant.publishingState,
  typeId,
});

export const initContentItemEditingStarted = (
  operationId: Uuid,
  commentThreadId?: Uuid | null,
  collapsedGuidelines?: ReadonlyArray<Uuid>,
) =>
  ({
    type: ContentItemEditing_Init_Started,
    payload: {
      operationId,
      commentThreadId: commentThreadId ?? null,
      collapsedGuidelines: collapsedGuidelines ?? [],
    },
  }) as const;

type EditingFinishedNoVariantPayload = {
  readonly operationId: Uuid;
  readonly editedContentItem: IEditedContentItem;
  readonly contentItemVariants: Immutable.Map<Uuid, IContentItemVariantReference>;
  readonly editedContentItemType: ICompiledContentType;
};

const initContentItemEditingFinishedNoVariant = (payload: EditingFinishedNoVariantPayload) =>
  ({
    type: ContentItemEditing_Init_FinishedNoVariant,
    payload,
  }) as const;

type EditingInitReadyPayload = ItemWithVariantAndFilterPayload & {
  readonly activeCapabilities: IVariantActiveCapabilities;
  readonly actualWorkflowStatus: IWorkflowStep | null;
  readonly contentItemVariants: Immutable.Map<Uuid, IContentItemVariantReference>;
  readonly editedContentItem: IEditedContentItem;
  readonly editedContentItemType: ICompiledContentType;
  readonly editedContentItemVariant: EditedContentItemVariant;
  readonly editedContentItemVariantElements: ReadonlyArray<ICompiledContentItemElementData>;
  readonly isContentItemVariantJustCreated: boolean;
  readonly operationId: Uuid;
};

export const initContentItemEditingReady = (payload: EditingInitReadyPayload) =>
  ({
    type: ContentItemEditing_Init_Ready,
    payload,
  }) as const;

export const initContentItemEditingFinished = () =>
  ({
    type: ContentItemEditing_Init_Finished,
  }) as const;

export const initContentItemEditingFailed = (operationId: Uuid) =>
  ({
    type: ContentItemEditing_Init_Failed,
    payload: {
      operationId,
    },
  }) as const;

export const initContentItemEditingAborted = (operationId: Uuid) =>
  ({
    type: ContentItemEditing_Init_Aborted,
    payload: {
      operationId,
    },
  }) as const;

export type InitContentItemEditingActionsType = ReturnType<
  | typeof initContentItemEditingStarted
  | typeof initContentItemEditingFinished
  | typeof initContentItemEditingFinishedNoVariant
  | typeof initContentItemEditingReady
  | typeof initContentItemEditingFailed
  | typeof initContentItemEditingAborted
>;

const abortInitializationAndOpenVariantWorkflowSelectionDialog = (
  dispatch: Dispatch,
  contentItemWithVariantsMetadata: IContentItemWithVariantsServerModel,
  operationId: Uuid,
  contentType: ICompiledContentType,
) => {
  const editedContentItem = mapEditedContentItemFromServerModel(
    contentItemWithVariantsMetadata.item,
  );
  const contentItemVariants = contentItemWithVariantsMetadata.variants.reduce(
    (reduced: Immutable.Map<Uuid, IContentItemVariantReference>, variantReference) =>
      reduced.set(
        variantReference._id.variantId,
        getContentItemVariantReferenceFromServerModel(variantReference),
      ),
    Immutable.Map<Uuid, IContentItemVariantReference>(),
  );

  dispatch(
    initContentItemEditingFinishedNoVariant({
      operationId,
      editedContentItem,
      contentItemVariants,
      editedContentItemType: contentType,
    }),
  );
  dispatch(modalOpened(ModalDialogType.NewVariantWorkflowSelectionDialog));
};

const updateContentItemWithVariantsMetadata = (
  contentItemWithVariantsMetadata: IContentItemWithVariantsServerModel,
  contentItemWithVariant: IContentItemWithVariantServerModel,
): IContentItemWithVariantsServerModel => ({
  ...contentItemWithVariantsMetadata,
  variants: [
    ...contentItemWithVariantsMetadata.variants.filter(
      (v) => v._id.variantId !== contentItemWithVariant?.variant.id.variantId,
    ),
    reduceVariantToItemReference(
      contentItemWithVariant.variant,
      contentItemWithVariant.item.name,
      contentItemWithVariant.item.type._id,
      contentItemWithVariant.activeCapabilities,
    ),
  ],
});

export const createInitContentItemEditingAction =
  (deps: IDeps): InitContentItemEditing =>
  (history, commentThreadId, operationId, abortSignal) =>
  async (dispatch, getState) => {
    // TODO KCL-6954 - This should no longer be necessary after implementation of pending operations into HandlePendingChangesOnNavigation.tsx
    // When there are no more of these errors in AppInsights, this check can be removed
    await waitForPendingOperations(
      getState,
      `Content item editing is being initialized before all pending actions (initialization, updates) finished.
This may be caused by too early redirect.
Use requestSafeRedirect(...) for redirects involving content item editing in the view.`,
    );

    const state = getState();
    const {
      contentApp: {
        editorUi: { temporaryItem },
        newContentItemVariantInit,
      },
      data: { listingContentItems, user },
    } = state;
    const currentProjectId = getCurrentProject(state).projectId;

    const matchParams = matchPath<ContentItemEditorRouteParams<string>>(history.location.pathname, {
      path: ContentItemEditorRoutes,
    });
    if (!matchParams) {
      throw InvariantException(
        `Current route does not belong to ContentItem routes. Route: ${history.location.pathname}`,
      );
    }

    const variantId = matchParams.variantId;
    const contentItemId = getEditedContentItemId(matchParams);

    const collapsedGuidelines = guidelinesVisibilityStorage.load(
      currentProjectId,
      user.info.userId,
    );

    dispatch(initContentItemEditingStarted(operationId, commentThreadId, collapsedGuidelines));

    try {
      const hiddenColumns = deps.contentListingHiddenColumnsStorage.load(
        currentProjectId,
        user.info.userId,
      );

      dispatch(contentListingColumnsInit(hiddenColumns));

      let [contentItemWithVariantsMetadata, contentItemWithVariant, rawType] = await Promise.all([
        deps.contentItemRepository.getItemWithAllVariants(contentItemId, abortSignal),
        deps.contentItemRepository.tryGetItemWithVariant(contentItemId, variantId, abortSignal),
        deps.contentItemRepository.getContentTypeForItem(contentItemId, abortSignal),
        dispatch(deps.loadCollections(abortSignal)),
        dispatch(deps.loadContentTypes(abortSignal)),
        dispatch(deps.loadRoles(abortSignal)),
        dispatch(deps.loadSitemap(abortSignal)),
        dispatch(deps.loadTaxonomyGroups(abortSignal)),
        dispatch(deps.loadUsers(abortSignal)),
        dispatch(deps.loadWorkflows(abortSignal)),
        dispatch(deps.loadAiGuidelines(abortSignal)),
        dispatch(deps.loadSpaces(abortSignal)).then(() =>
          dispatch(deps.loadPreviewConfiguration(abortSignal)),
        ),
      ]);

      if (contentItemWithVariantsMetadata.item.archived) {
        dispatch(initContentItemEditingFailed(operationId));
        // Cannot edit deleted item anymore
        history.push(
          buildPath<ContentItemsRouteParams>(ContentItemsRoute, {
            app: matchParams.app,
            projectId: currentProjectId,
            variantId,
            spaceId: matchParams.spaceId,
          }),
        );
        return;
      }

      if (
        temporaryItem &&
        temporaryItem.itemState !== TemporaryContentItemState.Edited &&
        contentItemWithVariant &&
        temporaryItem.parentItem &&
        contentItemWithVariant.item.id === temporaryItem.parentItem.itemId
      ) {
        //  If a content item was created from modular content, then navigated back without editing.
        //  The new item was automatically deleted and this reference need to be removed.

        contentItemWithVariant = removeTemporaryItemReference(
          contentItemWithVariant,
          temporaryItem,
        );
      }

      const contentType = getCompiledContentTypeFromServerModel(
        rawType,
        getContentTypeConversionOptions(state),
      );

      let isContentItemVariantJustCreated = false;
      const rawVariant = contentItemWithVariant?.variant;
      const { collectionId } = contentItemWithVariantsMetadata.item;
      const canCreate = canCreateItemVariant(contentType.id, collectionId, variantId, state);
      if (canCreate && (!rawVariant || rawVariant.archived)) {
        const workflowsCurrentUserCanCreateItemIn = getWorkflowsCurrentUserCanCreateVariantIn(
          getState(),
          variantId,
          collectionId,
          contentType.id,
        );

        const selectedWorkflowId = newContentItemVariantInit.workflowId;
        const canUserSelectWorkflow =
          !selectedWorkflowId && workflowsCurrentUserCanCreateItemIn.length > 1;
        if (canUserSelectWorkflow) {
          abortInitializationAndOpenVariantWorkflowSelectionDialog(
            dispatch,
            contentItemWithVariantsMetadata,
            operationId,
            contentType,
          );
          return;
        }

        const workflowId = selectedWorkflowId ?? workflowsCurrentUserCanCreateItemIn[0]?.id;

        assert(
          workflowId,
          () =>
            `${__filename}: No valid workflow was found for variant ${variantId}, content type ${contentType.id} and collection ${collectionId}.`,
        );

        contentItemWithVariant = await dispatch(
          deps.createContentItemVariant(
            contentItemWithVariantsMetadata.item,
            variantId,
            contentType,
            workflowId,
            abortSignal,
          ),
        );
        if (!contentItemWithVariant) {
          dispatch(initContentItemEditingFailed(operationId));
          return;
        }
        contentItemWithVariantsMetadata = updateContentItemWithVariantsMetadata(
          contentItemWithVariantsMetadata,
          contentItemWithVariant,
        );

        isContentItemVariantJustCreated = true;
      } else if (!canCreate && (!rawVariant || rawVariant.archived)) {
        dispatch(initContentItemEditingFailed(operationId));
        history.push(
          getAccessDeniedToContentItemRoute(
            currentProjectId,
            variantId,
            contentItemId,
            AccessDeniedToContentItemAction.Translate,
            history.location.pathname,
          ),
        );
        return;
      }

      if (
        !contentItemWithVariant ||
        !contentItemWithVariant.variant ||
        contentItemWithVariant.variant.archived
      ) {
        throw InvariantException(
          `The variant '${variantId}' of item '${contentItemId}' failed to be retrieved or created.`,
        );
      }

      const {
        editedContentItem,
        editedContentItemRawVariant,
        editedContentItemVariantElements,
        editedContentItemVariant,
        contentItemVariants,
      } = deps.parseContentItem(contentItemWithVariant, contentItemWithVariantsMetadata);

      if (
        !editedContentItemVariant ||
        !editedContentItemVariantElements ||
        !editedContentItemRawVariant
      ) {
        // This shouldn't happen now as the variant should either be automatically created or access denied happens
        // but it is probably a valid final state which can later be used for the confirmation dialog whether a new variant should be created or not
        // So we keep it for now. Also because there may still be some hidden historical scenario we are not aware of at the moment.
        dispatch(
          initContentItemEditingFinishedNoVariant({
            operationId,
            editedContentItem,
            contentItemVariants,
            editedContentItemType: contentType,
          }),
        );

        return;
      }

      await Promise.all([
        dispatch(
          deps.loadContentItemUsage(
            editedContentItemRawVariant.id.itemId,
            editedContentItemRawVariant.id.variantId,
            abortSignal,
          ),
        ),
        dispatch(
          deps.loadTasksForItemVariant(
            editedContentItemRawVariant.id.itemId,
            editedContentItemRawVariant.id.variantId,
            abortSignal,
          ),
        ),
        dispatch(
          deps.loadFirstWorkflowStepCurrentRoleCanWorkWith(
            variantId,
            editedContentItem.collectionId,
            editedContentItemVariant.assignment.workflowStatus.workflowId,
            abortSignal,
          ),
        ),
        dispatch(
          deps.loadRelatedContentItemElementsData(
            contentItemId,
            variantId,
            editedContentItemVariantElements,
            commentThreadId,
            abortSignal,
          ),
        ),
        dispatch(
          deps.loadContentTypeReferencesForItemEditing(contentType.contentElements, abortSignal),
        ),
      ]);

      const item = listingContentItems.byId.get(contentItemId);
      const actualWorkflowStatus = item?.variant?.actualWorkflowStatus ?? null;

      dispatch(
        initContentItemEditingReady({
          activeCapabilities: contentItemWithVariant.activeCapabilities,
          actualWorkflowStatus,
          contentItemVariants,
          editedContentItem,
          editedContentItemType: contentType,
          editedContentItemVariant,
          editedContentItemVariantElements,
          filter: getState().contentApp.listingUi.filter,
          isContentItemVariantJustCreated,
          itemWithVariant: contentItemWithVariant,
          operationId,
          usedSearchMethod: listingContentItems.usedSearchMethod,
        }),
      );

      await dispatch(
        deps.autoGenerateAllUrlSlugs(
          {
            overwriteExisting: false,
            pathname: history.location.pathname,
          },
          abortSignal,
        ),
      );

      dispatch(deps.trackSampleContentItemOpened());

      if (commentThreadId) {
        dispatch(focusedCommentThreadChanged(commentThreadId));
      }

      dispatch(deps.revalidateEditedContentItemVariantElements());

      dispatch(deps.selectContentGroupForAutoScroll(history.location.pathname));

      const pathParams = matchPath<ContentItemRouteParams<string>>(
        history.location.pathname,
        ContentItemRoute,
      );
      const isFromLinkedItems =
        !!pathParams && parseContentItemIds(pathParams.contentItemIds).length > 1;
      dispatch(
        trackUserEventWithData(TrackedEvent.FindRightContent, {
          'opened-from-linked-items': isFromLinkedItems,
          'contains-non-localizable-elements': contentType.contentElements?.some(
            (el) => el.isNonLocalizable,
          ),
          itemId: contentItemId,
          name: FindRightContentTrackedEvent.OpenedItemEditing,
          age: undefined,
        }),
      );

      dispatch(initContentItemEditingFinished());
    } catch (error) {
      if (isAbortError(error)) {
        dispatch(initContentItemEditingAborted(operationId));
        throw error as unknown;
      }

      dispatch(initContentItemEditingFailed(operationId));

      if (isXMLHttpRequest(error) && error.status === 403) {
        history.push(
          getAccessDeniedToContentItemRoute(
            currentProjectId,
            variantId,
            contentItemId,
            AccessDeniedToContentItemAction.View,
            history.location.pathname,
          ),
        );
        return;
      }

      // The desired contentItem does not exist, hence redirect to the inventory
      redirectToDefaultRoute({
        currentProjectId,
        history,
        error,
      });
    }
  };
