import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import Immutable from 'immutable';
import React from 'react';
import { DataTable } from '../../../../_shared/components/DataTable/DataTable.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { DataTableRow } from '../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import { LastUpdatedAt } from '../../../../_shared/components/LastUpdatedAt.tsx';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';
import {
  ItemColumnCode,
  translateColumnCodeToTitle,
} from '../../../../_shared/constants/itemColumnCode.ts';
import { YourContentTabName } from '../../../../_shared/constants/yourContentTabName.ts';
import { WorkflowStatusTagForVariant } from '../../../../_shared/containers/Workflow/WorkflowStatusTagForVariant.tsx';
import { ContentItemId } from '../../../../_shared/models/ContentItemId.type.ts';
import { OrderBy, OrderByDirection } from '../../../../_shared/models/OrderBy.ts';
import { stringifyContentItemId } from '../../../../_shared/models/utils/contentItemIdUtils.ts';
import { VariantCompletionStatus } from '../../../../_shared/utils/contentItemVariantUtils.ts';
import { DataUiCollection } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getContentItemPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { PublishingState } from '../../../../data/constants/PublishingState.ts';
import { IAssignmentWorkflowStep } from '../../../../data/models/workflow/WorkflowStep.ts';
import { TableCellDueDate } from '../../../contentInventory/content/features/ContentItemInventory/components/TableCellDueDate.tsx';
import {
  AssignedContentItemsOrderBy,
  OrderByColumn,
} from '../../../itemEditor/reducers/editorUi/reducers/assignedItemsOrdering.ts';
import { NameWithLanguage } from '../NameWithLanguage.tsx';
import { YourContentTabSaver } from '../YourContentTabSaver.tsx';

interface IContentAssignedToYouProps {
  readonly contentItems: Immutable.List<IAssignedItemViewModel>;
  readonly currentPath: string;
  readonly loadingFinished: boolean;
  readonly onColumnHeadClick: (orderBy: OrderBy<AssignedContentItemsOrderBy>) => void;
  readonly onItemClick: (itemId: Uuid, index: number) => void;
  readonly orderBy: OrderBy<AssignedContentItemsOrderBy>;
}

export interface IAssignedItemViewModel {
  readonly id: ContentItemId;
  readonly contentType: string;
  readonly due: DateTimeStamp | null;
  readonly language: string;
  readonly lastUpdatedAt: DateTimeStamp | null;
  readonly name: string;
  readonly publishingState: PublishingState;
  readonly variantCompletionStatus: VariantCompletionStatus | null;
  readonly workflowStatus: IAssignmentWorkflowStep;
  readonly scheduledToPublishAt: DateTimeStamp | null;
  readonly scheduledToUnpublishAt: DateTimeStamp | null;
}

export const ContentAssignedToYou: React.FC<IContentAssignedToYouProps> = ({
  contentItems,
  currentPath,
  loadingFinished,
  onColumnHeadClick,
  onItemClick,
  orderBy,
}) => {
  const getOppositeOrderByDirection = (direction: OrderByDirection): OrderByDirection => {
    return direction === OrderByDirection.Descending
      ? OrderByDirection.Ascending
      : OrderByDirection.Descending;
  };

  const renderItem = (item: IAssignedItemViewModel, index: number): JSX.Element => {
    const {
      contentType,
      due,
      id,
      language,
      lastUpdatedAt,
      name,
      publishingState,
      scheduledToPublishAt,
      scheduledToUnpublishAt,
      variantCompletionStatus,
      workflowStatus,
    } = item;

    const linkProps = {
      linkPath: getContentItemPath(currentPath, id.itemId, id.variantId),
      onClick: () => onItemClick(id.itemId, index),
    };

    return (
      <DataTableRow
        className="your-content__item"
        id={stringifyContentItemId(id)}
        key={stringifyContentItemId(id)}
        dataUiObjectName={name}
      >
        <LinkDataTableCell {...linkProps} focusableRowLinkAriaLabel={`visit content — ${name}`}>
          <NameWithLanguage
            name={name}
            language={language}
            variantCompletionStatus={variantCompletionStatus}
          />
        </LinkDataTableCell>
        <LinkDataTableCell {...linkProps} title={contentType}>
          {contentType}
        </LinkDataTableCell>
        <LinkDataTableCell {...linkProps}>
          <WorkflowStatusTagForVariant
            publishingState={publishingState}
            workflowStatus={workflowStatus}
            scheduledToPublishAt={scheduledToPublishAt}
            scheduledToUnpublishAt={scheduledToUnpublishAt}
          />
        </LinkDataTableCell>
        <LinkDataTableCell {...linkProps}>
          <TableCellDueDate dueDate={due} />
        </LinkDataTableCell>
        <LinkDataTableCell {...linkProps}>
          <LastUpdatedAt time={lastUpdatedAt} />
        </LinkDataTableCell>
      </DataTableRow>
    );
  };

  const renderEmptyState = (): JSX.Element => {
    return (
      <EmptyState>
        <EmptyState.Content>
          <EmptyState.ContentGroup>
            <p>There is nothing assigned to you at this moment.</p>
            <p>
              Need help? See{' '}
              <OutwardLink href={documentationLinks.assignContributors}>
                how to assign contributors
              </OutwardLink>
              .
            </p>
          </EmptyState.ContentGroup>
        </EmptyState.Content>
      </EmptyState>
    );
  };

  const tableHeadColumns = Immutable.List.of<Column>(
    {
      columnName: translateColumnCodeToTitle(ItemColumnCode.Name),
      orderBy: OrderByDirection.None,
    },
    {
      columnName: translateColumnCodeToTitle(ItemColumnCode.ContentType),
      orderBy: OrderByDirection.None,
      className: 'data-table__column--4',
    },
    {
      columnName: translateColumnCodeToTitle(ItemColumnCode.WorkflowStep),
      orderBy: OrderByDirection.None,
      className: 'data-table__column--5',
    },
    {
      columnName: translateColumnCodeToTitle(ItemColumnCode.DueDate),
      orderBy:
        (orderBy.columnCode === OrderByColumn.Due && orderBy.direction) || OrderByDirection.None,
      className: 'data-table__column--4',
      onClick: () =>
        onColumnHeadClick({
          columnCode: OrderByColumn.Due,
          direction:
            orderBy.columnCode !== OrderByColumn.Due
              ? orderBy.direction
              : getOppositeOrderByDirection(orderBy.direction),
        }),
    },
    {
      columnName: translateColumnCodeToTitle(ItemColumnCode.LastModifiedAt),
      orderBy:
        (orderBy.columnCode === OrderByColumn.LastModified && orderBy.direction) ||
        OrderByDirection.None,
      className: 'data-table__column--4',
      onClick: () =>
        onColumnHeadClick({
          columnCode: OrderByColumn.LastModified,
          direction:
            orderBy.columnCode !== OrderByColumn.LastModified
              ? orderBy.direction
              : getOppositeOrderByDirection(orderBy.direction),
        }),
    },
  );

  if (!loadingFinished) {
    return (
      <section>
        <DataTable
          noBoxShadow
          dataUiCollectionName={DataUiCollection.ContentItems}
          header={<DataTableHeadRow columns={tableHeadColumns} />}
          infoMessage={
            <EmptyState>
              <Loader />
            </EmptyState>
          }
        />
      </section>
    );
  }

  return (
    <YourContentTabSaver tabName={YourContentTabName.AssignedToYou} itemCount={contentItems.size}>
      <section className="your-content__items-section">
        <DataTable
          noBoxShadow
          dataUiCollectionName={DataUiCollection.ContentItems}
          header={<DataTableHeadRow columns={tableHeadColumns} />}
          infoMessage={contentItems.isEmpty() ? renderEmptyState() : null}
        >
          {!contentItems.isEmpty() && contentItems.toArray().map(renderItem)}
        </DataTable>
      </section>
    </YourContentTabSaver>
  );
};

ContentAssignedToYou.displayName = 'ContentAssignedToYou';
